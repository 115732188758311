<template>
    <div>
        <div
            :class="{
                selected: layerCategory.includes($store.state.selectedLayer),
            }"
            class="group"
            @click="updateLayerAndLegend()"
        >
            <div
                class="layer-text-legend-icon"
                v-bind:class="{
                    hovered: iconHovered,
                }"
            >
                <!-- <img
          class="legend-icon"
          src="@/assets/color-circle.svg"
        /> -->
                <span class="layer-text">{{ $t(text) }}</span>
            </div>
            <img
                class="layer-icon"
                :src="imgSrc"
                @mouseover="iconHovered = true"
                @mouseleave="iconHovered = false"
            />
        </div>
        <slot></slot>
        <!-- <LayerLegends
            @legendClicked="$emit('legendClicked', layerCode)"
            :layerCode="layerCode"
            :legendToShow="legendToShow"
            :layerCategory="layerCategory"
        ></LayerLegends> -->
    </div>
</template>

<script>
// import LayerLegends from "./LayerLegends.vue";
export default {
    // components: { LayerLegends },
    props: {
        imgSrc: String,
        text: String,
        layerCode: String,
        legendToShow: String,
        layerCategory: Array
    },

    data() {
        return { iconHovered: false };
    },
    methods: {
        updateLayerAndLegend: function () {
            this.$store.mutations.updateSelectedLayer(this.layerCode);
            this.$emit("legendClicked", this.layerCode);
        },
    },
};
</script>


<style >

</style>