function date2string(year, month, day, hour, minute) {
  return year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":00Z";
}

function getDateValuesFromDate(date) {
  var year = String(date.getUTCFullYear());
  var month = String(date.getUTCMonth() + 1).padStart(2, "0");
  var day = String(date.getUTCDate()).padStart(2, "0");
  var hour = String(date.getUTCHours()).padStart(2, "0");
  var minute = String(date.getUTCMinutes()).padStart(2, "0");
  return date2string(year, month, day, hour, minute);
}

function roundMinutes(date) {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date;
}

function getAllDateInfo(date, weekDays) {
  var dayOfWeek = weekDays[date.getDay()];

  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  var hour = date.getHours();
  var minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0" + String(minutes);
  }
  let tz = date.toLocaleTimeString('en-CA',{timeZoneName:'short'}).split(' ')[2]
  return [dayOfWeek, year, month, day, hour, minutes, tz]
}

function formatDateToDisplay(date, weekDays) {
  let [dayOfWeek, year, month, day, hour, minutes, tz] = getAllDateInfo(date, weekDays)

  var dateString = [year, month, day].join("-");
  var timeString = [hour, minutes].join(":");
  return [dayOfWeek, dateString, timeString, tz].join(" ");
}

function formatDateToDisplayNoYear(date, weekDays) {
  let infos = getAllDateInfo(date, weekDays)

  var timeString = infos[4]+"h"

  return [infos[0], infos[3], timeString].join(" ");
}

function formatDateToDisplayOnlyDay(date, weekDays) {
  let infos = getAllDateInfo(date, weekDays)

  return [infos[0], infos[3]].join(" ");
}
function getHoursDifference(date) {
  return Math.round((date - new Date) / 36e5)
}

export {
  getDateValuesFromDate,
  roundMinutes,
  formatDateToDisplay,
  formatDateToDisplayNoYear,
  formatDateToDisplayOnlyDay,
  getHoursDifference
};
