<template>
    <div class="pane-container-boxes atmos-pane">
        <div class="infoSectionTitle">{{ $t("meteostation-tab-title") }}</div>
        <div class="display-day-date-time date-time-atmos">
            {{ dateString }}
        </div>
        <!-- <b-table
      sticky-header="300px"
      striped
      hover
      small
      :items="items"
      thead-class="d-none"
      class="table-atmos"
    ></b-table> -->
        <div class="meteo-items">
            <div class="meteo-item" v-for="item in items" :key="item[0]">
                <div class="meteo-item-title">{{ item[0] }}</div>
                <div class="meteo-item-value">{{ item[1] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
import meteoMixin from "@/components/buttomPane/layouts/mixins/displayMeteoInfoMixin";
export default {
    mixins: [meteoMixin],
    computed: {
        items() {
            let data = !this.station ? [] : Object.entries(this.station.data);
            this.changeWindToKnots(data);
            return this.buildTable(data);
        },
        dateString: function () {
            if (!this.station || this.station.data === undefined) {
                return "";
            }
            var date = new Date(this.station.observationDateUtc);
            return formatDateToDisplay(date, days[this.$i18n.locale]);
        },
    },
};
</script>

<style scoped>
.date-time-atmos {
    text-align: center;
}
.atmos-pane {
    max-width: 550px;
    margin: 0 auto !important;
}

</style>