<template>
  <div class="bottom-pane">
    <div class="tabs-menu">
      <MenuTabButton
        v-for="tab in tabs"
        :key="tab"
        v-on:activeTabFromChild="handleTabClick"
        :menuTabName="tab"
        :activeTab="activeTab"
      ></MenuTabButton>
    </div>
    <layerInfoModal v-if="selectedLayer == Layer.CURRENTS"></layerInfoModal>
    <LayerLegends class="layer-legend-container"> </LayerLegends>

    <transition name="fadeHeight" mode="out-in">
      <div class="tab-content" v-show="activeTab">
        <img class="close-icon" src="@/assets/icone_x.svg" @click="activeTab = null" />

        <AreaInfo v-if="activeTab === tabEnum.INFORMATION" />

        <StationsListAndDetails
          :allStations="allMarineStations"
          :categories="Category"
          :selectedStation="selectedMarineStation"
          :stationCategories="[Category.Marine]"
          v-if="activeTab === tabEnum.MARINE_STATIONS"
        >
          <MarineStationLayout :stationData="selectedMarineStationData" />
        </StationsListAndDetails>

        <StationsListAndDetails
          :allStations="allAtmosStations"
          :categories="Category"
          :selectedStation="selectedAtmosStation"
          :stationCategories="[
            Category.Atmospheric,
            Category.AtmosPrevision,
            Category.MeteoMaritime,
          ]"
          v-if="activeTab === tabEnum.ATMOSPHERIC_STATIONS"
        >
          <MeteoStationExtendedLayout
            v-if="
              selectedStation != null && selectedStation.category === Category.Atmospheric
            "
            :station="selectedAtmosphericStationData"
            :showAllVariables="true"
            :key="meteoKey"
          />
          <AtmosPrevLayout
            v-if="
              selectedStation != null &&
              selectedStation.category === Category.AtmosPrevision
            "
            :stationData="selectedAtmosPrevisionData"
          />
          <MeteoMaritimeLayout
            v-if="
              selectedStation != null &&
              selectedStation.category === Category.MeteoMaritime
            "
            :regionData="selectedMeteoMaritimeData"
          >
          </MeteoMaritimeLayout>
        </StationsListAndDetails>

        <ItineraryTab v-show="activeTab === tabEnum.ITINERARY" />
      </div>
    </transition>
  </div>
</template>

<script>
import { Layer } from "@/constants";
import layerInfoModal from "@/components/buttomPane/layouts/TheLayerInfoModal.vue";
import MenuTabButton from "@/components/buttomPane/common/MenuTabButton.vue";
import AreaInfo from "@/components/buttomPane/layouts/AreaInfo.vue";
import StationsListAndDetails from "@/components/buttomPane/common/StationsListAndDetails.vue";
import MeteoStationExtendedLayout from "@/components/buttomPane/layouts/MeteoStationExtendedLayout.vue";
import AtmosPrevLayout from "@/components/buttomPane/layouts/AtmosPrevLayout.vue";
import MarineStationLayout from "@/components/buttomPane/layouts/MarineStationLayout.vue";
import ItineraryTab from "@/components/buttomPane/itinerary/ItineraryTab.vue";
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import MeteoMaritimeLayout from "@/components/buttomPane/layouts/MeteoMaritimeLayout.vue";
import LayerLegends from "@/components/buttomPane/layouts/LayerLegends.vue";

export default {
  components: {
    MenuTabButton,
    AreaInfo,
    StationsListAndDetails,
    MeteoStationExtendedLayout,
    ItineraryTab,
    MarineStationLayout,
    AtmosPrevLayout,
    MeteoMaritimeLayout,
    LayerLegends,
    layerInfoModal,
  },
  data() {
    return {
      activeTab: null,
      tabEnum: {
        INFORMATION: "information",
        MARINE_STATIONS: "marine_all",
        ATMOSPHERIC_STATIONS: "atmos_all",
        ATMOS_PREVISION_STATIONS: "atmos_prevision_stations",
        ITINERARY: "itinerary",
      },
      selectedMarineStation: null,
      selectedMarineStationData: null,
      selectedAtmosStation: null,
      selectedAtmosphericStationData: null,
      selectedAtmosPrevisionData: null,
      selectedMeteoMaritimeData: null,
      meteoKey: 0,
      selectedLayer: "",
    };
  },
  created() {
    this.Layer = Layer;
  },
  mounted() {
    this.Category = stationDataLayer.Category;

    this.$root.$on("ogsl::offsetpane-click", this.handleOffsetPaneClick);
  },
  destroyed() {
    this.$root.$off("ogsl::offsetpane-click", this.handleOffsetPaneClick);
  },
  computed: {
    selectedStation() {
      return this.$store.state.selectedStation;
    },
    tabs() {
      let out = { ...this.tabEnum };
      delete out.ATMOS_PREVISION_STATIONS;
      return out;
    },
    allMarineStations() {
      let out = [];
      out.push({
        title: this.$t("marinestation-list-title"),
        stations: this.$store.state.nearStations.filter(
          (x) => x.category === stationDataLayer.Category.Marine
        ),
      });
      return out;
    },
    allAtmosStations() {
      let out = [];
      out.push({
        title: this.$t("meteostation-list-title"),
        stations: this.$store.state.nearStations.filter(
          (x) => x.category === stationDataLayer.Category.Atmospheric
        ),
      });
      out.push({
        title: this.$t("meteoprevstation-list-title"),
        stations: this.$store.state.nearStations.filter(
          (x) => x.category === stationDataLayer.Category.AtmosPrevision
        ),
      });
      out.push({
        title: this.$t("meteomaritime-list-title"),
        stations:
          this.$store.state.nearMeteoMaritimeStations === null
            ? []
            : this.$store.state.nearMeteoMaritimeStations,
      });
      return out;
    },
    marineStations() {
      return this.$store.state.nearStations.filter(
        (x) => x.category === stationDataLayer.Category.Marine
      );
    },
    atmosphericStations() {
      return this.$store.state.nearStations.filter(
        (x) => x.category === stationDataLayer.Category.Atmospheric
      );
    },
    atmosPrevisionStations() {
      return this.$store.state.nearStations.filter(
        (x) => x.category === stationDataLayer.Category.AtmosPrevision
      );
    },
  },
  watch: {
    "window.innerWidth": function (newValue) {
      console.log(newValue);
    },
    "$store.state.selectedCoordinates": function () {
      if (this.activeTab) return;
      this.activeTab = this.tabEnum.INFORMATION;
    },

    selectedStation: function (newValue) {
      if (newValue == null) {
        this.selectedMarineStation = null;
        this.selectedAtmosStation = null;
        return;
      }

      if (newValue.category === stationDataLayer.Category.Marine) {
        this.activeTab = this.tabEnum.MARINE_STATIONS;
        this.selectedMarineStation = this.marineStations.find(
          (station) => station.id === newValue.id
        );

        //revoir: Toutes les données sont récupérées ici alors qu elles sont utilisées unitairement dans les composants enfants, ça pourrait être plus optimal ==> à discuter
        stationDataLayer
          .getMarineStationDataWithCache(
            this.selectedMarineStation.id,
            stationDataLayer.MarineDataCode.All
          )
          .then((data) => {
            this.selectedMarineStationData = data;
          });

        return;
      }

      if (newValue.category === stationDataLayer.Category.Atmospheric) {
        this.activeTab = this.tabEnum.ATMOSPHERIC_STATIONS;
        this.selectedAtmosStation = this.atmosphericStations.find(
          (station) => station.id === newValue.id
        );

        this.updateAtmosphericStationData();

        return;
      }
      if (newValue.category === stationDataLayer.Category.AtmosPrevision) {
        this.activeTab = this.tabEnum.ATMOSPHERIC_STATIONS;
        this.selectedAtmosStation = this.atmosPrevisionStations.find(
          (station) => station.id === newValue.id
        );
        stationDataLayer
          .getAtmosPrevisionStationDataWithCache(
            this.selectedAtmosStation.id,
            this.selectedAtmosStation.province
          )
          .then((data) => {
            this.selectedAtmosPrevisionData = data;
          });

        return;
      }

      if (newValue.category === stationDataLayer.Category.MeteoMaritime) {
        this.activeTab = this.tabEnum.ATMOSPHERIC_STATIONS;
        this.selectedAtmosStation = this.$store.state.nearMeteoMaritimeStations.find(
          (station) => station.id === newValue.id
        );
        let properties = this.selectedAtmosStation.feature.getProperties();
        stationDataLayer
          .getMeteoMaritimeDataWithCache(properties.area, properties.region_id)
          .then((data) => {
            this.selectedMeteoMaritimeData = data;
          });
        return;
      }
    },
    "$i18n.locale": function () {
      this.meteoKey += 1;
    },
    "$store.state.selectedTime": function () {
      this.updateAtmosphericStationData();
    },
    "$store.state.selectedLayer": function (value) {
      this.selectedLayer = value;
    },
  
  },
  methods: {
    handleOffsetPaneClick() {
      this.activeTab = this.tabEnum.TIME_SELECTOR;
    },
    updateAtmosphericStationData() {
      if (!this.selectedAtmosStation) {
        this.selectedAtmosphericStationData = null;
        return;
      }

      stationDataLayer
        .getAtmosphericStationData(
          this.selectedAtmosStation.id,
          this.$store.state.selectedTime
        )
        .then((data) => {
          this.selectedAtmosphericStationData = data;
        });
    },
    handleTabClick(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style>
.bottom-pane {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  text-align: left;
  overflow: hidden;
  z-index: 2;
}

.bottom-pane.active {
  left: 0;
}
.layer-legend-container {
  color: white;
  position: absolute;
  right: 50px;
  top: 50px;
  width: 235px;
}

@media (max-width: 920px) {
  .layer-legend-container {
    position: static;
    width: 100%;
  }
}
.tabs-menu {
  margin-bottom: 7px;
  max-width: 350px;
  overflow: visible;
  display: flex;
  width: auto;
  margin: 10px auto;
  justify-content: space-evenly;
}

@media (max-width: 850px) {
  .tab {
    padding: 10px 10px 10px 10px;
  }

  .tabs-menu {
    margin-bottom: 7px - 5px;
    max-width: 250px;
  }

  .close-icon {
    top: 20px !important;
  }
}
@media (max-width: 850px) {
  .close-icon {
    top: 75px !important;
  }
}

.tab-content {
  pointer-events: auto;
  background-color: white;
  height: 45vh;
  overflow: auto;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

@media (max-height: 350px) {
  .tab-content {
    height: 45vh;
  }
}

.close-icon {
  position: absolute;
  top: 85px;
  right: 15px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin: 5px 5px;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.5s;
  max-height: 55vh;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
.meteo-items {
  display: flex;
  /* max-width:400px; */
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}
.meteo-item {
  border-radius: 10px;
  border-style: solid;
  border-color: #d3d3d3;
  margin: 10px;
  text-align: center;
}
.meteo-item-title {
  background-color: #d3d3d3;
  padding: 3px;
}
.meteo-item-value {
  padding: 2px;
}
</style>
