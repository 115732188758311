<template>
    <div>
        <MeteoMaritimeLayout v-show="regionData != null" :regionData="regionData"></MeteoMaritimeLayout>
        <div v-show="regionData === null" class="no-marine-forecasts-warning">
            Aucune région maritime à proximité
        </div>
    </div>

</template>

<script>
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import MeteoMaritimeLayout from "@/components/buttomPane/layouts/MeteoMaritimeLayout.vue";
export default {
    components: { MeteoMaritimeLayout },
    data() {
        return {
            nearestStation: null,
            regionData: null,
            Category: stationDataLayer.Category,
            gotMeteoRegion: false,
        };
    },
    mounted(){
        let nearStations = this.$store.state.nearMeteoMaritimeStations
        if (nearStations !=null && nearStations.length != 0){
            this.nearestStation = this.$store.state.nearMeteoMaritimeStations[0]
        }
    },
    watch: {
        nearestStation: function (newValue) {
            let properties = newValue.feature.getProperties()
            stationDataLayer.getMeteoMaritimeDataWithCache(properties.area, properties.region_id).then((data) => {
                this.regionData = data;

            });
        },
        "$store.state.nearMeteoMaritimeStations": {
            deep: true,
            handler(newValue) {
                if (newValue != null) {
                    this.nearestStation = newValue[0]
                }

            },
        },
    },
};
</script>

<style>

</style>