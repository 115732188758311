<template>
  <div>
    <div v-show="selectedDate === getDate(new Date())" class="infoSectionTitle">
      {{ $t("areainfo-tide-section-title") }}
    </div>
    <div v-show="selectedDate != getDate(new Date())" class="infoSectionTitle">
      {{ $t("tides-for") }} {{ selectedDay }}
    </div>
    <div class="titleNameTides">
      <span
        v-if="nearestStation != null"
        class="stationNameClick"
        @click="goToStation()"
        >{{ nearestStation.name }}</span
      >
      <span class="distanceFromClick">{{ stationDisplayedString }}</span>
    </div>
    <span v-if="isStationWithNoTide">{{ $t("areainfo-no-tide") }}</span>
    <b-table
      v-if="showTideTable"
      striped
      hover
      small
      :items="todaysTide"
      :fields="fields"
      class="table-widget"
    >
      <template #cell(icon)="data">
        <img :src="data.value" width="20" height="20" />
      </template>
      ></b-table
    >
  </div>
</template>

<script>
import displayTidesMixin from "@/components/buttomPane/layouts/mixins/displayTidesMixin";
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import { daysLong } from "@/common/time-utils";
export default {
  mixins: [displayTidesMixin],
  data() {
    return {
      todaysTide: null,
      distanceFromClick: null,
      showTides: false,
      tideData: null,
    };
  },
  computed: {
    isStationWithNoTide() {
      return this.tideData && this.tideData.length === 0;
    },
    showTideTable() {
      return this.tideData && this.tideData.length > 0;
    },
    nearestStation() {
      const nearStations = this.$store.state.nearStations.filter(
        (x) => x.category === stationDataLayer.Category.Marine
      );
      return nearStations.length > 0 ? nearStations[0] : null;
    },
    selectedDate() {
      return this.getDate(this.$store.state.selectedTime);
    },
    selectedDay() {
      return daysLong[this.$i18n.locale][
        this.$store.state.selectedTime.getDay()
      ];
    },
    stationDisplayedString() {
      if (!this.tideData) {
        return this.$t("areainfo-tide-section-no-station");
      }
      return ` | ${this.distanceFromClick} ${this.$t("km-distance")}`;
    },
  },
  methods: {
    goToStation: function () {
      this.$store.mutations.updateSelectedStation(
        this.nearestStation.id,
        this.nearestStation.latitude,
        this.nearestStation.longitude,
        this.nearestStation.category,
        this.nearestStation.name,
        true
      );
    },
    updateTideData() {
      if (!this.nearestStation) {
        this.tideData = null;
        return;
      }

      stationDataLayer
        .getMarineStationDataWithCache(
          this.nearestStation.id,
          stationDataLayer.MarineDataCode.wlp_hilo
        )
        .then((data) => {
          this.tideData = data && "wlp-hilo" in data ? data["wlp-hilo"] : null;
        });
    },
    updateTideTable: function () {
      if (this.tideData) {
        this.buildTideTable([...this.tideData]);
        let today = this.formatDate(this.$store.state.selectedTime);
        for (let i = 0; i < this.tidesTable.length; i++) {
          if (this.tidesTable[i].date === today) {
            this.todaysTide = this.tidesTable[i].items;
          }
        }
        this.distanceFromClick = Math.round(
          this.nearestStation.distanceFromClick
        );
      }
    },
    getDate: function (date) {
      var dd = String(date.getDate());
      var mm = String(date.getMonth() + 1);
      var yyyy = date.getFullYear();
      return mm + "/" + dd + "/" + yyyy;
    },
  },
  mounted() {
    this.updateTideData();
  },
  watch: {
    nearestStation: function () {
      this.updateTideData();
    },
    tideData() {
      this.updateTideTable();
    },
    "$store.state.selectedCoordinates": function () {
      if (this.nearestStation === null) return
      this.distanceFromClick = Math.round(
          this.nearestStation.distanceFromClick
        );
    },
    "$store.state.selectedTime": function () {
      this.updateTideTable();
    },
  },
};
</script>

<style>
.titleNameTides {
  text-align: center;
}
.distanceFromClick {
  font-size: 12px;
  font-style: italic;
}
.stationNameClick {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: #00adef;
}
.stationNameClick:hover {
  text-decoration: underline;
}
</style>