<template>
    <div v-on:mouseleave="paneState=false">
        <div @click="paneState = !paneState" >
            <div
                class="layer-text-legend-icon"
                v-bind:class="{
                    hovered: iconHovered,
                }"
            >
                <span class="layer-text">Stations</span>
            </div>
            <img
                class="layer-icon"
                :src="
                    paneState
                        ? require(`@/assets/stations_all_icon.svg`)
                        : require(`@/assets/stations_all_icon.svg`)
                "
                @mouseover="iconHovered = true"
                @mouseleave="iconHovered = false"
            />
        </div>
        <transition name="fadeOpacityLegend" mode="out-in">
            <div v-show="paneState" class="stationSelectorPaneContent" >
                <VectorLayerSelector
                    @vectorLayerSelectedChanged="vectorLayerEmition"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import VectorLayerSelector from "@/components/map/rightSidePane/VectorLayerSelector.vue";
export default {
    components: { VectorLayerSelector },
    data() {
        return { paneState: false, iconHovered: false };
    },
    methods: {
        vectorLayerEmition: function (value) {
            this.$store.mutations.updateSelectedVectorLayers(value);
        },
    },
};
</script>

<style>
.stationSelectorPaneContent {
    pointer-events: auto;
    overflow:auto;
}
.fadeOpacityLegend-enter,
.fadeOpacityLegend-leave-to {
    opacity: 0;
    /* max-height: 0px; */
}
.fadeOpacityLegend-enter-active,
.fadeOpacityLegend-leave-active {
    transition: all 0.5s;
    /* max-height: 200px; */
}
</style>