<template>
  <div class="extendedTides">
    <div class="infoSectionTitle">
      {{ $t("marinestation-details-tide-section-title") }}
    </div>
    <div class="week-tide-buttons">
      <div class="week-tide-button" v-for="item in tidesDevidedByWeek" :key="item[0].date" @click="clicked(item)" :class="item===itemClicked? 'active-marine-button'
        : ''
      ">
        <div>{{ weekDays[$i18n.locale][item[0].datetime.getDay()] }} {{ dayWithoutYear(item[0].date) }}</div>
        <div>{{ weekDays[$i18n.locale][item[item.length - 1].datetime.getDay()] }} {{ dayWithoutYear(item[item.length -
          1].date) }}</div>
      </div>
    </div>

    <div class="allTideTables">
      <div v-for="item in itemClicked" :key="item.date">
        <div class="dateTideTableExtended">{{ weekDays[$i18n.locale][item.datetime.getDay()] }} {{ item.date }}</div>
        <b-table striped hover small :items="item.items" :fields="fields" class="tideTableLong">
          <template #cell(icon)="data">
            <img :src="data.value" width="20" height="20" />
          </template>
          ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import displayTidesMixin from "@/components/buttomPane/layouts/mixins/displayTidesMixin";
import { days } from "@/common/time-utils";
export default {
  props: ["stationData"],
  mixins: [displayTidesMixin],
  data() {
    return {
      weekDays: days,
      dateString: "",
      itemClicked: null,
    };
  },
  computed: {
    tideData() {
      return this.stationData && "wlp-hilo" in this.stationData
        ? this.stationData["wlp-hilo"]
        : null;
    },
    tidesDevidedByWeek() {
      let tidesDevidedByWeek = []
      let currentWeek = []
      for (let date of this.tidesTable) {
        if (date.datetime.getDay() === 6) {
          currentWeek.push(date)
          tidesDevidedByWeek.push(currentWeek)
          currentWeek = []
        }
        else {
          currentWeek.push(date)
        }
      }
      // si la première semaine contient seulement une valeur, c'est celle d'hier. Donc on l'enlève. 
      if (tidesDevidedByWeek.length > 0) {
        if (tidesDevidedByWeek[0].length === 1) {
          tidesDevidedByWeek.shift()
        }
      }
      return tidesDevidedByWeek
    },


  },
  methods: {
    dayWithoutYear(dateString) {
      let dateArray = dateString.split("-", 2)
      return dateArray.join("-")
    },
    clicked(element) {
      this.itemClicked = element
      // document.getElementById('detailed-day-weather-container').scrollIntoView({ behavior: 'smooth' })

    },
  },
  mounted(){
    this.itemClicked = this.tidesDevidedByWeek[0]
  },
  watch:{
    "tidesDevidedByWeek": function(){
      this.itemClicked = this.tidesDevidedByWeek[0]
    }
  }
};
</script>

<style>
.dateTideTableExtended {
  color: #404041;
  font-size: 13px;
  font-weight: bold;
}

.week-tide-button {
  position: relative;
  width: 120px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
  text-align: center;
  border-radius: 10px;
}

.week-tide-button:hover {
  background-color: #bec0c2;
  ;
}

.week-tide-buttons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tides-button-sep {
  position: absolute;
  top: 20px;
  height: 4px;
  width: 50px;
  border: 0;
  background: #00adef;
  left: 50%;
  transform: translate(-50%, 0);
}

.fadeWidth-enter-active,
.fadeWidth-leave-active {
  transition: all 0.7s;
  max-width: 50px;
}

.fadeWidth-enter,
.fadeWidth-leave-to {
  opacity: 1;
  max-width: 0px;
}
.active-marine-button{
  background-color: #bec0c2;
}
</style>