<template>
  <div class="app-header">
    <div class="header-left">
      <div class="logo-and-name">
        <a :href="ogslUrl" target="_blank" class="logo-ogsl"
          ><img src="@/assets/logo_ogsl.svg"
        /></a>
        <div class="texte-ogsl">Navigation</div>
      </div>
    </div>
    <div class="point-general-info-container">
      <img
        v-b-modal.modal-1
        class="point-general-info"
        src="@/assets/icon_pt_inter_general.svg"
      />
      <img
        @click="switchLocale"
        class="point-general-info"
        :src="
          require('@/assets/icon_language_' +
            ($i18n.locale === 'fr' ? 'en' : 'fr') +
            '.svg')
        "
      />
      <b-modal id="modal-1" ref="startup-modal" size="xl">
        <div class="modal-whole">
          <div class="modal-text">
            <div class="modal-custom-header">
              <img class="logo-ogsl-modal" src="@/assets/logo_ogsl.svg" />
              <button
                type="button"
                aria-label="Close"
                class="close"
                @click="$bvModal.hide('modal-1')"
              >
                ×
              </button>
            </div>

            <div class="modal-core-section">
              <img src="@/assets/new_feat.svg" width="30px" class="" />
              <div class="modal-core-section-text">
                <div class="modal-core-section-text-title">
                  {{ $t("new-release-title") }}
                </div>
                <p class="header-introduction-text" v-html="translatedNewReleaseText"></p>
              </div>
            </div>
            <div class="modal-core-section">
              <img src="@/assets/logo_navigation.svg" width="30px" class="" />
              <div class="modal-core-section-text">
                <PopupGeneralInfoSection></PopupGeneralInfoSection>
              </div>
            </div>
            <div class="modal-buttons">
              <a class="modal-button modal-button-first" @click="$bvModal.hide('modal-1')"
                ><span>{{ $t("understood") }}</span>
              </a>
              <a :href="surveyUrl" class="modal-button modal-button-second">{{
                $t("header-survey-button")
              }}</a>
            </div>
          </div>
          <img src="@/assets/modal-intro-image.webp" width="300px" class="popup-image" />
        </div>
      </b-modal>
      <StartupPopup
        :noCloseOnBackdrop="true"
        id="modal-disclaimer"
        modalRef="disclaimer-modal"
        ref="disclaimer-modal-container"
        size="xl"
      >
        <template #core-icon
          ><img src="@/assets/logo_navigation.svg" width="30px" class=""
        /></template>
        <template #core-text>
          <PopupGeneralInfoSection></PopupGeneralInfoSection>
          <p class="header-introduction-text" v-html="surveyClick"></p>
        </template>
        <template #modal-buttons>
          <a class="modal-button modal-button-first" @click="closeDisclaimer()"
            ><span>{{ $t("understood") }}</span>
          </a>
        </template>
        <template #main-image>
          <img src="@/assets/modal-intro-image.webp" width="300px" />
        </template>
      </StartupPopup>

      <StartupPopup
        :noCloseOnBackdrop="false"
        id="new-features"
        modalRef="new-features"
        ref="new-features-container"
        size="lg"
      >
        <template #core-icon
          ><img src="@/assets/new_feat.svg" width="30px" class=""
        /></template>
        <template #core-text>
          <div class="modal-core-section-text-title" >{{ $t("new-release-title") }}</div>
          <p class="header-introduction-text" v-html="translatedNewReleaseText"></p>
          <p class="header-introduction-text">
            {{ $t("header-introduction-data-source-end")
            }}<a :href="contextUrl" target="_blank">{{ $t("header-about-button") }}</a>
          </p>
        </template>
        <template #modal-buttons>
          <a
            class="modal-button modal-button-first"
            @click="$bvModal.hide('new-features')"
          >
            <span>{{ $t("close") }}</span>
          </a>
        </template>
        <!-- <template #main-image>
          <img src="@/assets/mtl-currents.webp" width="330px" />
        </template> -->
      </StartupPopup>
    </div>

    <TheTimeSlider v-if="itinerarySliderEmpty" />
  </div>
</template>

<script>
import TheTimeSlider from "@/components/header/TheTimeSlider.vue";
import StartupPopup from "@/components/header/StartupPopup.vue";
import PopupGeneralInfoSection from "@/components/header/PopupGeneralInfoSection.vue";
export default {
  components: { TheTimeSlider, StartupPopup, PopupGeneralInfoSection },
  computed: {
    contextUrl() {
      return process.env.VUE_APP_OGSL_URL + this.$t("external-context-end-url");
    },
    surveyUrl() {
      return "https://docs.google.com/forms/d/1kZryhNEhxbeLEPfpIn4jEji4q8NAMxuW2-ULcTJVNF4"
      // return this.$t("external-survey-url");
    },
    ogslUrl() {
      return "https://ogsl.ca/" + this.$i18n.locale;
    },
    logoECCC() {
      return require("@/assets/EC-" + this.$i18n.locale + ".png");
    },
    logoMPO() {
      return require("@/assets/MPO-" + this.$i18n.locale + ".png");
    },
    itinerarySliderEmpty() {
      if (this.$store.state.itineraryCoordinates.length > 0) {
        return false;
      }
      return true;
    },
    translatedNewReleaseText() {
      return this.$t("new-release-text");
    },
    surveyClick(){
      return this.$t("surveySentence")
    }
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "fr") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "fr";
      }
    },
    closeDisclaimer() {
      this.$bvModal.hide("modal-disclaimer");
      this.$cookies.set("disclaimerAccepted", true, "2y");
    },
  },
  mounted() {
    if (!this.$cookies.isKey("disclaimerAccepted")) {
      this.$refs["disclaimer-modal-container"].getChildRef().show();
      this.$cookies.set("newReleaseSummer2024", true, "2y");
    } else if (!this.$cookies.isKey("newReleaseSummer2024")) {
      this.$refs["new-features-container"].getChildRef().show();
      this.$cookies.set("newReleaseSummer2024", true, "2y");
    }
  },
};
//revoir: utiliser style scoped pour le css
</script>

<style>
.app-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  height: 40px;
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-left: 10px;
  pointer-events: none;
  color: #707070;
}

.header-left {
  display: flex;
}

.modal-footer {
  display: none !important;
}

.logo-and-name {
  height: 55px;
  pointer-events: none;
  background-color: white;
  border-radius: 8px;
  pointer-events: auto;
  padding: 4px;
}

.texte-ogsl {
  font-weight: bold;
  /* font-size: large;
  padding: 5px 6px 5px 6px; */
}

.point-general-info-container {
  display: flex;
  height: 100%;
  order: 2;
  width: 90px;
}

.point-general-info {
  height: 35px;
  pointer-events: auto;
  margin-top: 3px;
  margin-right: 2px;
  margin-left: 2px;
}

.point-general-info:hover {
  height: 40px;
  pointer-events: auto;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 0px;
}

.modal-body {
  color: #707070;
  padding: 0px !important;
}

.modal-core-section-text-title {
  margin-bottom: 7px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .point-general-info-container {
    order: initial;
    margin-right: 15px;
    align-items: center;
  }

  .header-left {
    margin-left: 15px;
    margin-top: 7px;
  }

  .time-button-slider-container {
    order: 0;
    flex: 1 1 100%;
  }

  .app-header {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 42px;
    margin-top: 0px;
    background-color: white;
    margin-left: 0px;
  }

  .logo-and-name {
    padding: 0px;
    white-space: nowrap;
    height: auto;
    pointer-events: none;
    background-color: transparent;
  }

  .texte-ogsl {
    font-size: medium;
    display: inline-block;
    vertical-align: text-top;
  }

  .point-general-info {
    height: 30px;
  }

  .point-general-info:hover {
    height: 35px;
  }
}

@media (max-width: 550px) {
  .point-general-info-container {
    justify-content: flex-end;
  }

  .header-left {
    margin-top: 0px;
  }

  .point-general-info {
    height: 20px;
    pointer-events: auto;
  }

  .point-general-info:hover {
    height: 22px;
  }

  .app-header {
    height: 30px;
  }
}

@media (max-width: 310px) {
  .logo-and-name {
    height: 23px;
    padding: 0px;
  }

  .texte-ogsl {
    font-size: small;
  }
}

.modal-header-title {
  text-align: center;
  font-weight: bold;
}

.modal-header {
  display: none !important;
}

.modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  text-align: center;
  padding: 10px 10px 10px 10px;
  border: 1px solid #2d9eaa;
  cursor: pointer;
  line-height: 1;
}

.modal-button-first {
  background: #2d9eaa 0% 0% no-repeat padding-box;
  color: white;
}

.modal-button-second {
  background-color: white;
  color: #2d9eaa;
}

.modal-footer {
  justify-content: space-evenly !important;
  padding: 2px !important;
}

.close {
  display: none;
}

@media (max-width: 1200px) {
  .popup-image {
    display: none;
  }

  .close {
    display: block;
  }
}

.header-introduction-text {
  white-space: pre-line;
  font-size: 13px;
}

.partner-logo {
  padding: 0px 10px 0px 10px;
  align-self: center;
}

.partners-logo {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
