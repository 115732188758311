<template>
    <div>
        <div class="infoSectionTitle">
            {{ $t("areainfo-map-predictions-section-title") }}
        </div>
        <div class="currents-info-click-title-model">
            <div class="currents-info-click-title">
                {{ $t("layer-title-currents") }}
            </div>
            <div v-show="currentModel!=''">
                <div class="currents-info-click-model" v-show="$i18n.locale === 'fr'">
                    | Modèle {{ currentModel }}
                </div>
                <div class="currents-info-click-model" v-show="$i18n.locale === 'en'">
                    | {{ currentModel }} model
                </div>
            </div>

        </div>
        <div class="currents-info-click">
            <img class="layer-icon-info-pane" :src="require(`@/assets/icon_courant.svg`)" />
            <div v-if="currentsPresent" class="currents-table-container">
                <b-table sticky-header="300px" striped hover :items="currentItems" thead-class="d-none"
                    class="table-widget-info-click"></b-table>
            </div>
            <div class="no-data-string" v-else>
                {{ $t("areainfo-current-section-no-data") }}
            </div>
        </div>
        <div id="current-legend"></div>
        <div class="currents-info-click-title-model">
            <div class="currents-info-click-title">
                {{ $t("layer-title-winds") }}
            </div>
            <div class="currents-info-click-model">
                {{ $t("wind-model-title") }}
            </div>
        </div>
        <div class="currents-info-click">
            <img class="layer-icon-info-pane" :src="require(`@/assets/icon_vent.svg`)" />
            <div v-if="windsPresent" class="currents-table-container">
                <b-table sticky-header="300px" striped hover :items="windItems" thead-class="d-none"
                    class="table-widget-info-click"></b-table>
            </div>
            <div class="no-data-string" v-else>
                {{ $t("areainfo-wind-section-no-data") }}
            </div>
        </div>
        <div class="currents-info-click-title-model">
            <div class="currents-info-click-title">
                {{ $t("layer-title-gusts") }}
            </div>
            <div class="currents-info-click-model">
                {{ $t("wind-model-title") }}
            </div>
        </div>
        <div class="currents-info-click">
            <img class="layer-icon-info-pane" :src="require(`@/assets/rafales.svg`)" />
            <div v-if="windsPresent" class="currents-table-container">
                <b-table sticky-header="300px" striped hover :items="gustItems" thead-class="d-none"
                    class="table-widget-info-click"></b-table>
            </div>
            <div class="no-data-string" v-else>
                {{ $t("areainfo-gust-section-no-data") }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentModel: function () {
            const modelDict = {
                stle200: { fr: { provider: "MPO", name: "ESTL200" }, en: { provider: "DFO", name: "STLE200" } },
                stle: { fr: { provider: "MPO", name: "STLE400" }, en: { provider: "DFO", name: "STLE400" } },
                gulf: { fr: { provider: "ECCC", name: "SPCOG-Est" }, en: { provider: "ECCC", name: "CIOPS-East" } },
                shop: { fr: { provider: "ECCC", name: "SHOP" }, en: { provider: "ECCC", name: "OPHS" } }
            }
            if (this.$store.state.selectedCurrents != null && this.$store.state.selectedCurrents.model != undefined) {
                let model = modelDict[this.$store.state.selectedCurrents.model][this.$i18n.locale]
                return `${model["name"]} - ${model["provider"]}`;
            }
            return ""
        },
        u: function () {
            if (this.$store.state.selectedCurrents != null) {
                var uTemp = this.$store.state.selectedCurrents.u;
                if (uTemp === null || uTemp > 1000 || uTemp * uTemp === 0) {
                    return null;
                }
                return this.msToKnots(this.$store.state.selectedCurrents.u);
            }
            return null;
        },
        v: function () {
            if (this.$store.state.selectedCurrents != null) {
                var vTemp = this.$store.state.selectedCurrents.v;
                if (vTemp === null || vTemp > 1000 || vTemp * vTemp <= 0) {
                    return null;
                }
                return this.msToKnots(this.$store.state.selectedCurrents.v);
            }
            return null;
        },
        currentsPresent: function () {
            if (Math.abs(this.u) > 1000 || Math.abs(this.v) > 1000) {
                return false;
            }
            if (this.u === null || this.v === null) {
                return false;
            }
            return true;
        },
        windsPresent: function () {
            if (
                this.$store.state.selectedWinds === null ||
                this.$store.state.selectedWinds.magnitude === null
            ) {
                return false;
            }
            return true;
        },
        currentMagnitude: function () {
            if (this.currentsPresent) {
                return Math.sqrt(this.u * this.u + this.v * this.v);
            } else {
                return null;
            }
        },
        currentDirection: function () {
            var currentDirTrig = Math.atan2(
                this.u / this.currentMagnitude,
                this.v / this.currentMagnitude
            );
            return currentDirTrig * (180 / Math.PI);
        },
        currentItems() {
            let itemObject = {};
            itemObject[this.$t("speed")] =
                Math.round(this.currentMagnitude * 10) / 10 +
                " " +
                this.$t("knots");
            itemObject["Direction"] =
                Math.round(this.currentDirection) + " " + this.$t("degrees");
            return Object.entries(itemObject);
        },

        windSpeed: function () {
            if (this.$store.state.selectedWinds != null) {
                return this.msToKnots(
                    this.$store.state.selectedWinds.magnitude
                );
            }
            return null;
        },
        gustSpeed: function () {
            if (this.$store.state.selectedWinds != null) {
                return this.msToKnots(
                    this.$store.state.selectedWinds.gustMagnitude
                );
            }
            return null;
        },
        windDirection: function () {
            if (this.$store.state.selectedWinds != null) {
                return this.degToCompass(
                    parseInt(this.$store.state.selectedWinds.direction)
                );
            }
            return null;
        },
        windItems() {
            let itemObject = {};
            itemObject[this.$t("speed")] =
                Math.round(this.windSpeed * 10) / 10 + " " + this.$t("knots");
            itemObject["Direction"] = this.windDirection;
            return Object.entries(itemObject);
        },
        gustItems() {
            let itemObject = {};
            itemObject[this.$t("speed")] =
                Math.round(this.gustSpeed * 10) / 10 + " " + this.$t("knots");
            itemObject["Direction"] = this.windDirection;
            return Object.entries(itemObject);
        },
    },
    methods: {
        msToKnots: function (value) {
            return value * 1.94384;
        },
        degToCompass: function (num) {
            let val = parseInt(num / 22.5 + 0.5);
            let arr = [
                "N",
                "NNE",
                "NE",
                "ENE",
                "E",
                "ESE",
                "SE",
                "SSE",
                "S",
                this.$t("SSW"),
                this.$t("SW"),
                this.$t("WSW"),
                this.$t("W"),
                this.$t("WNW"),
                this.$t("NW"),
                this.$t("NNW"),
            ];
            return arr[val % 16];
        },
    },
};
</script>

<style>
.table-currents {
    border-radius: 10px;
    border: 2px solid #bec0c2;
    margin: 4px;
    max-width: 300px;
}

.currentsLegend {
    max-width: 100%;
    padding: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.layer-icon-info-pane {
    width: 40px;
    padding: 5px;
    margin: 5px 15px 5px 0px;
}

.currents-info-click {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.currents-table-container {
    height: 40px;
}

.table-widget-info-click td {
    padding: 0px !important;
}

.table-widget-info-click {
    margin: 0;
    width: 200px;
}

.currents-info-click-title-model {
    display: flex;
    align-items: center
}

.currents-info-click-title {
    text-align: left;
    font-weight: bold;
    margin-left: 10px;
}

.currents-info-click-model {
    font-size: 12px;
    font-style: italic;
    margin: auto 4px auto 4px;
}

.no-data-string {
    font-size: 14px;
    font-style: italic;
    width: 200px;
}

@media (max-width: 550px) {
    .table-widget-info-click {
        width: 175px;
    }

    .no-data-string {
        width: 175px;
    }

    .layer-icon-info-pane {
        width: 40px;
        padding: 5px;
        margin: 10px;
    }
}</style>