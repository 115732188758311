<template>
  <div
    style="margin-left: 5%"
    class="ml-10 tab tab-name"
    v-bind:class="{
      active: activeTab === menuTabName,
    }"
    @click="$emit('activeTabFromChild', menuTabName)"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
    <div class="tab-img-text">
      <img
        class="tab-img"
        id="information_icon"
        :src="
          activeOrHovered
            ? require('@/assets/' + menuTabName + '_icon_white.svg')
            : require('@/assets/' + menuTabName + '_icon.svg')
        "
      />
      <div class="tab-text">{{ $t(menuTabName + "-icon-title") }}</div>
    </div>

    <div
      v-if="menuTabName === 'itinerary' && activeTab != 'itinerary'"
      v-show="itineraryActive"
      class="offset-small"
    >
      !
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuTabName: String,
    activeTab: String,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    activeOrHovered: function () {
      if (this.isHovered) return true;
      if (this.activeTab === this.menuTabName) return true;
      return false;
    },
    itineraryActive() {
      if (
        this.$store.state.itineraryCoordinates.length > 0 ||
        this.$store.state.mapClickEvent === "itinerary"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    mouseOver: function () {
      this.isHovered = true;
    },
    mouseLeave: function () {
      this.isHovered = false;
    },
  },
};
</script>

<style scoped>
.tab {
  pointer-events: auto;
  display: inline;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-right: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.tab:hover {
  background-color: #00adef;
}

.tab:hover .tab-text {
  color: white !important;
}
.tab.active {
  background-color: #00adef;
}

.tab.active .tab-text {
  color: white !important;
}

.tab-name {
  position: relative;
  overflow: visible;
  text-align: center;
  width: 60px;
  height: 60px;
  padding: 0px !important;
}

.tab-name span {
  writing-mode: vertical-lr;
  text-orientation: sideways;
  white-space: nowrap;
}
.tab-img-text {
  padding-top: 5px;
  margin: 0 auto;
  vertical-align: middle;
}
.tab-img {
  width: 30px;
  height: 30px;
  pointer-events: none;
}
.tab-text {
  color: #00adef;
  font-size: small;
  pointer-events: none;
  text-align: center;
}
.offset-small {
  position: absolute;
  font-size: 18px;
  top: -10px;
  right: -15px;
  color: white;
  height: 28px;
  width: 28px;
  background-color: #2D9EAA;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
}
@media (max-width: 850px), (max-height: 350px) {
  .tab-img {
    width: 20px;
    height: 20px;
  }
  .tab-text {
    font-size: 10px;
  }
  .tab-name {
    width: 43px;
    height: 43px;
  }
  .tab-img-text {
    padding-top: 2px;
  }
    .offset-small {
    font-size: 15px;
    height: 23px;
    width: 23px;
    line-height: 23px;
    right: -11px;
    top: -7px;
  }
}

</style>