<template>
    <div class="map-toggle">
        <div class="map-toggle-icon-container" :title="this.$t('mapSelectionTitle')">
            <img class="map-toggle-icon" src="@/assets/map-toggle-icon.svg" @click="mapToggleClicked = !mapToggleClicked" />
        </div>
        <div class="map-popup-container" v-show="mapToggleClicked">
            <div class="map-popup-inner-container">
                <div class="map-option" @click="selectBasemap('mapbox')">
                    <img class="map-option-icon" src="@/assets/bw-map-icon.png" />
                    <div class="map-option-text">{{ $t("bw-map-option") }}</div>
                </div>
                <div class="map-option" @click="selectBasemap('satellite')">
                    <img class="map-option-icon" src="@/assets/satellite-icon.png" />
                    <span class="map-option-text">Satellite</span>
                </div>
                <!-- <div class="map-option" @click="selectBasemap('bwd')">
                    <img class="map-option-icon" src="@/assets/bw-depth-icon.png" />
                    <span class="map-option-text">{{ $t("depth") }}</span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["widgetSelected"],
    data() {
        return { mapToggleClicked: false, widgetName: "mapToggle" };
    },
    methods: {
        selectBasemap: function (selection) {
            this.$emit("basemapSelected", selection);
            this.mapToggleClicked = false;
        },
    },
    watch: {
        mapToggleClicked: function (value) {
            if (value === true) {
                this.$emit("widgetOpened", this.widgetName);
            }
        },
        widgetSelected: function (value) {
            if (value != this.widgetName) {
                this.mapToggleClicked = false;
            }
        },
    },
};
</script>

<style>
.map-toggle {
    position: absolute;
    top: 188px;
    left: 0.5em;
    z-index: 1;
}

.map-toggle-icon-container {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    width: 28px;
    height: 28px;
}

.map-toggle-icon-container:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.map-toggle-icon {
    width: 22px;
    height: 22px;
    padding: 2px;
    background-color: rgba(0, 60, 136, 0.6);
    border-radius: 3px;
    cursor: pointer;
}

.map-toggle-icon:hover {
    background-color: rgba(0, 60, 136, 0.8);
}

.map-popup-container {
    position: absolute;
    bottom: 0px;
    left: 2em;
    color: white;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 3px;
    border-radius: 3px;
}

.map-popup-inner-container {
    position: relative;
    background-color: rgba(0, 60, 136, 0.6);
    border-radius: 3px;
}

.map-option-icon {
    width: 22px;
    height: 22px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
}

.map-option-text {
    padding: 0px 5px;
    margin: auto 0px;
    width: 50%;
}

.map-option {
    display: flex;
    padding: 2px;
}

.map-option:hover {
    background-color: rgba(0, 60, 136, 0.8);
    cursor: pointer;
}

@media (max-width: 575px) {
    .map-option-text {
        font-size: 10px;
        vertical-align: center;
    }
}
</style>