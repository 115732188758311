// store.js
import Vue from "vue";
import stationDataLayer from "@/stationDataLayer/stationDataLayer";

const state = Vue.observable({
    map: {},
    selectedCoordinates: null,
    selectedStation: null,
    selectedCurrents: null,
    selectedWinds: null,
    selectedGusts: null,
    selectedTime: null,
    nearStations: [],
    nearMeteoMaritimeStations: [],
    stationsLayerData: stationDataLayer.data,
    selectedLayer: null,
    selectedExtraLayers: [],
    selectedVectorLayers: {},
    mapClickEvent: "location",
    itineraryCoordinates: [],
    downloadedItineraryCoordinates: [],
    itineraryTotalLength: 0,
    detailedItineraryLength: [],
    selectedItineraryFraction: null,
    quebecCoordinatesSelected: false,
    mtlCoordinatesSelected: false,
    gulfCurrentsOffsets: [3000, 7000],
    stle200Status: false
});

const mutations = {
    updateSelectedCoordinates(latitude, longitude) {
        if (
            state.selectedCoordinates &&
            state.selectedCoordinates.latitude == latitude &&
            state.selectedCoordinates.longitude == longitude
        )
            return;

        state.selectedCoordinates = {
            latitude: latitude,
            longitude: longitude,
        };
    },
    updateSelectedStation(
        id,
        latitude,
        longitude,
        category,
        name,
        forceReload = false
    ) {
        //revoir: Ecrire en une ligne
        if (!forceReload) {
            if (state.selectedStation && state.selectedStation.id == id) return;
        }
        let coordinates;
        if (latitude === null || longitude === null) {
            coordinates = null;
        } else {
            coordinates = { latitude: latitude, longitude: longitude };
        }
        state.selectedStation = {
            id: id,
            category: category,
            coordinates: coordinates,
            name: name,
        };
    },
    unselectStation() {
        state.selectedStation = null;
    },
    updateSelectedCurrents(u, v, date, model) {
        state.selectedCurrents = { u: u, v: v, date: date, model: model };
    },
    updateSelectedWinds(direction, magnitude, gustMagnitude, date) {
        state.selectedWinds = {
            direction: direction,
            magnitude: magnitude,
            gustMagnitude: gustMagnitude,
            date: date,
        };
    },
    updateNearStations(nearStations) {
        state.nearStations = nearStations;
    },
    updateNearMeteoMaritimeStations(nearStations) {
        state.nearMeteoMaritimeStations = nearStations;
    },
    updateSelectedTime(time) {
        state.selectedTime = time;
    },
    updateSelectedLayer(layer) {
        state.selectedLayer = layer;
    },
    addSelectedExtraLayers(layer) {
        const index = state.selectedExtraLayers.indexOf(layer);
        if (index < 0) {
            state.selectedExtraLayers.push(layer);
        }
    },
    removeSelectedExtraLayers(layer) {
        const index = state.selectedExtraLayers.indexOf(layer);
        if (index > -1) {
            state.selectedExtraLayers.splice(index, 1);
        }
    },
    updateSelectedVectorLayers(layers) {
        state.selectedVectorLayers = layers
    },
    updateMapClickEvent(event) {
        state.mapClickEvent = event;
    },
    updateItineraryCoordinates(coordinates) {
        state.itineraryCoordinates = coordinates;
    },
    updateDownloadedItineraryCoordinates(coordinates) {
        state.downloadedItineraryCoordinates = coordinates;
    },
    updateItineraryTotalLength(length) {
        state.itineraryTotalLength = length;
    },
    updateSelectedItineraryFraction(fraction) {
        state.selectedItineraryFraction = fraction;
    },
    updateDetailedItineraryLength(lengthArr) {
        state.detailedItineraryLength = lengthArr;
    },
    updateQuebecCoordinatesSelected(value) {
        state.quebecCoordinatesSelected = value;
    },
    updateMtlCoordinatesSelected(value) {
        state.mtlCoordinatesSelected = value;
    },
    updateGulfCurrentsOffsets(value) {
        state.gulfCurrentsOffsets = value;
    },
    updateStle200Status(value) {
        state.stle200Status = value;
    },
};

export default {
    state: state,
    mutations,
};
