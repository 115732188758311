import { QuadTree, Box, Point, Circle } from "js-quadtree";
import { Category, Source } from "@/stationDataLayer/stationDataLayer.js";

const provinces_to_fetch = ["NB", "NL", "QC", "NS", "PE"];
let dataStructure = {};
async function fetchData(obOut, stationId, stationProvince, language) {
    let url = `${process.env.VUE_APP_METEO_PREVISIONS_URL}xml/${stationProvince}/${stationId}_${language}.xml`;
    const request = new XMLHttpRequest();
    request.open("GET", url, false); // `false` makes the request synchronous
    request.send(null);

    const document = await new window.DOMParser().parseFromString(
        request.responseText,
        "text/xml"
    );
    if (language === "e") {
        dataStructure = getDataStructure(document);
    }

    obOut[language] = {};
    let variable = document
        .getElementsByTagName("location")[0]
        .getElementsByTagName("name")[0].innerHTML;
    obOut[language]["location"] = variable;
    variable = document
        .getElementsByTagName("location")[0]
        .getElementsByTagName("region")[0].innerHTML;
    obOut[language]["region"] = variable;
    variable = document
        .getElementsByTagName("location")[0]
        .getElementsByTagName("name")[0].innerHTML;
    obOut[language]["location"] = variable;
    // obOut[language]["forecasts"] = { today: [], tomorrow: [], longTerm: [] };

    let forecasts = document.getElementsByTagName("forecast");
    let forecastsArray = []
    for (const item in dataStructure) {
        forecastsArray.push(dataStructure[item].map(getData))
    }

    obOut[language]["forecasts"] = forecastsArray
    function getData(index) {
        return {
            period: forecasts[index].getElementsByTagName("period")[0]
                .innerHTML,
            "text-summary":
                forecasts[index].getElementsByTagName("textSummary")[0]
                    .innerHTML,
            "icon-code": parseInt(
                forecasts[index].getElementsByTagName("iconCode")[0].innerHTML
            ),
            "temperature":
                forecasts[index].getElementsByTagName("temperatures")[0].getElementsByTagName("temperature")[0]
                    .innerHTML,
        };
    }
    // Add warnings if there
    let warnings = document.getElementsByTagName("warnings");
    if (warnings[0].innerHTML === "") {
        obOut[language]["warnings"] = null
    }
    else {
        let warningEvents = Array.from(warnings[0].getElementsByTagName("event"))
        let warningDict = { url: warnings[0].getAttribute("url"), events: [] }
        for (let event of warningEvents) {
            if (event != undefined) {
                warningDict.events.push({
                    description: event.getAttribute("description"),
                    type: event.getAttribute("type")
                })
            }
        }
        obOut[language]["warnings"] = warningDict
    }




}
async function getStationData(stationId, stationProvince) {
    //console.log(stationId)
    //console.log(stationProvince)
    let out = {};

    await fetchData(out, stationId, stationProvince, "e");
    await fetchData(out, stationId, stationProvince, "f");

    return out;
}
async function getPrevisionStationList() {
    let stationUrl = `${process.env.VUE_APP_METEO_PREVISIONS_URL}docs/site_list_fr.csv`;
    let stationReg = /(s\d{7})/;
    // let stationArr = [];
    let response = await fetch(stationUrl);
    let v = await response.text();
    let splitted_response = v.split(/\r?\n/);
    // Quadtree qui a un rande de latitude entre 0 et 90, et un range de longitude de -180 à 0
    const quadtree = new QuadTree(new Box(-180, 0, 180, 90));
    for (let line of splitted_response) {
        let line_array = line.split(",");
        if (stationReg.test(line_array[0])) {
            let lat = parseFloat(line_array[3].slice(0, -1));
            let lon = parseFloat(`-${line_array[4].slice(0, -1)}`);
            quadtree.insert({
                id: line_array[0],
                name: line_array[1],
                province: line_array[2],
                source: Source.dd_atmos_prevision,
                category: Category.AtmosPrevision,
                y: lat,
                latitude: lat,
                x: lon,
                longitude: lon,
            });
        }
    }
    return quadtree;
}

function getDataStructure(document) {
    let forecasts = document.getElementsByTagName("forecast");
    let allDays = [];
    for (let forecast of forecasts) {
        allDays.push(
            forecast.getElementsByTagName("period")[0].innerHTML.split(" ")[0]
        );
    }
    let uniqueDays = allDays.filter(onlyUnique);
    // let todayIndices = [];
    // let tomorrowIndices = [];
    // let longtermIndices = [];
    let orderedDays = {}
    for (let i = 0; i < allDays.length; i++) {
        let indexDay = uniqueDays.indexOf(allDays[i])
        if (!(indexDay in orderedDays)) {
            orderedDays[indexDay] = [i]
        }
        else {
            orderedDays[indexDay].push(i)
        }

    }

    // for (let i = 0; i < allDays.length; i++) {
    //     if (allDays[i] === uniqueDays[0]) {
    //         todayIndices.push(i);
    //     } else if (allDays[i] === uniqueDays[1]) {
    //         tomorrowIndices.push(i);
    //     } else {
    //         longtermIndices.push(i);
    //     }
    // }
    return orderedDays
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export { getPrevisionStationList, getStationData };
