import store from "@/store";

const goToMyLocation = function() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) =>
      store.mutations.updateSelectedCoordinates(
        position.coords.latitude,
        position.coords.longitude
      )
    );
  }
};

export { goToMyLocation };
