<template>
    <div>
        <b-modal :id="id" :ref="modalRef" :size="size" :no-close-on-backdrop="noCloseOnBackdrop">
            <div class="modal-whole">

                <div class="modal-text">
                    <div class="modal-custom-header">
                        <img class="logo-ogsl-modal" src="@/assets/logo_ogsl.svg" />
                        <img @click="switchLocale" class="point-general-info" :src="require('@/assets/icon_language_' +
                            ($i18n.locale === 'fr' ? 'en' : 'fr') +
                            '.svg')
                            " />
                    </div>

                    <div class="modal-core-section">
                        <slot name="core-icon"></slot>
                        <div class="modal-core-section-text">
                            <slot name="core-text"></slot>
                        </div>
                    </div>
                    <div class="modal-buttons">
                        <slot name="modal-buttons"></slot>
                    </div>
                </div>
                <div class="main-image-container">
                    <slot name="main-image"></slot>
                </div>


            </div>

        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        noCloseOnBackdrop: Boolean,
        size: { type: String, default: "xl" },
        id: String,
        modalRef: String
    },
    computed: {
        contextUrl() {
            return (
                process.env.VUE_APP_OGSL_URL +
                this.$t("external-context-end-url")
            );
        },

    },
    methods: {
        switchLocale() {
            if (this.$i18n.locale === "fr") {
                this.$i18n.locale = "en";
            } else {
                this.$i18n.locale = "fr";
            }
        },
        getChildRef() {
            return this.$refs[this.modalRef];
        }

    },
};
//revoir: utiliser style scoped pour le css
</script>

<style>
.modal-whole {
    display: flex
}

.main-image-container {
    margin: 1px;
}

.logo-ogsl {
    height: 35px;
    pointer-events: auto;
    cursor: pointer;
}

.modal-core-section {
    display: flex;
    align-items: flex-start;
}

.modal-core-section-text {
    margin-left: 10px;
}

.modal-text {
    padding: 10px
}

.logo-ogsl-modal {
    height: 35px;
    padding: 5px;
}


.modal-custom-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 30px;
}

.modal-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px
}

@media (max-width: 1200px) {
    .main-image-container {
        display: none;
    }

    .modal-whole {
        display: block;
    }
}
</style>