<template>
  <div class="side-pane">
    <TheNoDataPane></TheNoDataPane>
    <LayerSelector
      text="layer-title-currents"
      class="side-pain-element"
      :layerCode="Layer.CURRENTS"
      :layerCategory="layerCategories.CURRENTS"
      :imgSrc="
        layerCategories.CURRENTS.includes($store.state.selectedLayer)
          ? require(`@/assets/icon_courant.svg`)
          : require(`@/assets/icon_deactivated_courant.svg`)
      "
      @legendClicked="showHideLegend"
      :legendToShow="legendToShow"
    >
      </LayerSelector>
    <LayerSelector
      text="layer-title-winds"
      :layerCode="Layer.WINDS"
      :layerCategory="layerCategories.WINDS"
      :imgSrc="
        layerCategories.WINDS.includes($store.state.selectedLayer)
          ? require(`@/assets/icon_vent.svg`)
          : require(`@/assets/icon_deactivated_vent.svg`)
      "
      @legendClicked="showHideLegend"
      :legendToShow="legendToShow"
    >
      <SubLayerSelector
        class="sublayor-selector"
        v-if="selectedLayer == Layer.WINDS || selectedLayer == Layer.GUSTS"
        text="layer-title-gusts-short"
        :layerCode="Layer.GUSTS"
        :parentlayerCode="Layer.WINDS"
        :layerCategory="layerCategories.WINDS"
        @legendClicked="showHideLegend"
      />
      <SubExtraLayerSelector
        class="sublayor-selector"
        v-show="selectedLayer == Layer.WINDS || selectedLayer == Layer.GUSTS"
        text="layer-title-isobars-short"
        :layerCode="Layer.ISOBARS"
      />
    </LayerSelector>

    <StationSelector></StationSelector>
  </div>
</template>

<script>
import { Layer } from "@/constants";
import LayerSelector from "@/components/map/rightSidePane/TheSidePaneLayerSelector.vue";
import SubLayerSelector from "@/components/map/rightSidePane/SubLayerSelector.vue";
import SubExtraLayerSelector from "@/components/map/rightSidePane/SubExtraLayerSelector.vue";
import TheNoDataPane from "@/components/map/rightSidePane//TheNoDataPane.vue";
import StationSelector from "@/components/map/rightSidePane/TheSidePaneStationSelector.vue";

export default {
  components: {
    LayerSelector,
    TheNoDataPane,
    SubLayerSelector,
    SubExtraLayerSelector,
    StationSelector,
  },
  data() {
    return {
      selectedLayer: "",
      legendToShow: Layer.CURRENTS,
      layerCategories: {
        WINDS: ["winds", "gusts"],
        CURRENTS: ["currents"],
        STATIONS: ["stations"],
      },
    };
  },
  methods: {
    showHideLegend(value) {
      if (this.legendToShow === value) {
        this.legendToShow = "";
      } else {
        this.legendToShow = value;
      }
    },
  },
  created() {
    this.Layer = Layer;
  },
  watch: {
    "$store.state.selectedLayer": function (value) {
      this.selectedLayer = value;
    },
  },
  mounted() {
    // $( ".side-pane" ).mouseenter(function() {
    //     $( ".side-pane" ).css("pointer-events", "auto")
    // });
    // $( ".side-pane" ).mouseleave(function() {
    //     $( ".side-pane" ).css("pointer-events", "none")
    // });
    // $("body::-webkit-scrollbar").mouseover(function() {
    //     console.log("test")
    // });
  },
};
</script>

<style>
.side-pane {
  position: absolute;
  top: 80px;
  right: 0;
  width: 160px;
  margin-right: 20px;
  overflow: hidden;
  text-align: right;
  pointer-events: none;
  /* max-height: 200px; */
}

/* .side-pain-element{
    width: 140px;
} */
@media (max-width: 800px) {
  .side-pane {
    top: 100px;
  }
}

@media (max-width: 550px) {
  .side-pane {
    top: 85px;
    width: 130px;
    margin-right: 2px;
  }
}

.layer-icon {
  width: 50px;
  padding: 5px;
  pointer-events: auto;
  cursor: pointer;
}

.layer-icon:hover {
  padding: 2px;
}

.layer-text-legend-icon {
  /* width: 0px; */
  color: white;
  display: none;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 5px 3px 5px 3px;
  border-radius: 4px;
  pointer-events: auto;
}

.legend-icon {
  width: 15px;
  cursor: pointer;
}

.legend-icon:hover {
  width: 20px;
}

.layer-text {
  pointer-events: none;
  background-color: rgba(45, 158, 170, 0.6);
  padding: 2px;
  border-radius: 4px;
}

.selected > .layer-icon {
  padding: 2px;
}

.selected > .layer-text-legend-icon {
  display: inline;
}

@media (max-width: 550px) {
  .layer-icon {
    padding: 2px;
    width: 30px;
  }

  .layer-text-legend-icon {
    font-size: small;
  }
}

.hovered {
  display: inline;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #131718 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
  pointer-events: auto !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #5494a0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.custom-control-label {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 0 2px 0 2px;
}

@media (max-width: 550px) {
  .sublayor-selector {
    height: 20px;
  }
  .custom-control-label {
    font-size: x-small !important;
  }

  .custom-switch.b-custom-control-sm .custom-control-label::before,
  .input-group-sm .custom-switch .custom-control-label::before {
    top: 0.11875rem !important;
    left: -1.46875rem !important;
    width: 1.13125rem !important;
    height: 0.675rem !important;
    border-radius: 0.4375rem !important;
  }

  .custom-switch.b-custom-control-sm .custom-control-label::after,
  .input-group-sm .custom-switch .custom-control-label::after {
    top: calc(0.11875rem) !important;
    left: calc(-1.46875rem) !important;
    width: calc(0.875rem - 3px) !important;
    height: calc(0.875rem - 3px) !important;
    border-radius: 0.4375rem;
    background-size: 50% 50%;
  }
}
</style>
