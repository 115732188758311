import { fromLonLat } from "ol/proj";

function GPXsaver(points, times) {
    const xmlStart = `<?xml version="1.0"?>
    <gpx
    version="1.1"
    creator="OGSL - Navigation app"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns="http://www.topografix.com/GPX/1/1"
    xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" >
    <trk>
    <trkseg>`;

    let xmlOutput = xmlStart;

    for (let i = 0; i < points.length; i++) {
        let point = points[i];
        let time = times[i].toISOString();
        xmlOutput += `<trkpt lat="${point[1]}" lon="${point[0]}">
        <time>${time}</time>`;
        xmlOutput += `</trkpt>
        `;
    }

    // And add the end
    xmlOutput += `</trkseg>
    </trk>
    </gpx>`;

    let element = document.createElement("a");
    element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(xmlOutput)
    );
    element.setAttribute("download", "itinerary.gpx");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}
function GPXloader(file) {
    let points = []
    let reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
        let text = (evt.target.result);
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(text, "text/xml");
        for (let point of xmlDoc.getElementsByTagName("trkpt")){
            points.push(fromLonLat([point.getAttribute("lon"),point.getAttribute("lat")]))
        }
        
    };
    return points
    
}
export { GPXsaver, GPXloader };
