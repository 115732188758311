const variables = [
  "present_weather",
  "relative_humidity",
  "horizontal_visibility",
  "air_temperature",
  "wind_speed",
  "wind_direction",
  "wind_gust_speed",
];
const provinces_to_fetch = ["NB", "NL", "QC", "NS", "PE"];

async function getLatestMeteoFileUrl(timeMaxMillisecond, province) {
  let out = "";

  const url = `${process.env.VUE_APP_METEO_STATIONS_URL}${province}/hourly/`;
  const response = await fetch(url);
  const text = await response.text();
  const document = new window.DOMParser().parseFromString(text, "text/html");

  const hrefElements = document.getElementsByTagName("a");

  const dateStrings = [];
  const currentDate = new Date(timeMaxMillisecond);
  dateStrings.push(
    currentDate.getUTCFullYear() +
      ("0" + (currentDate.getUTCMonth() + 1)).slice(-2) +
      ("0" + currentDate.getUTCDate()).slice(-2) +
      ("0" + currentDate.getUTCHours()).slice(-2)
  );

  const hourAgoDate = new Date(currentDate.getTime() - 1000 * 60 * 60);
  dateStrings.push(
    hourAgoDate.getUTCFullYear() +
      ("0" + (hourAgoDate.getUTCMonth() + 1)).slice(-2) +
      ("0" + hourAgoDate.getUTCDate()).slice(-2) +
      ("0" + hourAgoDate.getUTCHours()).slice(-2)
  );

  for (const element of hrefElements) {
    for (const dateString of dateStrings) {
      if (element.href.includes(dateString)) {
        out =
          process.env.VUE_APP_METEO_STATIONS_URL +
          `${province}/hourly/hourly_${province.toLowerCase()}_${dateString}_f.xml`;
        // debugger
        return new Promise((resolve) => resolve(out));
      }
    }
  }
  return new Promise((_, reject) => reject("Not found"));
}

async function getLatestObservations(timeMaxMillisecond) {
  const out = [];
  for (let j = 0; j < provinces_to_fetch.length; j++) {
    // Si l'heure est changé, on doit aller chercher à nouveau les données avec cette méthode.
    const url = await getLatestMeteoFileUrl(
      timeMaxMillisecond,
      provinces_to_fetch[j]
    );
    const response = await fetch(url);
    const text = await response.text();
    const document = await new window.DOMParser().parseFromString(
      text,
      "text/xml"
    );

    //revoir: utiliser des nom de variables explicites ==> observations
    const obs = document.getElementsByTagName("om:Observation");

    //revoir: utiliser des boucle for(const observation of observations) ==> pas de i ni j qd c est pas nécessaire
    for (let i = 0; i < obs.length; i++) {
      //revoir: scope devrait etre const
      let obsData = {};

      //fetch les métadonnées
      //revoir: Nom de variable, scope devrait etre const
      var idElements = obs[i].getElementsByTagName("identification-elements")[0]
        .childNodes;

      for (let j = 0; j < idElements.length; j++) {
        if (idElements[j].attributes.name.nodeValue === "transport_canada_id") {
          obsData["id"] = idElements[j].attributes.value.nodeValue;
        }
        if (idElements[j].attributes.name.nodeValue === "station_name") {
          obsData["officialName"] = idElements[j].attributes.value.nodeValue;
        }
        if (idElements[j].attributes.name.nodeValue === "latitude") {
          obsData["latitude"] = idElements[j].attributes.value.nodeValue;
        }
        if (idElements[j].attributes.name.nodeValue === "longitude") {
          obsData["longitude"] = idElements[j].attributes.value.nodeValue;
        }
        if (
          idElements[j].attributes.name.nodeValue === "observation_date_utc"
        ) {
          obsData["observationDateUtc"] =
            idElements[j].attributes.value.nodeValue;
        }
      }
      if ("id" in obsData) {
        obsData.data = {};
        // fetch les données
        var results =
          obs[i].getElementsByTagName("om:result")[0].childNodes[0].childNodes;

        for (let j = 0; j < results.length; j++) {
          if (variables.includes(results[j].attributes.name.nodeValue)) {
            obsData.data[results[j].attributes.name.nodeValue] =
              results[j].attributes.value.nodeValue;
          }
        }
        out.push(obsData);
      }
    }
  }

  return out;
}

export { getLatestObservations };
