import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
  props: {
    universalDepartureDate: Date,
    universalUnitsChosen: String,
    unitChoices: Array,
  },
  data() {
    return {
      popover: {
        placement: "top",
        positionFixed: true,
        visibility: "focus",
      },
    };
  },
  computed: {
    distanceUnitsShown() {
      let distanceUnits = null;
      if (this.unitsChosen === "knots") {
        distanceUnits = this.$t("nauticalm");
      } else if (this.unitsChosen === "km/h") {
        distanceUnits = "km";
      }
      return distanceUnits
    },
    fields() {
      return [
        {
          key: "departureDate",
          class: "departure-time-template",
          label: this.$t("departure-date-time"),
        },
        {
          key: "speed",
          label: this.$t("boat-speed"),
        },
        {
          key: "distance",
          label: `Distance (${this.distanceUnitsShown})`,
        },
        {
          key: "arrivalDateFormatted",
          label: this.$t("arrivalTime"),
        },
        {
          key: "time",
          label: this.$t("Time"),
        },
      ];
    },
    formattedDepartureDate() {
      return formatDateToDisplay(this.departureDate, days[this.$i18n.locale]);
    },
  },

  methods: {
    calculateDuration: function(length, speed) {
      if (length > 0) {
        return (length / speed) * 60;
      } else {
        return 0;
      }
    },
    formatDuration: function(durationInMinutes) {
      if (durationInMinutes > 0) {
        let rawHours = durationInMinutes / 60;
        let hours = Math.floor(rawHours);
        let decimalMinutes = rawHours - hours;
        let minutes = Math.round(60 * decimalMinutes);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        return `${hours}h${minutes}m`;
      } else {
        return "0h";
      }
    },
    kmToNm: function(value) {
      return Math.round((value / 1.852) * 10) / 10;
    },
    nmToKm: function(value) {
      return Math.round(value * 1.852);
    },
  },
};
