<template>
    <div>
        <div v-if="selectedStation === null || !stationCategories.includes($store.state.selectedStation.category)">
            <div v-for="group in allStations" :key="group.title">
                <h5 class="station-list-titles">{{ group.title }}</h5>
                <div class="station-list-container">
                    <button class="btn-menu" v-for="station in group.stations" :key="station.id"
                        @click="displaySelectedStation(station)" :class="{
                            'disable-list-click':
                                station.category === categories.Atmospheric &&
                                hourDifference > 3,
                        }">
                        {{ station.name }}
                        <i v-if="station.category === categories.Atmospheric">({{ Math.round(station.distanceFromClick) }}
                            Km)</i>
                    </button>
                </div>

                <p class="no-station-data" v-if="$store.state.selectedCoordinates === null">
                    {{ $t("coordinates-click-instructions") }}
                </p>
                <p class="no-station-data" v-else-if="group.stations.length === 0">
                    {{ $t("no-station-nearby") }}
                </p>
            </div>
        </div>
        <div v-if="selectedStation !== null && stationCategories.includes($store.state.selectedStation.category)">
            <button type="button" class="btn btn-link button-listStation" @click="$store.mutations.unselectStation()">
                {{ $t("stationdetail-button-list-label") }}
            </button>
            <h5 style="text-align: center">
                {{ selectedStation.name }}
            </h5>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { getHoursDifference } from "@/common/timeRelatedFunctions";
export default {
    data() {
        return {};
    },
    props: ["allStations", "selectedStation", "categories", "stationCategories"],
    computed: {
        hourDifference() {
            return getHoursDifference(this.$store.state.selectedTime);
        },
    },

    methods: {
        displaySelectedStation: function (station) {
            this.$store.mutations.updateSelectedStation(
                station.id,
                station.latitude,
                station.longitude,
                station.category,
                station.name
            );
        },
    },
};
</script>

<style scoped>
.disable-list-click {
    pointer-events: none;
    background-color: #bec0c2;
}

.button-listStation {
    background-color: #00adef;
    border: 2px solid #00adef;
    color: white;
    padding: 5px 5px;
    text-align: center;
    font-size: 14px;
    transition-duration: 0.4s;
    cursor: pointer;
    box-shadow: 0 0 10px rgb(0 0 0 / 35%);
    border-radius: 8px;
    margin: 3px;
    font-size: small;
}

.station-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.no-station-data {
    background-color: #bec0c2;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px;
    display: block;
    width: 300px;
}

@media (max-width: 300px) {
    .no-station-data {
        width: 100%;
    }
}
.station-list-titles{
    padding:0 4px 0 4px;
}
</style>