<template>
  <div class="time-button-slider-container">
    <div class="time-control-buttons">
      <img
        class="plus-minus-icon"
        src="@/assets/ios-arrow-back.svg"
        @click="changeHour(-1)"
      />
      <img
        class="plus-minus-icon"
        src="@/assets/ios-arrow-forward.svg"
        @click="changeHour(1)"
      />
    </div>
    <div class="slider">
      <vue-slider
        v-model="sliderValue"
        :min="sliderMin"
        :max="sliderMax"
        :marks="marks"
        ><template v-slot:tooltip="{ value }">
          <div class="custom-tooltip">
            {{ showDateInSlider(value) }}
          </div>
        </template>
        <div class="time-slider-days" v-for="day of daysToSelect" :key="day.id">
          <div
            class="displayed-day-time-slider"
            :style="{ left: day.id * 16.666 + 6 + '%' }"
          >
            {{ day.day }}
          </div>
        </div>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import {
  formatDateToDisplayNoYear,
  formatDateToDisplayOnlyDay,
} from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
  data() {
    let today = new Date();
    let minDate = new Date();
    minDate.setDate(today.getDate() - 1);
    minDate.setHours(0);
    minDate.setMinutes(0);
    minDate.setSeconds(0);
    let maxDate = new Date();
    maxDate.setDate(today.getDate() + 4);
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    this.$store.mutations.updateSelectedTime(today);
    return {
      date: this.$store.state.selectedTime,
      minDate: minDate,
      maxDate: maxDate,
      sliderMax: 144,
      sliderMin: 0,
      sliderValue: this.dateToSliderValue(today, minDate),
      marks: [24, 48, 72, 96, 120],
    };
  },
  computed: {
    daysToSelect() {
      let daysToDisplay = [];
      let day = new Date(this.minDate);
      let id = 0;
      while (day.getTime() <= this.maxDate.getTime()) {
        daysToDisplay.push({
          id: id,
          day: formatDateToDisplayOnlyDay(day, days[this.$i18n.locale]),
        });
        day.setDate(day.getDate() + 1);
        id = id + 1;
      }
      return daysToDisplay;
    },
  },
  methods: {
    changeHour(value) {
      let newDate = new Date();
      newDate.setTime(
        this.$store.state.selectedTime.getTime() + value * 60 * 60 * 1000,
        0,
        0,
        0
      );
      if (
        newDate.getTime() < this.minDate.getTime() ||
        newDate.getTime() > this.maxDate.getTime()
      )
        return;
      this.$store.mutations.updateSelectedTime(newDate);
    },
    showDateInSlider(value) {
      return formatDateToDisplayNoYear(
        this.getDateToShow(value),
        days[this.$i18n.locale]
      );
    },
    getDateToShow(value) {
      let date = new Date(this.minDate.getTime() + value * 3600000);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },
    sliderValueToDate(sliderValue) {
      return new Date(this.minDate.getTime() + sliderValue * 60 * 60 * 1000);
    },
    dateToSliderValue(date, minDate) {
      return Math.round(Math.abs(date - minDate) / (60 * 60 * 1000));
    },
  },
  watch: {
    sliderValue: function (newValue) {
      let date = this.sliderValueToDate(newValue);
      if (date.getTime() != this.$store.state.selectedTime.getTime()) {
        this.$store.mutations.updateSelectedTime(date);
      }
    },
    "$store.state.selectedTime": function (value) {
      this.sliderValue = this.dateToSliderValue(value, this.minDate);
    },
  },
};
</script>

<style>
.time-button-slider-container .slider {
  position: relative;
  flex-grow: 1;
}
.time-button-slider-container .vue-slider {
  padding: 0px !important;
}
.time-button-slider-container .vue-slider-rail {
  background-color: #e0e0e0 !important;
  border-radius: 0px !important;
  height: 20px !important;
  cursor: pointer !important;
  margin-top: 3px;
  margin-left: 2px;
  pointer-events: auto;
  border-radius: 0px !important;
}
.time-button-slider-container .vue-slider-process {
  border-radius: 0px 0px 0px 0px !important;
  background-color: #9cd5ff !important;
}
.time-button-slider-container .vue-slider-dot-handle {
  display: none;
}
.time-button-slider-container .vue-slider-dot-handle {
  cursor: grab !important;
}
.time-button-slider-container .vue-slider-mark-label {
  display: none !important;
}
.time-button-slider-container .vue-slider-dot-tooltip-top {
  top: 50px !important;
  background-color: whitesmoke;
  border-radius: 6%;
  font-weight: bold;
  cursor: grab;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto;
}
.time-button-slider-container .custom-tooltip {
  width: 47px;
  line-height: 95%;
  pointer-events: auto;
  padding-top: 3px;
}
.time-button-slider-container .vue-slider-mark-step {
  border-radius: 0px;
  box-shadow: none;
}
.displayed-day-time-slider {
  position: absolute;
  top: 0px;
  z-index: 2;
  pointer-events: none;
  font-size: small;
  font-weight: bold;
}
.time-button-slider-container {
  display: flex;
  margin: 0px 10px 0px 10px;
  flex-grow: 1;
}
/* .time-control-buttons {
  margin-right: 4px;
} */
.time-control-buttons .plus-minus-icon {
  margin: 0px !important;
  height:20px;
  border-radius: 0px;
  pointer-events: auto;
}

@media screen and (max-width: 550px) {
  .time-button-slider-container {
    height:65px;
    width: 100%;
  }

  .time-button-slider-container .slider::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .displayed-day-time-slider {
    width:30px;
  }

  .time-button-slider-container .slider {
    overflow-x: scroll;
    pointer-events:auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  .time-button-slider-container .vue-slider-rail {
    height: 40px!important;
  } 
  .time-button-slider-container {
    margin: 0; 
    width: 100%;
  }
  .time-button-slider-container .vue-slider-rail {
    border-radius: 0px !important;
    margin-top: 0px;
    margin-left: 0px;
  }

  .time-button-slider-container .vue-slider-process {
    border-radius: 0px !important;
  }
  .time-control-buttons {
    display: flex;
  }

  .time-control-buttons .plus-minus-icon {
    width: 40px !important;
    height: 40px !important;
    border-radius: 0% !important;
    margin: 0px !important;
    margin-left: 3px !important;
  }
  .time-button-slider-container .vue-slider {
      min-width:600px;
  }
}
</style>