import { days } from "@/common/time-utils";

export default {
  computed: {
    fields: function () {
      let tz = this.tidesTable != undefined ? this.tidesTable[0].datetime.toLocaleTimeString('en-CA', { "timeZoneName": 'short' }).split(' ')[2] : ""
      return [
        { key: "Heure", label: `${this.$t("hour")} ${tz}`},
        { key: "heightM", label: this.$t("height") + " (m)" },
        {
          key: "heightF",
          label: this.$t("height") + " (" + this.$t("feet") + ")",
        },
        { key: "icon", label: "", class: "tideIcon" },
      ];
    },
    tidesTable: function () {
      if (this.tideData === null) return []
      return this.buildTideTable(this.tideData);
    },
  },
  methods: {
    isToday: function (date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    splitDateTime: function (string) {
      let DateTimeSplit = string.eventDate.split("T");
      let DateSplit = DateTimeSplit[0].split("-");
      let TimeSplit = DateTimeSplit[1].slice(0, -1).split(":");
      return DateSplit + TimeSplit;
    },
    formatDate: function (date) {

      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    },
    getTableInfo: function (obs, index, date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let timeString = hours + ":" + minutes;
      let tableInfo = {};
      tableInfo["Heure"] = timeString;
      tableInfo["heightM"] = Math.round(obs[index].value * 10) / 10;
      tableInfo["heightF"] = Math.round(obs[index].value * 3.28084 * 10) / 10;
      if (index < obs.length - 1) {
        if (obs[index].value > obs[index + 1].value) {
          tableInfo["icon"] = require("@/assets/high_tide.png");
        } else {
          tableInfo["icon"] = require("@/assets/low_tide.png");
        }
      } else {
        if (obs[index].value > obs[index - 1].value) {
          tableInfo["icon"] = require("@/assets/high_tide.png");
        } else {
          tableInfo["icon"] = require("@/assets/low_tide.png");
        }
      }
      return tableInfo;
    },
    buildTideTable: function (tideData) {
      let _this = this;
      let tidesTable = [];

      this.todaysTide = [];
      tideData.sort(function (a, b) {
        var aSplit = _this.splitDateTime(a);
        var bSplit = _this.splitDateTime(b);
        return aSplit > bSplit ? 1 : aSplit < bSplit ? -1 : 0;
      });
      for (let i = 0; i < tideData.length; i++) {
        var date = new Date(tideData[i]["eventDate"]);
        var formattedDate = _this.formatDate(date);
        var obsTableInfo = _this.getTableInfo(tideData, i, date);
        var inDict = false;
        for (let j = 0; j < tidesTable.length; j++) {
          if (tidesTable[j].date === formattedDate) {
            tidesTable[j].items.push(obsTableInfo);
            inDict = true;
            break;
          }
        }
        if (!inDict) {
          tidesTable.push({
            datetime: date,
            date: formattedDate,
            items: [obsTableInfo],
          });
        }
      }
      return tidesTable;
    },
  },

  watch: {},
};
