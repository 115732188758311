<template>
  <div>
    <div class="slider-container" v-if="itineraryFirstPointOk">
      <div class="itinerary-date-slider">
        <div>{{ $t("Start") }}</div>
        {{ formattedDepartureDate }}
        <div></div>
      </div>
      <div class="slider">
        <div class="fake-slider-rail"></div>
        <vue-slider
          v-model="sliderValue"
          :min="sliderMin"
          :max="sliderMax"
          :marks="true"
          @drag-start="updateSliderValue"
          @change="updateSliderValue"
          ><template v-slot:tooltip="{ value }">
            <div class="custom-tooltip">
              {{ showDateInSlider(value) }}
            </div>
          </template>
        </vue-slider>
        <div v-for="point in segmentPointInfo" :key="point.id">
          <div class="segment-points" :style="{ left: point.percentage + '%' }"></div>
          <div class="segment-id-parent" :style="{ left: point.percentage + '%' }">
            <div class="segment-id-child">{{ point.id }}</div>
          </div>
        </div>
      </div>

      <div class="itinerary-date-slider">
        <div>{{ $t("Arrival") }}</div>
        {{ formattedArrivalDate }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDateToDisplay,
  formatDateToDisplayNoYear,
} from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
  props: {
    formattedDepartureDate: String,
    departureDate: Date,
    arrivalDate: null,
    universalSliderTime: Date,
    extendedTable: Array,
    sliderType: String,
    regularDurationMinutes: Number,
  },
  data() {
    return {
      sliderValue: 0,
      sliderMax: 1,
      sliderMin: 0,
      sliderValueUpdate: true,
      percentage: 80,
    };
  },
  computed: {
    segmentPointInfo() {
      if (this.sliderType === "extended") {
        if (this.extendedTable != null) {
          if (this.extendedTable.length > 0) {
            if (this.extendedTable[0].arrivalDate != undefined) {
              return this.getPointsPositions(
                this.extendedTable[0].departureDate,
                this.extendedTable[this.extendedTable.length - 1].arrivalDate,
                this.extendedTable[this.extendedTable.length - 1]
                  .durationMinutesFromStart,
                this.extendedTable
              );
            }
          }
        }
      } else {
        if (this.arrivalDate != null) {
          let speedInMetersPerMin =
            this.$store.state.itineraryTotalLength / this.regularDurationMinutes;
          let segmentsDuration = [];
          for (let segmentLength of this.$store.state.detailedItineraryLength) {
            segmentsDuration.push({
              durationInMinutes: segmentLength / speedInMetersPerMin,
            });
          }

          return this.getPointsPositions(
            this.departureDate,
            this.arrivalDate,
            this.regularDurationMinutes,
            segmentsDuration
          );
        }
      }
      return [];
    },
    roundedMinuteValue() {
      if (this.departureDate.getMinutes() != 0) return false;
      return true;
    },
    totalDurationInMinutes() {
      if (this.arrivalDate === undefined || this.arrivalDate === null) {
        return 0;
      }
      if (this.departureDate === undefined) {
        return 0;
      }
      let difInMilli = this.arrivalDate - this.departureDate;
      return Math.floor(difInMilli / 1000 / 60);
    },
    itineraryFirstPointOk() {
      if (this.totalDurationInMinutes === 0) return false;
      return true;
    },
    formattedArrivalDate() {
      if (this.arrivalDate === null) {
        return null;
      }
      return formatDateToDisplay(this.arrivalDate, days[this.$i18n.locale]);
    },
  },
  methods: {
    getPointsPositions(departureDate, arrivalDate, totalDuration, segmentsDuration) {
      let points = [];
      let substractFromStart;
      if (departureDate.getMinutes() === 0) {
        substractFromStart = 0;
      } else {
        substractFromStart = 60 - departureDate.getMinutes();
      }
      let adjustedCumulation = segmentsDuration[0].durationInMinutes - substractFromStart;

      let substractFromEnd = arrivalDate.getMinutes();
      let totalAdjusted = totalDuration - substractFromStart - substractFromEnd;

      for (let i = 1; i < segmentsDuration.length; i++) {
        let percentage = Math.round((adjustedCumulation / totalAdjusted) * 100);
        adjustedCumulation = adjustedCumulation + segmentsDuration[i].durationInMinutes;

        let newPoint = { id: i, percentage: percentage };
        points.push(newPoint);
      }
      return points;
    },
    updateSliderValue: function () {
      this.sliderValueUpdate = true;
    },
    showDateInSlider(value) {
      return formatDateToDisplayNoYear(
        this.getDateToShow(value),
        days[this.$i18n.locale]
      );
    },
    getDateToShow(value) {
      let hoursDisplayed;
      if (this.roundedMinuteValue) {
        hoursDisplayed = value;
      } else {
        hoursDisplayed = value + 1;
      }
      let date = new Date(this.departureDate.getTime() + hoursDisplayed * 3600000);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },
    modifySliderMax: function (itineraryMinutes) {
      if (this.departureDate.getMinutes() === 0) {
        this.sliderMax = Math.floor(itineraryMinutes / 60);
        return;
      }
      if (this.departureDate.getMinutes() < this.arrivalDate.getMinutes()) {
        this.sliderMax = Math.floor(itineraryMinutes / 60) - 1;
      } else {
        this.sliderMax = Math.floor(itineraryMinutes / 60);
      }
    },
    getNumberOfDaysBetween: function (date1, date2) {
      return Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600));
    },
  },
  watch: {
    totalDurationInMinutes: function (value) {
      if (value != 0) {
        this.modifySliderMax(value);
      }
    },
    sliderValue: function (value) {
      if (this.sliderValueUpdate) {
        let date = this.getDateToShow(value);
        this.$emit("sliderValueChange", [value, date]);
      }
    },
    universalSliderTime: function (value) {
      if (
        Math.abs(value.getTime() - this.getDateToShow(this.sliderValue).getTime()) > 1000
      ) {
        let nbOfDays = this.getNumberOfDaysBetween(this.getDateToShow(0), value);
        if (nbOfDays > this.sliderMax) {
          this.sliderValueUpdate = false;
          this.sliderValue = this.sliderMax;
        } else {
          this.sliderValueUpdate = true;
          this.sliderValue = nbOfDays;
        }
      }
    },
  },
};
</script>

<style>
.slider-container .slider {
  position: relative;
  height: 70px;
  flex-grow: 1;
  width: 90%;
  margin: auto;
}
.above-slider {
  width: 100%;
  padding: 0px 5px 0px 5px;
  background-color: #90d0e8;
  position: relative;
  height: 50px;
}
.custom-tooltip {
  font-size: 12px;
  width: 70px;
}
@media screen and (max-width: 733px) {
  .custom-tooltip {
    font-size: 10px;
    width: 50px;
  }
}
.slider-container .vue-slider {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  padding: 0 !important;
  top: 0 !important;
}
.slider-container .vue-slider-mark-label {
  display: none !important;
}
.slider-container .vue-slider-mark-step {
  box-shadow: 0 0 0 2px #8d999e !important;
  height: 4px !important;
}
.slider-container .vue-slider-mark {
  z-index: 2 !important;
}
/* .vue-slider-marks{
  outline: 1px solid #fff !important;
} */
.vue-slider-rail {
  /* border: 1px solid #fff !important; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
  margin-left:5px !important;
}
.slider-container .vue-slider-rail {
  background-color: #e0e0e0 !important;
  border-radius: 0px !important;
  height: 40px !important;
  cursor: pointer;
}
.slider-container .vue-slider-process {
  border-radius: 0px !important;
  background-color: #9cd5ff !important;
}
.slider-container .vue-slider-dot {
  top: 0px !important;
  cursor: grab !important;
}
.slider-container .vue-slider-dot-handle {
  cursor: grab !important;
}
.slider-container .vue-slider-dot-tooltip-top {
  top: 40px;
  background-color: whitesmoke;
  border-radius: 6%;
  padding: 2px;
  font-weight: bold;
  cursor: grab;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  visibility: visible !important;
  opacity: 1 !important;
}
@media screen and (max-width: 733px) {
  .slider-container .vue-slider-dot-tooltip-top {
    top: 50px;
  }
}
.slider-container {
  text-align: center;
  align-items: center;
  display: flex;
}

.itinerary-date-slider {
  font-size: small;
  white-space: nowrap;
  padding: 0px 5px 0px 5px;
}
@media screen and (max-width: 733px) {
  .slider-container {
    display: block !important;
  }
}

.segment-points {
  position: absolute;
  top: 0;
  border-left: 2px solid #f7931d;
  height: 60px;
  z-index: 2;
}
.segment-id-parent {
  position: absolute;
  bottom: -20px;
  height: 100%;
  z-index: 2;
}
.segment-id-child {
  position: absolute;
  bottom: 24px;
  left: -9px;
  z-index: 2;
  background-color: #f7931d;
  border-radius: 50%;
  font-size: small;
  height: 20px;
  width: 20px;
}
.fake-slider-rail {
  position: absolute;
  top: 0;
  width: 100%;
  height: 3px;
  background: #8d999e;
  z-index: 2;
}
</style>
