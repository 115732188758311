var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-layer-selector-container"},[_c('div',{staticClass:"vector-layer-selector"},[_c('div',[_c('div',{staticClass:"vector-layer-selector-title"},[_vm._v("\n                "+_vm._s(_vm.$t("marine-stations"))+"\n            ")]),_vm._v(" "),_c('img',{staticClass:"vector-layer-selector-icon",attrs:{"src":_vm.layerStates[_vm.Category.Marine]
                        ? require('@/assets/marine_station.svg')
                        : require('@/assets/marine_station_grey.svg')},on:{"click":function($event){return _vm.changeLayerState(_vm.Category.Marine)}}})]),_vm._v(" "),_c('div',[_c('div',{staticClass:"vector-layer-selector-title"},[_vm._v("\n                "+_vm._s(_vm.$t("atmos-stations"))+"\n            ")]),_vm._v(" "),_c('img',{staticClass:"vector-layer-selector-icon",attrs:{"src":_vm.layerStates[_vm.Category.Atmospheric]
                        ? require('@/assets/atmospheric_station.svg')
                        : require('@/assets/atmospheric_station_grey.svg')},on:{"click":function($event){return _vm.changeLayerState(_vm.Category.Atmospheric)}}})]),_vm._v(" "),_c('div',[_c('div',{staticClass:"vector-layer-selector-title"},[_vm._v("\n                "+_vm._s(_vm.$t("prev-stations"))+"\n            ")]),_vm._v(" "),_c('img',{staticClass:"vector-layer-selector-icon",attrs:{"src":_vm.layerStates[_vm.Category.AtmosPrevision]
                        ? require('@/assets/atmos_prevision_stations_icon.svg')
                        : require('@/assets/atmos_prevision_stations_icon_grey.svg')},on:{"click":function($event){return _vm.changeLayerState(_vm.Category.AtmosPrevision)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }