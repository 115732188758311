<template>
    <div class="vector-layer-selector-container">
        <div class="vector-layer-selector">
            <div>
                <div class="vector-layer-selector-title">
                    {{ $t("marine-stations") }}
                </div>
                <img
                    class="vector-layer-selector-icon"
                    :src="
                        layerStates[Category.Marine]
                            ? require('@/assets/marine_station.svg')
                            : require('@/assets/marine_station_grey.svg')
                    "
                    @click="changeLayerState(Category.Marine)"
                />
            </div>
            <div>
                <div class="vector-layer-selector-title">
                    {{ $t("atmos-stations") }}
                </div>
                <img
                    class="vector-layer-selector-icon"
                    :src="
                        layerStates[Category.Atmospheric]
                            ? require('@/assets/atmospheric_station.svg')
                            : require('@/assets/atmospheric_station_grey.svg')
                    "
                    @click="changeLayerState(Category.Atmospheric)"
                />
            </div>
            <div>
                <div class="vector-layer-selector-title">
                    {{ $t("prev-stations") }}
                </div>
                <img
                    class="vector-layer-selector-icon"
                    :src="
                        layerStates[Category.AtmosPrevision]
                            ? require('@/assets/atmos_prevision_stations_icon.svg')
                            : require('@/assets/atmos_prevision_stations_icon_grey.svg')
                    "
                    @click="changeLayerState(Category.AtmosPrevision)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
export default {
    props: [],
    data() {
        let Category = stationDataLayer.Category;
        let layerStates = {};
        this.$set(layerStates, Category.Marine, true);
        this.$set(layerStates, Category.Atmospheric, true);
        this.$set(layerStates, Category.AtmosPrevision, true);

        return {
            Category,
            layerStates,
        };
    },
    methods: {
        changeLayerState(layer) {
            this.layerStates[layer] = !this.layerStates[layer];
        },
    },
    watch: {
        layerStates: {
            handler(value) {
                this.$emit("vectorLayerSelectedChanged", value);
            },
            deep: true,
        },
    },
    mounted() {
        this.$emit("vectorLayerSelectedChanged", this.layerStates);
    },
};
</script>

<style>
.vector-layer-selector {
    justify-content: center;
    border-radius: 10%;
    float: right;
    text-align: right;
    pointer-events:none;
}

.vector-layer-selector-icon {
    width: 29px;
    height: 29px;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
    margin: 3px;
    pointer-events:auto;
    /* float:right; */
}

/* .vector-layer-selector-icon:hover {
    background-color: rgba(231, 68, 16, 0.5);
} */

.active-vector-layer {
    background-color: #2D9EAA !important;
}
.vector-layer-selector-title {
    display: inline;
    pointer-events:none;
    color:white;
    font-size:small;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    background-color:rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding:2px

}
@media (max-width: 550px) {
    .vector-layer-selector-title {
        font-size:x-small;
    }
    .vector-layer-selector-icon{
        margin:0px;
    }
}
</style>