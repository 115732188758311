import axios from "axios";
import { getDateValuesFromDate } from "@/common/timeRelatedFunctions";
import { none } from "ol/centerconstraint";

const Code = {
  wlo: "wlo",
  wlp_hilo: "wlp-hilo",
  wlp: "wlp",
  wlf: "wlf",
  All: "All",
};

async function getStations() {
  const response = await axios.get(process.env.VUE_APP_IWLS_BASE_STATIONS_URL, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  let noneEmptyStations = await response.data.filter(function (station) { return station.timeSeries.length > 0 })
  return noneEmptyStations;
}

async function getStationData(id, code) {
  const validCodes = Object.values(Code).filter((x) => x != Code.All);

  const codesToRequest =
    code === Code.All ? validCodes : validCodes.filter((x) => x === code);

  const dateMin = new Date();
  dateMin.setDate(dateMin.getDate() - 1);
  dateMin.setHours(0, 0, 0, 0);

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() + 3);
  dateMax.setHours(0, 0, 0, 0);

  // On doit donner des dates spécifiques pour le phénomène wlp-hilo (prochains 6 jours)
  const dateMinHilo = new Date();
  dateMinHilo.setDate(dateMinHilo.getDate() - 1);
  dateMinHilo.setHours(0, 0, 0, 0);
  const dateMaxHilo = new Date();
  dateMaxHilo.setDate(dateMaxHilo.getDate() + 28);
  dateMaxHilo.setHours(23, 59, 0);

  const out = {};

  for (const _code of codesToRequest) {
    const timeStart = _code === Code.wlp_hilo ? dateMinHilo : dateMin;
    const timeEndPrediction = _code === Code.wlp_hilo ? dateMaxHilo : dateMax;
    try {
      const result = await axios.get(
        process.env.VUE_APP_IWLS_BASE_STATIONS_URL +
        id +
        "/data?time-series-code=" +
        _code +
        "&from=" +
        getDateValuesFromDate(timeStart) +
        "&to=" +
        getDateValuesFromDate(timeEndPrediction) +
        ""
      );
      out[_code] = result.data;
    }
    catch (error) {
      if (error.response && error.response.status === 404) {
        out[_code] = [];
      } else {
        throw error; // Re-throw the error if it's not a 404
      }
    }

  }

  return out;
}

export { getStations, getStationData, Code };
