<template>
    <div class="itinerary-table text-center">
        <b-table
            responsive
            striped
            hover
            :items="items"
            :fields="fieldsExtended"
            class="itinerary-just-table"
        >
            <template #head(speed)="data">
                <span class="itinerary-speed-label">{{ data.label }}</span>
                <b-form-select
                    class="itinerary-units-select"
                    v-model="unitsChosen"
                    :options="unitChoices"
                    required
                ></b-form-select>
            </template>
            <template v-slot:cell(speed)="row">
                <b-form-input
                    class="boat-speed-input"
                    min="1"
                    v-model="row.item.speed"
                    type="number"
                    @change="updateSpeeds(row)"
                />
            </template>
            <template v-slot:cell(departureDate)="row">
                <v-date-picker
                    v-if="row.index === 0"
                    v-model="departureDate"
                    mode="dateTime"
                    :popover="popover"
                    :masks="{ L: 'YYYY-MM-DD' }"
                    :is24hr="true"
                >
                    <template v-slot="{ inputValue, inputEvents, updateValue }">
                        <img
                            class="itinerary-clock-icon"
                            src="@/assets/clock.svg"
                        />
                        <input
                            class="
                                bg-white
                                border
                                px-2
                                py-1
                                rounded
                                input-itinerary-departure-date
                            "
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                        <div class="plus-minus-container">
                            <img
                                class="plus-minus-icon"
                                src="@/assets/ios-arrow-back.svg"
                                @click="updateValue(changeHour(-1))"
                            />
                            <img
                                class="plus-minus-icon"
                                src="@/assets/ios-arrow-forward.svg"
                                @click="updateValue(changeHour(1))"
                            />
                        </div>
                    </template>
                </v-date-picker>
                <div v-else>{{ displayDate(row.item.departureDate) }}</div>
            </template>
        </b-table>
        <div v-show="numberOfSegments > 1" class="extended-table-summary">
            <div class="extended-summary-child">
                {{ $t("total-length-itinerary") }}:
                {{ Math.round(totalDistance) }}
                {{ distanceUnitsShown }}
            </div>
            <div class="extended-summary-child">
                {{ $t("total_itinerary_length_title") }}: {{ totalDuration }}
            </div>
        </div>
    </div>
</template>

<script>
import itineraryTableMixin from "@/components/buttomPane/itinerary/mixins/itineraryTableMixin";
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
    mixins: [itineraryTableMixin],
    data() {
        return {
            departureDate: new Date(),
            newDepartureDate: null,
            arrivalDate: null,
            items: null,
            speeds: {},
            unitsChosen: "knots",
        };
    },
    computed: {
        fieldsExtended() {
            let itemToInsert = [
                {
                    key: "segment",
                    label: "Segment",
                },
            ];
            return itemToInsert.concat(this.fields);
        },
        totalDuration: function () {
            if (this.items === null) return "";
            return this.formatDuration(
                this.items[this.items.length - 1].durationMinutesFromStart
            );
        },
        totalDistance() {
            let [distanceInKm, distanceInKnots] = this.formatLength(
                this.$store.state.itineraryTotalLength
            );
            if (this.universalUnitsChosen === "knots") {
                return distanceInKnots;
            } else {
                return distanceInKm;
            }
        },
        numberOfSegments() {
            if (this.items === null) return 0;
            return this.items.length;
        },
    },
    methods: {
        changeHour(value) {
            return this.departureDate.setHours(
                this.departureDate.getHours() + value
            );
        },
        updateSpeeds: function (row) {
            if (this.universalUnitsChosen === "knots") {
                this.speeds[row.index] = row.value;
            } else {
                this.speeds[row.index] = this.kmToNm(parseFloat(row.value));
            }

            this.items = this.updateTable();
        },
        updateTable: function () {
            let rows = [];
            let departure_time = new Date(this.departureDate);
            let durationMinutesFromStart = 0;
            for (
                let i = 0;
                i < this.$store.state.detailedItineraryLength.length;
                i++
            ) {
                let length = this.$store.state.detailedItineraryLength[i];
                let speedInKnots;

                if (this.speeds[i] != undefined) {
                    speedInKnots = this.speeds[i];
                } else {
                    speedInKnots = 5;
                }
                let speedDisplayed;
                let distanceDisplayed;
                let [distanceInKm, distanceInKnots] = this.formatLength(length);

                if (this.universalUnitsChosen === "knots") {
                    speedDisplayed = speedInKnots;
                    distanceDisplayed = distanceInKnots;
                } else {
                    speedDisplayed = this.nmToKm(speedInKnots);
                    distanceDisplayed = distanceInKm;
                }

                let durationInMinutes = this.calculateDuration(
                    distanceInKnots,
                    speedInKnots
                );

                let arrivalDate = new Date(
                    departure_time.getTime() + durationInMinutes * 60000
                );

                let formattedArrival = formatDateToDisplay(
                    new Date(arrivalDate),
                    days[this.$i18n.locale]
                );

                durationMinutesFromStart =
                    durationMinutesFromStart + durationInMinutes;

                let row = {
                    segment: `${i}-${i + 1}`,
                    departureDate: new Date(departure_time),
                    speedInKnots: speedInKnots,
                    speed: speedDisplayed,
                    distance: distanceDisplayed,
                    distanceInKnots: distanceInKnots,
                    arrivalDate: arrivalDate,
                    durationInMinutes: Math.floor(durationInMinutes),
                    arrivalDateFormatted: formattedArrival,
                    time: this.formatDuration(Math.floor(durationInMinutes)),
                    durationMinutesFromStart: durationMinutesFromStart,
                };
                rows.push(row);

                departure_time = new Date(arrivalDate);
            }
            if (this.$store.state.detailedItineraryLength.length === 0) {
                let row = {
                    departureDate: new Date(departure_time),
                };
                rows.push(row);
            }

            if (rows.length > 0) {
                this.$emit("tableChanged", rows);
            }

            return rows;
        },
        displayDate: function (date) {
            return formatDateToDisplay(date, days[this.$i18n.locale]);
        },
        formatLength: function (lengthInMeters) {
            return [
                Math.round((lengthInMeters / 1000) * 100) / 100,
                Math.round((lengthInMeters / 1851.99932587) * 100) / 100,
            ];
        },
    },
    mounted() {
        this.items = this.updateTable();
    },
    watch: {
        universalDepartureDate: function (value, oldValue) {
            if (value.getTime() != oldValue.getTime()) {
                this.departureDate = value;
            }
        },
        "$store.state.detailedItineraryLength": function () {
            this.items = this.updateTable();
        },
        departureDate: function (value) {
            if (value.getTime() != this.items[0].departureDate.getTime()) {
                this.items = this.updateTable();
            }
        },
        universalUnitsChosen: function (value, oldValue) {
            if (value != oldValue) {
                this.unitsChosen = value;
                this.items = this.updateTable();
            }
        },
        unitsChosen: function (value) {
            this.$emit("unitsChanged", value);
        },
    },
};
</script>

<style>
.itinerary-just-table {
    margin-bottom: 0 !important;
}
.extended-table-summary {
    display: flex;
    justify-content: flex-end;
}
.extended-summary-child {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    margin: 5px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
}
</style>