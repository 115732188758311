<template>
  <div>
    <h5 class="areainfo-tab-title">{{ $t("areainfo-tab-title") }}</h5>
    <div v-if="$store.state.selectedCoordinates != null">
      <div class="lat-lon">
        Latitude
        {{
          Math.round($store.state.selectedCoordinates.latitude * 1000) / 1000
        }}, Longitude
        {{
          Math.round($store.state.selectedCoordinates.longitude * 1000) / 1000
        }}
      </div>
      <div class="lat-lon">{{dateToDisplay}}</div>
      <div class="flexCurrentsTides">
        <CurrentsInfoLayout class="CurrentsTidesItem pane-container-boxes">
          ></CurrentsInfoLayout
        >
        <TidesLayout
          class="CurrentsTidesItem pane-container-boxes"
        ></TidesLayout>
        <MeteoStationLayout
          class="CurrentsTidesItem pane-container-boxes"
          :station="nearestMeteoStation"
          :weatherStation="nearestWeatherStation"
          :showAllVariables="false"
          :presenceOfNearestMeteoStation="presenceOfNearestMeteoStation"
          :key="meteoKey"
        >
        </MeteoStationLayout>
      </div>
      <NearestMeteoMaritimeLayout></NearestMeteoMaritimeLayout>
    </div>
    <div style="text-align: center" v-else> {{ $t("coordinates-click-instructions")}} </div>
  </div>
</template>

<script>
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
import TidesLayout from "@/components/buttomPane/layouts/TidesLayout.vue";
import NearestMeteoMaritimeLayout from "@/components/buttomPane/layouts/NearestMeteoMaritimeLayout.vue";
import MeteoStationLayout from "@/components/buttomPane/layouts/MeteoStationLayout.vue";
import CurrentsInfoLayout from "@/components/buttomPane/layouts/CurrentsInfoLayout.vue";
import { Category } from "@/stationDataLayer/stationDataLayer";

export default {
  components: {
    TidesLayout,
    NearestMeteoMaritimeLayout,
    MeteoStationLayout,
    CurrentsInfoLayout,
  },
  data() {
    return { meteoKey: 0 };
  },
  computed: {
    nearestMeteoStation: function () {
      const atmosphericStations = this.$store.state.nearStations.filter(
        (x) => x.category === Category.Atmospheric
      );
      return atmosphericStations.length > 0 ? atmosphericStations[0] : null;
    },
    nearestWeatherStation: function () {
      const atmosphericStations = this.$store.state.nearStations.filter(
        (x) => x.category === Category.AtmosPrevision
      );
      return atmosphericStations.length > 0 ? atmosphericStations[0] : null;
    },
    presenceOfNearestMeteoStation: function () {
      return !this.nearestMeteoStation ? false : true;
    },
    dateToDisplay: function(){
      return formatDateToDisplay(this.$store.state.selectedTime, days[this.$i18n.locale]);
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.meteoKey += 1;
    },
  },
};
</script>

<style>
.areainfo-tab-title {
  text-align: center;
  font-weight: bold;
}
.lat-lon {
  text-align: center;
  font-style: italic;
}
@media screen and (min-width: 1000px) {
  .CurrentsTidesItem {
    max-width: 30% !important;
  }
  .flexCurrentsTides {
    justify-content: space-between !important;
  }
}
.flexCurrentsTides {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.CurrentsTidesItem {
  width: 100%;
  max-width: 300px;
}

</style>