<template>
    <div>
        <div class="pane-container-boxes">
            <div class="infoSectionTitle">{{ $t("itinerary") }}</div>
            <div class="itinerary-buttons-container">
                <button class="btn-menu itinerary-button" @click="startItinerary" v-show="!itineraryOn">
                    {{ $t("start-itinerary-button") }}
                </button>
                <button class="btn-menu itinerary-button" @click="stopItinerary" v-show="itineraryOn">
                    {{ $t("stop-itinerary-button") }}
                </button>
                <button class="btn-menu itinerary-button" @click="resetItinerary" :class="
                    $store.state.itineraryCoordinates.length > 0
                        ? ''
                        : 'button-deactivated'
                ">
                    {{ $t("reset-itinerary-button") }}
                </button>
                <button class="btn-menu itinerary-button" @click="saveItinerary">
                    {{ $t("save-itinerary-button") }}
                </button>
                <input style="display: none" id="loadItineraryButton" type="file" @change="loadItinerary" />
                <label class="btn-menu itinerary-button" style="margin: 2px" for="loadItineraryButton">{{
                    $t("load-itinerary-button") }}</label>
            </div>
            <div class="start-itinerary-explanation" v-show="itineraryOn">
                {{ $t("start-itinerary-explanation") }}
                <span @click="showMore = true" class="show-more-less-button" v-show="!showMore">{{ $t("show-more") }}</span>
            </div>
            <div class="stop-itinerary-explanation" v-show="!itineraryOn">
                {{ $t("stop-itinerary-explanation") }}
            </div>
            <div class="active-itinerary-warning" v-show="itineraryOn && showMore">
                {{ $t("active-itinerary-warning") }}
            </div>
            <div class="active-itinerary-warning" v-show="
                $store.state.itineraryCoordinates.length > 0 && showMore
            ">
                {{ $t("itinerary-points-warning") }}
            </div>
            <div class="show-more-less-button" v-show="showMore" @click="showMore = false">
                {{ $t("show-less") }}
            </div>
            <b-form-checkbox class="advanced-switch" v-model="advancedOptions" switch>
                {{ $t("advanced-switch-label") }}
            </b-form-checkbox>
            <div v-show="!advancedOptions">
                <ItineraryTable :universalDepartureDate="universalDepartureDate"
                    @departureDateChanged="updateUniversalDepartureDate" :universalUnitsChosen="unitsChosen"
                    :unitChoices="unitChoices" @unitsChanged="updateUniversalUnits"
                    @arrivalDateChanged="updateRegularArrivalDate" @durationChanged="updateRegularDuration">
                </ItineraryTable>
                <ItinerarySlider :formattedDepartureDate="formattedDepartureDate" :departureDate="universalDepartureDate"
                    :arrivalDate="regularArrivalDate" :universalSliderTime="universalSliderTime"
                    :extendedTable="extendedTable" sliderType="regular" :regularDurationMinutes="regularDurationMinutes"
                    @sliderValueChange="updateFractionAndTime"></ItinerarySlider>
            </div>
            <div v-show="advancedOptions" class="itinerary-table-and-slider">
                <ItineraryExtendedTable :universalUnitsChosen="unitsChosen" :unitChoices="unitChoices"
                    @unitsChanged="updateUniversalUnits" :universalDepartureDate="universalDepartureDate"
                    :selectedUnits="selectedUnits" @tableChanged="updateExtendedInfo"></ItineraryExtendedTable>
                <ItinerarySlider :formattedDepartureDate="formattedDepartureDate" :departureDate="universalDepartureDate"
                    :arrivalDate="advancedArrivalDate" :universalSliderTime="universalSliderTime"
                    :extendedTable="extendedTable" sliderType="extended" @sliderValueChange="updateFractionAndTime">
                </ItinerarySlider>
            </div>
        </div>
    </div>
</template>

<script>
import ItineraryTable from "/src/components/buttomPane/itinerary/ItineraryTable.vue";
import ItineraryExtendedTable from "/src/components/buttomPane/itinerary/ItineraryExtendedTable.vue";
import ItinerarySlider from "/src/components/buttomPane/itinerary/ItinerarySlider.vue";
import { fromLonLat, toLonLat } from "ol/proj";
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
import { GPXsaver, GPXloader } from "@/common/gpxParser";
export default {
    components: { ItineraryExtendedTable, ItineraryTable, ItinerarySlider },
    data() {
        let disableStartButton = false;
        let selectedUnits = "nm";
        let lengthInMeters = 0;
        let selectedSpeed;

        return {
            firstActivatedItinerary: false,
            unitsChosen: "knots",
            universalSliderTime: null,
            universalSliderPosition: 0,
            regularFraction: 0,
            extendedFraction: 0,
            extendedTable: null,
            universalDepartureDate: new Date(),
            selectedUnits,
            lengthInMeters,
            disableStartButton,
            selectedSpeed,
            regularDurationMinutes: 0,
            popover: {
                placement: "top",
                positionFixed: true,
                visibility: "focus",
            },
            advancedOptions: false,
            advancedArrivalDate: null,
            regularArrivalDate: null,
            showMore: false,
            arrivalDates: []
        };
    },
    computed: {
        devicePixelRatio() {
            return window.devicePixelRatio;
        },
        availHeight() {
            return window.screen.availHeight;
        },
        unitChoices() {
            return [
                { text: this.$t("knots"), value: "knots" },
                { text: "km/h", value: "km/h" },
            ];
        },
        itineraryOn() {
            if (this.$store.state.mapClickEvent === "itinerary") return true;
            return false;
        },
        formattedLength() {
            return this.formatLength(this.$store.state.itineraryTotalLength);
        },
        formattedDepartureDate() {
            return formatDateToDisplay(
                this.universalDepartureDate,
                days[this.$i18n.locale]
            );
        },
    },
    methods: {
        updateUniversalDepartureDate(value) {
            let selectedValue = new Date(value);
            selectedValue.setHours(
                selectedValue.getHours() + this.universalSliderPosition + 1,
                // Math.round(selectedValue.getMinutes() / 60),
                0,
                0,
                0
            );
            if (
                this.$store.state.selectedTime != null &&
                this.firstActivatedItinerary
            ) {
                if (
                    selectedValue.getTime() !=
                    this.$store.state.selectedTime.getTime()
                ) {
                    this.$store.mutations.updateSelectedTime(selectedValue);
                }
            }

            this.universalDepartureDate = value;
            this.universalSliderTime = selectedValue;
        },
        updateUniversalUnits(value) {
            this.unitsChosen = value;
        },
        updateRegularDuration(value) {
            this.regularDurationMinutes = value;
            this.regularFraction = this.getItineraryFraction(
                this.universalSliderTime
            );
        },
        updateRegularArrivalDate(value) {
            if (value != null) {
                this.regularArrivalDate = new Date(value);
            }
        },
        updateExtendedInfo(value) {
            if (value === undefined) return
            if (value[0].arrivalDate != undefined) {
                this.advancedArrivalDate = new Date(
                    value[value.length - 1].arrivalDate
                );
            }
            this.updateUniversalDepartureDate(new Date(value[0].departureDate));
            // this.universalDepartureDate = new Date(value[0].departureDate);
            this.extendedTable = value;
            this.extendedFraction = this.getItineraryFractionFromAdvanced(
                this.universalSliderTime
            );

            this.arrivalDates = []
            this.arrivalDates.push(value[0].departureDate)
            for (let point of value) {
                this.arrivalDates.push(point.arrivalDate)
            }

        },
        updateFractionAndTime: function (value) {
            this.universalSliderTime = value[1];
            this.universalSliderPosition = value[0];
            this.$store.mutations.updateSelectedTime(value[1]);
            this.regularFraction = this.getItineraryFraction(value[1]);
            this.extendedFraction = this.getItineraryFractionFromAdvanced(
                value[1]
            );
        },

        resetItinerary() {
            this.$store.state.map.getLayers().forEach(function (layerFound) {
                if (
                    [
                        "itineraryPointLayer",
                        "itineraryLineLayer",
                        "itineraryFractionPointLayer",
                    ].includes(layerFound.get("name"))
                ) {
                    if (layerFound.getSource() != null) {
                        layerFound.getSource().clear();
                    }
                }
            });
            this.advancedArrivalDate = null;
            this.regularArrivalDate = null;
            this.$store.mutations.updateItineraryCoordinates([]);
        },
        startItinerary() {
            this.$store.mutations.updateMapClickEvent("itinerary");
        },
        stopItinerary() {
            this.$store.mutations.updateMapClickEvent("location");
        },
        saveItinerary() {
            let lonLatCoordinates = [];
            for (let coordinate of this.$store.state.itineraryCoordinates) {
                lonLatCoordinates.push(toLonLat(coordinate));
            }
            GPXsaver(lonLatCoordinates, this.arrivalDates);
        },
        async loadItinerary(event) {
            if (document.getElementById("loadItineraryButton").value === null) return
            this.startItinerary()
            this.resetItinerary()
            let file = event.target.files[0];
            let points = await GPXloader(file)
            this.$store.mutations.updateDownloadedItineraryCoordinates(points);
            document.getElementById("loadItineraryButton").value = null
        },

        getItineraryFraction: function (dateChosen) {
            let diff = dateChosen - this.universalDepartureDate;
            let diffMinutes = Math.floor(diff / 1000 / 60);
            let fraction = diffMinutes / this.regularDurationMinutes;
            if (fraction > 1) {
                return 1;
            }
            return fraction;
        },

        getItineraryFractionFromAdvanced: function (value) {
            let totalDist = this.extendedTable.reduce(
                (n, { distanceInKnots }) => n + distanceInKnots,
                0
            );
            let baseDist = 0;
            let baseMinutes = 0;
            let diffMinutes = Math.floor(
                (value - this.universalDepartureDate) / 1000 / 60
            );
            for (let i = 0; i < this.extendedTable.length; i++) {
                let segmentDuration = this.extendedTable[i].durationInMinutes;
                let endSegmentTotal = baseMinutes + segmentDuration;
                if (diffMinutes < endSegmentTotal) {
                    let minutesInSegment = diffMinutes - baseMinutes;
                    let distInSgement =
                        this.extendedTable[i].speedInKnots *
                        (minutesInSegment / 60);
                    return (baseDist + distInSgement) / totalDist;
                }
                baseMinutes = endSegmentTotal;
                baseDist =
                    baseDist +
                    this.extendedTable[i].speed * (segmentDuration / 60);
            }
            return 1;
        },
    },
    watch: {
        "$store.state.selectedTime": function (value) {
            this.universalSliderTime = value;
        },
        regularFraction: function (value) {
            if (!this.advancedOptions) {
                this.$store.mutations.updateSelectedItineraryFraction(value);
            }
        },
        extendedFraction: function (value) {
            if (this.advancedOptions) {
                this.$store.mutations.updateSelectedItineraryFraction(value);
            }
        },
        advancedOptions: function (value) {
            if (value) {
                this.$store.mutations.updateSelectedItineraryFraction(
                    this.extendedFraction
                );
            } else {
                this.$store.mutations.updateSelectedItineraryFraction(
                    this.regularFraction
                );
            }
        },
        itineraryOn: function (value) {
            if (value) {
                this.firstActivatedItinerary = true;
            }
        },
    },
};
</script>

<style>
.boat-speed-input {
    max-width: 100px !important;
    display: inline-block !important;
}

.itinerary-slider-box {
    width: 90%;
    position: relative !important;
}

.v-application--wrap {
    min-height: 0 !important;
}

.itinerary-slider {
    margin: 25px 50px 0 50px;
}

.v-slider__thumb-label {
    width: 90px !important;
    transform: none !important;
    border-radius: 10% !important;
    position: relative !important;
    top: -40px !important;
    left: -43px !important;
}

.v-slider__thumb-label>* {
    transform: none !important;
}

.v-slider__thumb {
    cursor: grab;
}

.v-slider {
    cursor: pointer !important;
}

.itinerary-buttons-container {
    display: flex;
    justify-content: center;
}

button.itinerary-button {
    margin: 2px;
}

.itinerary-clock-icon {
    width: 25px;
    height: 25px;
}

.departure-time-template {
    min-width: 230px !important;
}

.input-itinerary-departure-date {
    max-width: 170px !important;
}

.button-deactivated {
    background-color: grey !important;
    pointer-events: none;
    cursor: none;
}

.start-itinerary-explanation {
    color: #00adef;
    font-size: small;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
}

.stop-itinerary-explanation {
    font-size: small;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
}

.active-itinerary-warning {
    color: #00adef;
    font-size: small;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
}

.advanced-switch {
    font-size: 12px;
    max-height: 40px;
    width: 200px !important;
    position: relative !important;
    margin: 0px 8px;

    /* display:flex; */
    /* align-items: center; */
}

.advanced-switch .custom-control-label {
    background-color: transparent;
    padding-top: 4px;
    cursor: pointer;
}

.v-label {
    margin: 0px;
    font-size: small !important;
}

.v-input--selection-controls {
    margin-top: 0px !important;
}

.itinerary-speed-label {
    display: inline-block;
}

.itinerary-units-select {
    display: inline-block;
    font-size: small !important;
    padding: 0.375rem !important;
    width: 75px !important;
}

@media screen and (max-width:400px) {
    .itinerary-buttons-container {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 733px) {
    .itinerary-table {
        font-size: small;
    }
}

.itinerary-table {
    margin-bottom: 1rem;
}

.pane-container-boxes .btn-menu {
    padding: 10px;
    width: 250px;
}

@media screen and (max-width: 650px) {
    .pane-container-boxes .btn-menu {
        padding: 5px;
        width: 150px;

    }
}

.show-more-less-button {
    text-decoration: underline;
    cursor: pointer;
    color: #2D9EAA;
    font-size: small;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
}
</style>