import Vue from "vue";
import App from "./App.vue";
import store from "./store";
// import { BootstrapVue } from "bootstrap-vue";
import i18n from "./i18n";
// import VCalendar from "v-calendar";
import VueCookies from "vue-cookies";

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

// import "./custom.scss";

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue);
// Vue.use(VCalendar, {
//   componentPrefix: "vc",
// });
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.component('VueSlider', VueSlider)

new Vue({
  i18n,
  render: (h) => h(App)
}).$mount("#app");
