import { getLatestObservations } from "./dd-observations.js";
import {
    getPrevisionStationList,
    getStationData as getAtmosPrevisionStationData
} from "./dd-previsions.js";
import {
    getStations,
    getStationData as getIwlsStationData,
    Code,
} from "./iwls.js";
import {getLatestData as getMeteoMaritimeData} from "./dd-meteo-maritime.js"
import { getDistanceFromLatLonInKm } from "@/common/locationFunctions.js";
import memoize from "memoizee";
import Vue from "vue";
import { Circle } from "js-quadtree";

const getLatestObservationsWithCache = memoize(getLatestObservations, {
    maxAge: 120000,
    promise: true,
});

const Category = {
    Marine: "Marine",
    Atmospheric: "Atmospheric",
    AtmosPrevision: "AtmosPrevision",
    MeteoMaritime: "MeteoMaritime",
};

const Source = {
    dd_meteo_gc_ca: "dd_meteo_gc_ca",
    Iwls: "Iwls",
    dd_atmos_prevision: "dd_atmos_prevision",
};

const exportedData = Vue.observable({
    stationsList: [],
});
// for (let source in Source){
//     exportedData.stationsList[source] = []
// }

const vm = new Vue({
    data() {
        return {
            dd_meteo_gc_ca_stationsAndData: [],
            iwlsStations: [],
            previsionStationTree: null,
        };
    },
    created() {
        getLatestObservations(new Date().getTime()).then((observations) => {
            this.dd_meteo_gc_ca_stationsAndData.push(...observations);
        });
        getPrevisionStationList().then((stationList) => {
            this.previsionStationTree = stationList;
        });
        const _this = this;
        getStations().then((stations) => {
            _this.iwlsStations = stations;
        });
    },
    watch: {
        stationsList(newStationsList) {
            exportedData.stationsList = newStationsList;
        },
    },
    computed: {
        stationsList() {
            let out_atmos = this.dd_meteo_gc_ca_stationsAndData.map((x) => {
                x.source = Source.dd_meteo_gc_ca;
                x.category = Category.Atmospheric;
                return x;
            });
            let out_marine = this.iwlsStations.map((x) => {
                x.source = Source.Iwls;
                x.category = Category.Marine;
                return x;
            });

            let out_atmosPrevision;
            if (this.previsionStationTree != null) {
                out_atmosPrevision = this.previsionStationTree.getAllPoints();
            }

            return {
                [Source.dd_meteo_gc_ca]: out_atmos,
                [Source.Iwls]: out_marine,
                [Source.dd_atmos_prevision]: out_atmosPrevision,
            };
        },
    },
    methods: {
        getNearStationsSorted(coordinates) {
            if (!coordinates) return [];
            let arrOfStations = [];
            arrOfStations.push(...this.stationsList[Source.dd_meteo_gc_ca]);
            arrOfStations.push(...this.stationsList[Source.Iwls]);

            const nearStations = [];
            for (const station of arrOfStations) {
                const distance = getDistanceFromLatLonInKm(
                    station.latitude,
                    station.longitude,
                    coordinates.latitude,
                    coordinates.longitude
                );
                if (distance < 30) {
                    const stationNameObj = {
                        name: station.officialName,
                        id: station.id,
                        distanceFromClick: distance,
                        longitude: station.longitude,
                        latitude: station.latitude,
                        source: station.source,
                        category: station.category,
                    };

                    nearStations.push(stationNameObj);
                }
            }

            let previsionStations = [];
            if (this.previsionStationTree != null) {
                previsionStations = this.previsionStationTree.query(
                    new Circle(coordinates.longitude, coordinates.latitude, 0.3)
                );
                previsionStations.map(
                    (x) =>
                        (x.distanceFromClick = getDistanceFromLatLonInKm(
                            x.y,
                            x.x,
                            coordinates.latitude,
                            coordinates.longitude
                        ))
                );
            }

            let nearStationsComplete = nearStations.concat(previsionStations);

            nearStationsComplete.sort((s1, s2) =>
                s1.distanceFromClick > s2.distanceFromClick ? 1 : -1
            );
            return nearStationsComplete;
        },
    },
});

const getAtmosphericStationData = async function (id, dateMax) {
    const observations = await getLatestObservationsWithCache(
        dateMax.getTime()
    );
    return observations.find((x) => x.id === id);
};

const all = {
    MarineDataCode: Code,
    Category,
    Source,
    getNearStationsSorted: vm.getNearStationsSorted,
    getAtmosphericStationData: getAtmosphericStationData,
    getMarineStationData: getIwlsStationData,
    getMarineStationDataWithCache: memoize(getIwlsStationData, {
        maxAge: 120000,
        promise: true,
    }),
    getAtmosPrevisionStationDataWithCache: memoize(getAtmosPrevisionStationData, {
        maxAge: 120000,
        promise: true,
    }),
    getMeteoMaritimeDataWithCache: memoize(getMeteoMaritimeData, {
        maxAge: 120000,
        promise: true,
    }),
    data: exportedData,
};

export { Category, Source };
export default all;
