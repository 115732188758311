<template>
    <div>
        <div v-if="$store.state.selectedLayer === Layer.CURRENTS">
            <div class="layer-legend" id="current-legend">
                <div class="current-legend-scale-gradient">
                    <span class="wind-legend-text-units">kn</span>
                    <span class="current-legend-text-1">0</span>
                    <span class="current-legend-text-2">1</span>
                    <span class="current-legend-text-3">2</span>
                    <span class="current-legend-text-4">3</span>
                    <span class="current-legend-text-5">4</span>
                    <span class="current-legend-text-6">5</span>
                    <span class="current-legend-text-7">6</span>
                    <span class="current-legend-text-8">7+</span>
                </div>
            </div>
        </div>
        <div
            v-if="
                $store.state.selectedLayer === Layer.WINDS ||
                $store.state.selectedLayer === Layer.GUSTS
            "
        >
            <div class="layer-legend" id="wind-legend">
                <div class="wind-legend-scale-gradient">
                    <span class="wind-legend-text-units">kn</span>
                    <span class="wind-legend-text-1">0</span>
                    <span class="wind-legend-text-2">5</span>
                    <span class="wind-legend-text-3">10</span>
                    <span class="wind-legend-text-4">15</span>
                    <span class="wind-legend-text-5">20</span>
                    <span class="wind-legend-text-6">25</span>
                    <span class="wind-legend-text-7">30</span>
                    <span class="wind-legend-text-8">35</span>
                    <span class="wind-legend-text-9">40+</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Layer } from "@/constants";
export default {
    created() {
        this.Layer = Layer;
    },
};
</script>

<style scoped>
.legend-envelop {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 3px;
    border-radius: 7px;
    width: 59px;
    margin-left: auto;
    margin-right: 0;
    text-align: center;
}
.layer-legend {
    font-size: 11px;
    font-weight: bold;
    background-color: rgba(0, 60, 136, 0.6);
    /* padding: 2px; */
    border-radius: 7px;
    /* padding: 3px 2px; */
}

.legend-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
}
.last-legend-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 0px;
}
.legend-text {
    position: relative;
    /* top: -8px; */
    padding-right: 2px;
    display: block;
}

.legend-units {
    transform: rotate(-90deg);
    height: 15px;
    position: relative;
    width: 15px;
    /* float: left; */
    font-size: 13px;
    margin: 2px;
    top: 10px;
    left: -3px;
}
.legend-scale {
    position: relative;
    display: flex;
}
.wind-legend-scale-gradient {
    height: 17px;
    background: linear-gradient(
        to right,
        rgba(46, 75, 203, 1) 0%,
        rgba(46, 75, 203, 1) 13%,
        rgba(48, 157, 204, 1) 23%,
        rgba(49, 210, 205, 1) 33%,
        rgba(55, 211, 132, 1) 43%,
        rgba(152, 218, 66, 1) 53%,
        rgba(253, 225, 68, 1) 63%,
        rgba(223, 138, 50, 1) 73%,
        rgba(191, 42, 31, 1) 83%,
        rgba(223, 43, 189, 1) 93%
    );
    /* border-style: solid;
    border-width: 1px;
    border-color: black; 
    margin: 1px; */
    padding: 0 0 0 2px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.35);
    border-radius: 5px;
}
.wind-legend-text-units {
    position: absolute;
    left: 2%;
}
.wind-legend-text-1 {
    position: absolute;
    left: 13%;
}
.wind-legend-text-2 {
    position: absolute;
    left: 23%;
}
.wind-legend-text-3 {
    position: absolute;
    left: 33%;
}
.wind-legend-text-4 {
    position: absolute;
    left: 43%;
}
.wind-legend-text-5 {
    position: absolute;
    left: 53%;
}
.wind-legend-text-6 {
    position: absolute;
    left: 63%;
}
.wind-legend-text-7 {
    position: absolute;
    left: 73%;
}
.wind-legend-text-8 {
    position: absolute;
    left: 83%;
}
.wind-legend-text-9 {
    position: absolute;
    left: 92%;
}
.current-legend-scale-gradient {
    height: 17px;
    background: linear-gradient(
        to right,
        rgba(224, 226, 230, 1) 15%,
        rgba(150, 173, 228, 1) 24%,
        rgba(55, 106, 226, 1) 36%,
        rgba(36, 157, 83, 1) 49%,
        rgba(249, 131, 8, 1) 60%,
        rgba(249, 26, 8, 1) 85%,
        rgba(249, 8, 233, 1) 93%
    );
    color: black;
    padding: 0 0 0 2px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.35);
    border-radius: 5px;
}
.current-legend-text-1 {
    position: absolute;
    left: 15%;
}
.current-legend-text-2 {
    position: absolute;
    left: 26%;
}
.current-legend-text-3 {
    position: absolute;
    left: 37%;
}
.current-legend-text-4 {
    position: absolute;
    left: 48%;
}
.current-legend-text-5 {
    position: absolute;
    left: 59%;
}
.current-legend-text-6 {
    position: absolute;
    left: 70%;
}
.current-legend-text-7 {
    position: absolute;
    left: 81%;
}
.current-legend-text-8 {
    position: absolute;
    left: 92%;
}
@media (max-width: 920px) {
    .wind-legend-scale-gradient {
        height: 15px;
        border-radius: 0px;
    }
    .current-legend-scale-gradient {
        height: 15px;
        border-radius: 0px;
    }
}
@media (max-width: 550px) {
    .legend-text {
        padding-right: 1px;
        font-size: 9px;
    }
    .legend-units {
        /* width: 20px; */
        float: left;
        font-size: 11px;
        top: 5px;
        left: -2px;
    }
}
</style>