<template>
    <div></div>
</template>

<script>
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import Feature from "ol/Feature";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import { Modify } from "ol/interaction";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { getLength, getDistance } from "ol/sphere";
import { toLonLat, fromLonLat } from "ol/proj";
export default {
    data() {
        let _this = this;

        let vectorLayer = null;
        let pointVectorLayer = null;
        let fractionLayer = null;

        let source = new VectorSource();
        let pointSource = new VectorSource();

        let singleClickEvent = null;
        let modify = new Modify({ source: source });
        modify.on("modifyend", function () {
            _this.updateItineraryCoordinates();
        });

        return {
            source,
            pointSource,
            vectorLayer,
            fractionLayer,
            modify,
            singleClickEvent,
            vectorLayerCoordinates: null,
            pointVectorLayer,
        };
    },
    computed: {
        vectorLayerSource() {
            if (this.vectorLayer != null) {
                return this.vectorLayer.getSource().getFeatures()[0];
            }
            return null;
        },
    },
    methods: {
        appendOrDeleteCoordinates(e) {
            let _this = this;
            let pointOnClick = this.checkForItineraryNodeOnClick(e);
            if (this.vectorLayer.getSource().getFeatures().length === 0) {
                // Si aucun feature, on créé la couche
                this.vectorLayer.getSource().addFeature(
                    new Feature({
                        geometry: new LineString([e.coordinate]),
                    })
                );
            } else {
                // Si on a pas cliqué sur un point, on ajoute à la liste des points de l'itinéraire
                if (!pointOnClick.clickOnPoint) {
                    this.vectorLayer
                        .getSource()
                        .getFeatures()[0]
                        .getGeometry()
                        .appendCoordinate(e.coordinate);
                } else {
                    // Si un point est cliqué, on doit l'enlever. Le problème, c'est que si ligne est cliqué (pour ajouter un point),
                    // le "modify" va ajouter un point, donc ce code va le supprimer par la suite.
                    if (pointOnClick.coordinates != null) {
                        let itineraryCoordinates = this.vectorLayer
                            .getSource()
                            .getFeatures()[0]
                            .getGeometry()
                            .getCoordinates();

                        const equals = (a, b) =>
                            JSON.stringify(a) === JSON.stringify(b);
                        itineraryCoordinates.forEach(function (coordinate, i) {
                            if (equals(coordinate, pointOnClick.coordinates)) {
                                itineraryCoordinates.splice(i, 1);
                                _this.vectorLayer
                                    .getSource()
                                    .getFeatures()[0]
                                    .getGeometry()
                                    .setCoordinates(itineraryCoordinates);
                            }
                        });
                    }
                }
            }
            this.updateItineraryCoordinates();
        },
        updateItineraryCoordinates() {
            this.vectorLayerCoordinates = this.vectorLayer
                .getSource()
                .getFeatures()[0]
                .getGeometry()
                .getCoordinates();
        },
        addInteraction() {
            this.$store.state.map.on(
                "singleclick",
                this.appendOrDeleteCoordinates
            );

            this.$store.state.map.addInteraction(this.modify);
        },

        removeInteraction() {
            this.$store.state.map.removeInteraction(this.modify);
            this.$store.state.map.un(
                "singleclick",
                this.appendOrDeleteCoordinates
            );
        },
        checkForItineraryNodeOnClick(evt) {
            let _this = this;
            let clickOnPoint = false;
            let coordinates = null;
            _this.$store.state.map.forEachFeatureAtPixel(
                evt.pixel,
                function (f, layer) {
                    if (layer === null) {
                        return;
                    }
                    if (!(layer.get("name") === "itineraryPointLayer")) return;

                    clickOnPoint = true;
                    coordinates = f.getGeometry().getCoordinates();
                }
            );
            return { clickOnPoint, coordinates };
        },
    },
    mounted() {
        var _this = this;
        this.vectorLayer = new VectorLayer({
            name: "itineraryLineLayer",
            source: _this.source,
            style: new Style({
                fill: new Fill({
                    color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                    color: "#f7931d",
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: "#f7931d",
                    }),
                }),
            }),
            zIndex: 10000,
        });
        this.pointVectorLayer = new VectorLayer({
            name: "itineraryPointLayer",
            source: _this.pointSource,
            zIndex: 10000,
        });
        this.fractionLayer = new VectorLayer({
            name: "itineraryFractionPointLayer",
            source: _this.pointSource,
            style: new Style({
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                        color: "#ffffff",
                    }),
                }),
            }),
            zIndex: 10000,
        });
        let layers = [
            this.vectorLayer,
            this.pointVectorLayer,
            this.fractionLayer,
        ];
        this.$emit("layersToAdd", layers);
    },
    watch: {
        "$store.state.downloadedItineraryCoordinates": function (value) {
            if (value === undefined) return;
            if (value.length === 0) return;

            if (this.vectorLayer.getSource().getFeatures().length === 0) {
                // Si aucun feature, on créé la couche
                this.vectorLayer.getSource().addFeature(
                    new Feature({
                        geometry: new LineString([value[0]]),
                    })
                );
            }
            for (let i = 1; i < value.length; i++) {
                this.vectorLayer
                    .getSource()
                    .getFeatures()[0]
                    .getGeometry()
                    .appendCoordinate(value[i]);
            }
            this.updateItineraryCoordinates();
            this.$store.mutations.updateDownloadedItineraryCoordinates([])
        },
        "$store.state.selectedItineraryFraction": function (value) {
            if (value === null) return;
            if (this.fractionLayer.getSource() != null) {
                this.fractionLayer.getSource().clear();
            }

            let features = this.vectorLayer.getSource().getFeatures();

            if (features.length > 0) {
                let fractionCoordinates = features[0]
                    .getGeometry()
                    .getCoordinateAt(value);

                const feature = new Feature({
                    geometry: new Point(fractionCoordinates),
                });
                this.fractionLayer.setSource(
                    new VectorSource({
                        features: [feature],
                    })
                );
            }
        },
        vectorLayerSource: function (value) {
            if (value === undefined) {
                this.vectorLayerCoordinates = [];
            }
        },
        "$store.state.mapClickEvent": function () {
            if (this.$store.state.mapClickEvent === "location") {
                this.removeInteraction();
            } else {
                this.addInteraction();
            }
        },
        vectorLayerCoordinates: function (coordinatesArr) {
            if (coordinatesArr === null) return;
            if (coordinatesArr.length === 0) {
                this.$store.mutations.updateItineraryTotalLength(0);
                this.$store.mutations.updateDetailedItineraryLength([]);
                return;
            }
            this.$store.mutations.updateItineraryCoordinates(coordinatesArr);
            // this.$store.mutations.updateItineraryCoordinates(coordinatesArr)
            this.$store.mutations.updateItineraryTotalLength(
                getLength(
                    this.vectorLayer.getSource().getFeatures()[0].getGeometry()
                )
            );

            // Update length of each segment for the extend table version
            let detailedItineraryLength = [];
            for (let i = 0; i < coordinatesArr.length - 1; i++) {
                detailedItineraryLength.push(
                    getDistance(
                        toLonLat(coordinatesArr[i]),
                        toLonLat(coordinatesArr[i + 1])
                    )
                );
            }
            this.$store.mutations.updateDetailedItineraryLength(
                detailedItineraryLength
            );

            if (this.pointVectorLayer.getSource() != null) {
                this.pointVectorLayer.getSource().clear();
            }
            const features = [];
            coordinatesArr.forEach(function (coordinate, i) {
                const feature = new Feature({
                    geometry: new Point(coordinate),
                });
                feature.setStyle(
                    new Style({
                        fill: new Fill({
                            color: "rgba(255, 255, 255, 0.2)",
                        }),
                        image: new CircleStyle({
                            radius: 10,
                            fill: new Fill({
                                color: "#f7931d",
                            }),
                        }),
                        text: new Text({ text: i.toString() }),
                    })
                );
                features.push(feature);
            });
            if (features.length != 0) {
                this.pointVectorLayer.setSource(
                    new VectorSource({
                        features: features,
                    })
                );
            }
        },
    },
};
</script>

<style>
</style>