<template>
    <div>
        <div class="modal-core-section-text-title"> {{ $t("first-visit") }}</div>
        <p class="header-introduction-text">{{ $t("header-introduction-text") }}
        </p>
        <p class="header-introduction-text">{{ $t("header-introduction-data-sources")
        }}<a style="font-weight: bold;">{{ $t("dfo") }}</a> <a>{{
    $t("and-d") }}</a> <a style="font-weight: bold;">{{
        $t("eccc") }}</a>. {{ $t("header-introduction-data-source-end") }}<a :href="contextUrl" target="_blank">{{
        $t("header-about-button") }}</a>
        </p>
        <p class="header-introduction-text">{{ $t("header-disclaimer") }}</p>
    </div>
</template>

<script>
export default {
    computed: {
        contextUrl() {
            return (
                process.env.VUE_APP_OGSL_URL +
                this.$t("external-context-end-url")
            );
        },
    },
};
</script>

<style>

</style>