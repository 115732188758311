<template>
    <div>
        <!-- <div class="warning-label warning-label-and-title" v-if="!inRange || quebecCoordinatesSelected || mtlCoordinatesSelected" -->
        <div class="warning-label warning-label-and-title" v-if="quebecCoordinatesSelected || mtlCoordinatesSelected"
            @click="showWarning = !showWarning">
            <div class="warning-text">{{ $t("warning") }}</div>
            <img class="close-warning-no-data" :src="showWarning
                    ? require(`@/assets/close-arrow.svg`)
                    : require(`@/assets/open-arrow.svg`)
                " />
        </div>
        <!-- <transition name="fadeHeightNoDataPane" mode="out-in">
            <div v-if="showWarning && !inRange" @click="showWarning = !showWarning" class="warning-label-and-title">
                <div>{{ noDataString }}</div>
            </div>
        </transition> -->
        <transition name="fadeHeightNoDataPane" mode="out-in">
            <div v-if="showWarning && quebecCoordinatesSelected" @click="showWarning = !showWarning"
                class="warning-label-and-title">
                <div v-if="quebecCoordinatesSelected">
                    {{ $t("quebecRegionWarning") }}
                </div>
            </div>
        </transition>
        <transition name="fadeHeightNoDataPane" mode="out-in">
            <div v-if="showWarning && mtlCoordinatesSelected" @click="showWarning = !showWarning"
                class="warning-label-and-title">
                <div v-if="mtlCoordinatesSelected">
                    {{ $t("mtlRegionWarning") }} <a class="warning-hyperlink" :href="contextUrl" target="_blank">{{ $t("navigationContext") }}</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inRange: true,
            windTimeRange: null,
            stleCurrentsTimeRange: null,
            gulfCurrentsTimeRange: null,
            noCurrents: [],
            showWarning: true,
        };
    },
    computed: {
        contextUrl() {
            return (
                process.env.VUE_APP_OGSL_URL +
                this.$t("external-context-end-url")
            );
        },
        quebecCoordinatesSelected() {
            if (this.$store.state.quebecCoordinatesSelected && this.$store.state.selectedLayer == "currents" && this.$store.state.stle200Status == false) { return true }
            return false;
        },
        mtlCoordinatesSelected() {
            if (this.$store.state.mtlCoordinatesSelected && this.$store.state.selectedLayer == "currents") { return true }
            return false;
        },
        noDataString() {
            return `${this.$t("no-gridded-data-first")}${this.layerString
                }${this.$t("no-gridded-data-second")}`;
        },
        layerString() {
            if (this.$store.state.selectedLayer === "winds") {
                return this.$t("wind-lowercase");
            } else if (this.$store.state.selectedLayer === "gusts") {
                return this.$t("gusts-lowercase");
            } else if (this.$store.state.selectedLayer === "currents") {
                let models = "";
                if (this.noCurrents.length === 1) {
                    models = `(Region: ${this.$t(this.noCurrents[0])})`;
                }
                if (this.noCurrents.length === 2) {
                    models = `(Regions: ${this.$t(
                        this.noCurrents[0]
                    )} ${this.$t("and")} ${this.$t(this.noCurrents[1])})`;
                }

                return `${this.$t("currents-lowercase")} ${models}`;
            } else {
                return "";
            }
        },
    },
    methods: {
        getWmsCapabilities() {
            let _this = this;
            fetch(
                process.env.VUE_APP_GEOSERVER_URL +
                "geoserver/eccc_wind_predictions/wms?service=WMS&version=1.1.0&request=GetCapabilities"
            )
                .then(function (response) {
                    return response.text();
                })
                .then(function (text) {
                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(text, "text/xml");
                    let extents = xmlDoc.getElementsByTagName("Extent");
                    for (let extent of extents) {
                        for (let node of extent.parentNode.childNodes) {
                            if (node.nodeName === "Name") {
                                if (
                                    node.innerHTML === "eccc_wind_predictions"
                                ) {
                                    let dates = extent.innerHTML.split(",");
                                    _this.windTimeRange = [
                                        new Date(dates[0]),
                                        new Date(dates[dates.length - 1]),
                                    ];
                                }
                            }
                        }
                    }
                });

            fetch(
                process.env.VUE_APP_GEOSERVER_URL +
                "geoserver/shc_courants/wms?service=WMS&version=1.1.0&request=GetCapabilities"
            )
                .then(function (response) {
                    return response.text();
                })
                .then(function (text) {
                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(text, "text/xml");
                    let extents = xmlDoc.getElementsByTagName("Extent");
                    for (let extent of extents) {
                        for (let node of extent.parentNode.childNodes) {
                            if (node.nodeName === "Name") {
                                if (
                                    node.innerHTML === "gulf_courants_coverage"
                                ) {
                                    let dates = extent.innerHTML.split(",");
                                    _this.gulfCurrentsTimeRange = [
                                        new Date(dates[0]),
                                        new Date(dates[dates.length - 1]),
                                    ];
                                }
                                if (
                                    node.innerHTML === "stle_courants_coverage"
                                ) {
                                    let dates = extent.innerHTML.split(",");
                                    _this.stleCurrentsTimeRange = [
                                        new Date(dates[0]),
                                        new Date(dates[dates.length - 1]),
                                    ];
                                }
                            }
                        }
                    }
                });
        },
        checkActiveLayerInRange() {
            let time = this.$store.state.selectedTime;
            if (this.$store.state.selectedLayer === "winds" || this.$store.state.selectedLayer === "gusts") {
                this.inRange =
                    time >= this.windTimeRange[0] &&
                    time <= this.windTimeRange[1];
            }
            if (this.$store.state.selectedLayer === "currents") {
                if (this.stleCurrentsTimeRange === null) return;
                this.noCurrents = [];
                let stle =
                    time >= this.stleCurrentsTimeRange[0] &&
                    time <= this.stleCurrentsTimeRange[1];
                if (this.gulfCurrentsTimeRange === null) return;
                let gulf =
                    time >= this.gulfCurrentsTimeRange[0] &&
                    time <= this.gulfCurrentsTimeRange[1];
                if (!stle || !gulf) {
                    this.inRange = false;
                } else {
                    this.inRange = true;
                }
                if (!stle) {
                    this.noCurrents.push("stle");
                }
                if (!gulf) {
                    this.noCurrents.push("gulf");
                }
            }
        },
    },

    mounted() {
        this.getWmsCapabilities();
    },
    watch: {
        "$store.state.selectedTime": function () {
            // Check if selected time is outside the time range of the layer displayed
            this.checkActiveLayerInRange();
        },
        "$store.state.selectedLayer": function () {
            // Check if selected time is outside the time range of the layer displayed
            this.checkActiveLayerInRange();
        },
    },
};
</script>

<style>

.warning-label-and-title{
    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
    background-color: #d75050;
    opacity:0.8;
    padding: 3px;
    border-radius: 7px;
    text-align: center;
    pointer-events: auto;
    cursor: pointer;
    margin-top: 2px;
}

.warning-label {
    display: inline-block;
}

.close-warning-no-data {
    width: 17px;
    pointer-events: auto;
    border-radius: 50%;
    /* background-color: white; */
    cursor: pointer;
    margin: 2px;
}

@media (max-width: 550px) {
    .warning-label-and-title {
        font-size: 10px;
    }
}

.fadeHeightNoDataPane-enter-active,
.fadeHeightNoDataPane-leave-active {
    transition: all 0.5s;
    max-height: 50px;
}

.fadeHeightNoDataPane-enter,
.fadeHeightNoDataPane-leave-to {
    opacity: 0;
    max-height: 0px;
}

.warning-text {
    display: inline-block;
}
.warning-hyperlink{
    color:white;
    text-decoration: underline;
}
</style>