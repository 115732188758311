import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import mapboxgl from "mapbox-gl";
import Layer from "ol/layer/Layer.js";
import Source from "ol/source/Source.js";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";
import { toLonLat } from "ol/proj";
import { roundMinutes } from "@/common/timeRelatedFunctions";
import { Group as LayerGroup, Vector as VectorLayer } from "ol/layer";
import { Layer as LayerCodes } from "@/constants";
import GeoJSON from "ol/format/GeoJSON";
import { getNearMeteoMaritimeStations } from "@/stationDataLayer/dd-meteo-maritime";
import VectorSource from "ol/source/Vector";

function getStle200TimeExtent() {
  const url = process.env.VUE_APP_GEOSERVER_URL + "geoserver/raster-harvester/wms?service=WMS&version=1.1.0&request=GetCapabilities";

  // Return the promise chain directly
  return fetch(url)
    .then((response) => response.text())
    .then((text) => {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(text, "text/xml");
      const parentLayer = xmlDoc.querySelector("Layer");

      // Select only the nested Layer elements
      const nestedLayers = parentLayer.querySelectorAll(":scope > Layer");

      // Use a standard for loop to allow early return
      for (let i = 0; i < nestedLayers.length; i++) {
        let layer = nestedLayers[i];
        // For each Layer, find the Name element
        let name = layer.querySelector('Name');
        if (name.textContent.trim() === "stle200_mosaic") {
          var extent = layer.querySelector('Extent[name="time"]');
          if (extent) {
            // Get the text content of the Extent element
            var extentText = extent.textContent;
            // Split the text content by comma to get individual date strings
            var dateStrings = extentText.split(',');
            // Extract the minimum and maximum dates
            var minDateString = dateStrings[0];
            var maxDateString = dateStrings[dateStrings.length - 1];

            // Convert date strings to Date objects
            var minDate = new Date(minDateString);
            var maxDate = new Date(maxDateString);

            return [minDate, maxDate];
          } else {
            return [];
          }
        }
      }
      // Return a default value if no matching layer is found
      return null;
    })
    .catch(error => {
      console.error("Error fetching time extent:", error);
      throw error; // Propagate the error
    });
}

function layerInit(center, gulfOffset, featureLayerCategories, store) {
  let wmsLayerSources = []
  let basemapLayers = {}
  let layers = []

  let satelliteSource = new BingMaps({
    key: "AvNf2K1e2y9htQc1-Pz0ODRxstgG4DgmevXmUsh0li-jmpiJP65ZhOPsx44PfBGu",
    imagerySet: "AerialWithLabelsOnDemand",
  });
  let satelliteBasemap = new TileLayer({
    source: satelliteSource,
    name: "basemap",
    basemapType: "satellite"
  });
  basemapLayers["satellite"] = satelliteBasemap
  // basemapLayers.push(satelliteBasemap)

  // add mapbox layer
  mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
  const mbMap = new mapboxgl.Map({
    style:
      `mapbox://styles/admin-ogsl/${process.env.VUE_APP_MAPBOX_BASEMAP_ID}?key=${process.env.VUE_APP_MAPBOX_KEY}`,
    attributionControl: false,
    boxZoom: false,
    center: center,
    container: "map",
    doubleClickZoom: false,
    dragPan: false,
    dragRotate: false,
    interactive: false,
    keyboard: false,
    pitchWithRotate: false,
    scrollZoom: false,
    touchZoomRotate: false,
  });

  let mapboxBasemap = new Layer({
    name: "basemap",
    basemapType: "mapbox  ",
    zIndex: 2,
    visible: true,
    render: function (frameState) {
      const canvas = mbMap.getCanvas();
      const viewState = frameState.viewState;

      // const visible = this.blackWhiteSource.getVisible();
      // canvas.style.display = visible ? "block" : "none";
      // canvas.style.position = "absolute";
      canvas.style.display = true;
      canvas.style.position = "absolute";
      canvas.style.left = 0;

      // const opacity = this.blackWhiteSource.getOpacity();
      // canvas.style.opacity = opacity;
      canvas.style.opacity = 1;

      // adjust view parameters in mapbox
      const rotation = viewState.rotation;
      mbMap.jumpTo({
        center: toLonLat(viewState.center),
        zoom: viewState.zoom - 1,
        bearing: (-rotation * 180) / Math.PI,
        animate: false,
      });

      // cancel the scheduled update & trigger synchronous redraw
      // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
      // NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
      if (mbMap._frame) {
        mbMap._frame.cancel();
        mbMap._frame = null;
      }
      mbMap._render();

      return canvas;
    },
    source: new Source({
      attributions: [
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>',
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      ],
    }),
  });

  // basemapLayers.push(satelliteBasemap)
  basemapLayers["mapbox"] = mapboxBasemap

  // layers.push({ "layer": basemap, "active": true})


  var dateNow = new Date();
  let isoSelectedDate = roundMinutes(dateNow).toISOString();

  // set styling depending on window.devicePixelRatio
  let currentStyle;
  let windStyle;
  let gustStyle;
  let gulfCurrentStyle;
  let shopCurrentsStyle;
  let stle200CurrentsStyle;
  let ciopsEastCurrentsStyle;
  if (window.devicePixelRatio > 2.3) {
    currentStyle = "shc_courants:courants_surface_smartphone";
    windStyle = "eccc_wind_predictions:eccc_winds_style_smartphone";
    gustStyle = "eccc_wind_predictions:eccc_winds_gust_style_smartphone";
    shopCurrentsStyle = "eccc_shop:mtl_tr_courants_smartphone";
    stle200CurrentsStyle = "raster-harvester:stle200_currents_smartphone"
    ciopsEastCurrentsStyle = "raster-harvester:ciops-east_currents_smartphone"
  } else {
    currentStyle = "shc_courants:courants_surface_laptop";
    windStyle = "eccc_wind_predictions:eccc_winds_style_laptop";
    gustStyle = "eccc_wind_predictions:eccc_winds_gust_style_laptop";
    shopCurrentsStyle = "eccc_shop:mtl_tr_courants_laptop";
    stle200CurrentsStyle = "raster-harvester:stle200_currents"
    ciopsEastCurrentsStyle = "raster-harvester:ciops-east_currents"
  }
  // Add STLE currents
  let currentSource = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/shc_courants/wms",
    params: {
      LAYERS: "shc_courants:stle_courants_coverage",
      TILED: true,
      time: isoSelectedDate,
      STYLES: currentStyle,
    },
    serverType: "geoserver",
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  });
  wmsLayerSources.push({ "name": "stle", "source": currentSource });

  var currentsWMS = new TileLayer({
    name: "stle_courants_coverage",
    source: currentSource,
    visible: false
  });

  // Add gulf currents
  let gulfSource = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/raster-harvester/wms",
    params: {
      LAYERS: "raster-harvester:ciops-east_mosaic",
      TILED: true,
      time: isoSelectedDate,
      STYLES: ciopsEastCurrentsStyle,
    },
    serverType: "geoserver",
    transition: 0,
    // tileLoadFunction: function (image, src) {
    //   const offsetX = gulfOffset[0];
    //   const offsetY = gulfOffset[1];
    //   const url = new URL(src);
    //   const params = url.searchParams;
    //   const oldParams = params.toString();
    //   const bbox = params.get("BBOX").split(",").map(Number);
    //   bbox[0] += offsetX;
    //   bbox[1] += offsetY;
    //   bbox[2] += offsetX;
    //   bbox[3] += offsetY;
    //   params.set("BBOX", bbox.toString());
    //   const newParams = params.toString();
    //   image.getImage().src = src.replace(oldParams, newParams);
    // },
  });
  wmsLayerSources.push({ "name": "gulf", "source": gulfSource });

  var currentsGulfWMS = new TileLayer({
    name: "gulf_courants_coverage",
    source: gulfSource,
  });

  // Add SHOP currents
  let shopSource = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/eccc_shop/wms",
    params: {
      LAYERS: "eccc_shop:currents_MTL_TR_coverage",
      TILED: true,
      STYLES: shopCurrentsStyle,
      time: isoSelectedDate,
    },
    serverType: "geoserver",
    transition: 0,
  });
  wmsLayerSources.push({ "name": "shop", "source": shopSource });

  var shopCurrentsWMS = new TileLayer({
    name: "shop_courants_coverage",
    source: shopSource,
  });

  // add STLE200 currents
  let stle200Source = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/raster-harvester/wms",
    params: {
      LAYERS: "raster-harvester:stle200_mosaic",
      TILED: true,
      STYLES: stle200CurrentsStyle,
      time: isoSelectedDate,
    },
    serverType: "geoserver",
    transition: 0,
  });
  wmsLayerSources.push({ "name": "stle200", "source": stle200Source });

  var stle200CurrentsWMS = new TileLayer({
    name: "stle200",
    source: stle200Source,
  });

  let wmsCurrentsLayer = new LayerGroup({
    name: LayerCodes.CURRENTS,
    layers: [currentsWMS, currentsGulfWMS, shopCurrentsWMS, stle200CurrentsWMS],
    type: "wms",
    zIndex: 1,
  });
  // this.$set(this.wmsLayers, "currents", wmsCurrentsLayer);
  // this.$store.state.map.addLayer(wmsCurrentsLayer);
  layers.push({ "layer": wmsCurrentsLayer, "active": true })

  // Add WINDS
  let windsSource = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/eccc_wind_predictions/wms",
    params: {
      LAYERS: "eccc_wind_predictions:eccc_wind_predictions",
      TILED: true,
      time: isoSelectedDate.split(".")[0] + "Z",
      STYLES: windStyle,
    },
    serverType: "geoserver",
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  });
  // this.$set(this.wmsSources, "winds", windsSource);
  wmsLayerSources.push({ "name": "winds", "source": windsSource });

  let wmsWindLayer = new TileLayer({
    zIndex: 2,
    name: LayerCodes.WINDS,
    source: windsSource,
    visible: false,
    type: "wms"
  });
  // this.$set(this.wmsLayers, "winds", wmsWindLayer);
  // this.$store.state.map.addLayer(wmsWindLayer);

  layers.push({ "layer": wmsWindLayer, "active": true })

  // Add GUSTS
  let gustsSource = new TileWMS({
    url: process.env.VUE_APP_GEOSERVER_URL + "geoserver/eccc_wind_predictions/wms",
    params: {
      LAYERS: "eccc_wind_predictions:eccc_wind_predictions",
      TILED: true,
      time: isoSelectedDate.split(".")[0] + "Z",
      STYLES: gustStyle,
    },
    serverType: "geoserver",
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  });
  // this.$set(this.wmsSources, "gusts", gustsSource);
  wmsLayerSources.push({ "name": "gusts", "source": gustsSource });

  let wmsGustLayer = new TileLayer({
    zIndex: 2,
    name: LayerCodes.GUSTS,
    source: gustsSource,
    visible: false,
    type: "wms"
  });
  // this.$set(this.wmsLayers, "gusts", wmsGustLayer);
  // this.$store.state.map.addLayer(wmsGustLayer);
  layers.push({ "layer": wmsGustLayer, "active": true, })

  // Add Isobars
  let isobarsSource = new TileWMS({
    url: "https://geo.weather.gc.ca/geomet/",
    params: {
      LAYERS: "HRDPS.CONTINENTAL_PN",
      TILED: true,
      time: isoSelectedDate.split(".")[0] + "Z",
    },
    serverType: "geoserver",
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  });
  // this.$set(this.wmsSources, "isobars", pressureSource);

  let wmsIsobarsLayer = new TileLayer({
    zIndex: 2,
    name: LayerCodes.ISOBARS,
    source: isobarsSource,
    visible: false,
    type: "wmsExtraLayers"
  });

  layers.push({ "layer": wmsIsobarsLayer, "active": true })


  // Add feature layers
  for (let category of featureLayerCategories) {
    let featureLayer = new VectorLayer({
      zIndex: 2,
      name: category,
      type: "featureLayers"
    });

    layers.push({ "layer": featureLayer, "active": true })
  }

  // add Météo Maritime layer
  var meteoMaritimeLayer = new VectorLayer({
    source: new VectorSource({
      format: new GeoJSON(),
      url: function () {
        return (
          process.env.VUE_APP_GEOSERVER_URL +
          "geoserver/eccc_meteo_maritime/ows?service=WFS" +
          "&version=1.0.0&request=GetFeature&typeName=eccc_meteo_maritime:meteo_maritime_regions" +
          "&maxFeatures=50&outputFormat=application/json"
        );
      },
    }),
    name: "meteoMaritimeRegions",
    opacity: 0,
  });

  // Get meteo maritime when the layer has finished loading
  meteoMaritimeLayer.getSource().on("change", function (evt) {
    var source = evt.target;
    if (source.getState() === "ready") {
      let stations = getNearMeteoMaritimeStations(
        store.state.selectedCoordinates,
        meteoMaritimeLayer
      );
      store.mutations.updateNearMeteoMaritimeStations(stations);
    }
  });

  layers.push({ "layer": meteoMaritimeLayer, "active": true })

  return [basemapLayers, layers, wmsLayerSources]

}

export { layerInit, getStle200TimeExtent };

