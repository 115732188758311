import fLocales from "../locales/fr.json"
import eLocales from "../locales/en.json"
import { toLonLat, fromLonLat } from "ol/proj";
import { Category } from "@/stationDataLayer/stationDataLayer.js";

const locales = { "f": fLocales, "e": eLocales }
const warningDict = { warning: ["warning", "avertissement"], watch: ["watch", "veille"], statement: ["advisory", "avis"] }
async function getDataFromLanguage(obOut, area, id, language) {
    let dataUrl = `${process.env.VUE_APP_METEO_MARITIME_URL}${area}/${id}_${language}.xml`
    obOut[language] = {
        "region": null, "emis": null,
        "coveragePeriod": null,
        "coveragePeriodWaves": null,
        "headerVent": null,
        "vent": null,
        "temperature": null,
        "headerConditions": null,
        "conditions": null,
        "headerVagues": null,
        "vagues": null,
        "longTerme": null,
        "longTermEmis": null,
        "warningType": null,
        "longTermPredictionsItems": [],
        "dataPresence": {
            wind: false,
            conditions: false,
            waves: false,
            warning: false,
        }
    }

    var x = new XMLHttpRequest();
    x.open("GET", dataUrl, true);
    x.onreadystatechange = function () {
        if (x.readyState == 4 && x.status == 200) {
            var doc = x.responseText;
            var parser = new DOMParser(),
                xmlDoc = parser.parseFromString(doc, "text/xml");

            obOut[language].region =
                xmlDoc.getElementsByTagName("area")[0].innerHTML;
            if (!(xmlDoc.getElementsByTagName("warnings").length === 0)) {
                var warningTag = xmlDoc.getElementsByTagName("warnings")[0];
                var events = [];
                for (
                    let i = 0;
                    i < warningTag.getElementsByTagName("event").length;
                    i++
                ) {
                    var warningTagInt = warningTag.getElementsByTagName("event")[i];
                    var warningStatus = warningTagInt.getAttribute("status");
                    if (["EN VIGUEUR", "IN EFFECT"].includes(warningStatus)) {
                        events.push(warningTag.getElementsByTagName("event")[i]);
                        obOut[language].dataPresence.warning = true;
                    }
                }
                obOut[language].warnings = []
                for (let event in events) {
                    obOut[language].warnings.push({ type: getObjectKey(events[event].getAttribute("type")), description: events[event].getAttribute("name") })
                }

            }
            var regularForecast =
                xmlDoc.getElementsByTagName("regularForecast")[0];
            if (regularForecast.childNodes[0] != undefined) {
                obOut[language].emis =
                    locales[language]["marine-forecast-date-issued"] +
                    regularForecast.getElementsByTagName("textSummary")[1].innerHTML;
                const timeStamp = regularForecast.getElementsByTagName("timeStamp")[1].innerHTML
                obOut[language].timeStamp = new Date(
                    parseInt(timeStamp.slice(0, 4)),       // Year
                    parseInt(timeStamp.slice(4, 6)) - 1,   // Month (0-based)
                    parseInt(timeStamp.slice(6, 8)),        // Day
                    parseInt(timeStamp.slice(8, 10))
                );

                obOut[language].coveragePeriod =
                    regularForecast.getElementsByTagName(
                        "periodOfCoverage"
                    )[0].innerHTML;

                var wind = regularForecast.getElementsByTagName("wind")[0];
                if (wind.childNodes[0] != undefined) {
                    obOut[language].dataPresence.wind = true;
                    obOut[language].vent = wind.innerHTML;
                }

                var temperature =
                    regularForecast.getElementsByTagName("airTemperature")[0];
                if (temperature.childNodes[0] != undefined) {
                    obOut[language].dataPresence.temperature = true;
                    obOut[language].temperature = temperature.innerHTML;
                }

                var conditions =
                    regularForecast.getElementsByTagName("weatherVisibility")[0];
                if (conditions.childNodes[0] != undefined) {
                    obOut[language].dataPresence.conditions = true;
                    obOut[language].conditions = conditions.innerHTML;
                }
            }
            var waveForecast = xmlDoc.getElementsByTagName("waveForecast")[0];
            if (waveForecast.childNodes[0] != undefined) {
                obOut[language].dataPresence.waves = true;
                var weatherConditionWavFor =
                    waveForecast.getElementsByTagName("weatherCondition")[0];
                var waves =
                    weatherConditionWavFor.getElementsByTagName("textSummary")[0];
                obOut[language].coveragePeriodWaves =
                    regularForecast.getElementsByTagName(
                        "periodOfCoverage"
                    )[0].innerHTML;
                if (waves) {
                    obOut[language].vagues = waves.innerHTML;
                }
            }

            var extendedForecast =
                xmlDoc.getElementsByTagName("extendedForecast")[0];
            if (extendedForecast.childNodes[0] != undefined) {
                var weatherConditionExtFor =
                    extendedForecast.getElementsByTagName("weatherCondition")[0];
                var forecastPeriod =
                    weatherConditionExtFor.getElementsByTagName("forecastPeriod");

                obOut[language].longTermPredictionsItems = [];
                for (
                    var prediction = 0;
                    prediction < forecastPeriod.length;
                    prediction++
                ) {
                    obOut[language].longTermPredictionsItems.push([
                        forecastPeriod[prediction].getAttribute("name"),
                        forecastPeriod[prediction].innerHTML,
                    ]);
                }
                obOut[language].longTermEmis =
                    extendedForecast.getElementsByTagName("textSummary")[1].innerHTML;
            }
        }
    };
    x.send(null);
}
async function getLatestData(area, id) {
    let out = {}
    await getDataFromLanguage(out, area, id, "f")
    await getDataFromLanguage(out, area, id, "e")

    return out
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

function getNearMeteoMaritimeStations(selectedCoordinates, meteoMaritimeLayer) {
    if (!selectedCoordinates) {
        return;
    }
    var coordLon = selectedCoordinates.longitude;
    var coordLat = selectedCoordinates.latitude;
    var features = meteoMaritimeLayer.getSource().getFeatures();
    let polygonDistances = []
    features.forEach(function (feature) {
        let distance
        var polygonGeometry = feature.getGeometry();
        var coordsClicked = fromLonLat([coordLon, coordLat]);
        var nearestCoords = polygonGeometry.getClosestPoint(coordsClicked);
        if (polygonGeometry.intersectsCoordinate(coordsClicked)) {
            distance = 0
        }
        else {
            var nearestCoordsLonLat = toLonLat(nearestCoords);
            distance = getDistanceFromLatLonInKm(
                coordLat,
                coordLon,
                nearestCoordsLonLat[1],
                nearestCoordsLonLat[0]
            );
        }
        if (distance < 30) {
            polygonDistances.push({
                "feature": feature,
                "distance": distance,
                "category": Category["MeteoMaritime"],
                "name": feature.getProperties().name,
                "id": feature.getProperties().id,
                "longitude": null,
                "latitude": null,
            })
        }


    });
    polygonDistances.sort(compare);
    if (polygonDistances.length === 0) {
        return null
    }
    return polygonDistances
}
function compare(a, b) {
    if (a.distance < b.distance) {
        return -1;
    }
    if (a.distance > b.distance) {
        return 1;
    }
    return 0;
}
function getObjectKey(string) {

    for (const key in warningDict) {
        if (warningDict[key].includes(string)) {
            return key;
        }
    }

    return null; // Return null if the string doesn't match any key
}

export { getLatestData, getNearMeteoMaritimeStations };