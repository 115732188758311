<template>
    <div>
        <b-form-checkbox class="sub-layer-switch" v-model="layerSelected" switch size="sm">
            {{ $t(`${text}`) }}
        </b-form-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        layerCode: String,
    },

    data() {
        return { layerSelected: false };
    },
    watch: {
        layerSelected: function (value) {
            if (value) {
                this.$store.mutations.addSelectedExtraLayers(this.layerCode);
            } else {
                this.$store.mutations.removeSelectedExtraLayers(this.layerCode);
            }
        },
    },
};
</script>


<style scoped>
.sub-layer-switch {
    font-size: 12px;
    max-height: 40px;
    display: inline;
    color: white;
    pointer-events: auto;
    cursor: pointer;
}

.sub-layer-text-legend-icon {
    /* width: 0px; */
    color: white;
    display: inline;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 5px 3px 5px 3px;
    border-radius: 4px;
    pointer-events: auto;
    cursor: pointer;
}

</style>