export default {
  props: {
    station: Object,
    showAllVariables: { type: Boolean, default: true },
  },
  data() {
    return {
      meteoEnum: {
        present_weather: ["Condition", ""],
        horizontal_visibility: [
          this.$t("atmospheric-variable-visibility"),
          " km",
        ],
        air_temperature: [this.$t("atmospheric-variable-temperature"), " °C"],
        relative_humidity: [this.$t("atmospheric-variable-humidity"), " %"],
        wind_speed: [this.$t("atmospheric-variable-wind"), " "+this.$t("knots")],
        wind_direction: ["Direction", ""],
        wind_gust_speed: [this.$t("atmospheric-variable-gusts"), " "+this.$t("knots")],
      },
    };
  },

  methods: {
    changeWindToKnots: function(data) {
      for (let i = 0; i < data.length; i++) {
        if (["wind_speed", "wind_gust_speed"].includes(data[i][0])) {
          let knotSpeed = Math.round(parseFloat(data[i][1]) / 1.852);
          data[i][1] = knotSpeed.toString();
        }
      }
    },
    buildTable: function(data) {
      let obs = {};
      //return Object.entries(this.station.data);
      for (const [key, value] of data) {
        if (value.length > 0) {
          obs[this.meteoEnum[key][0]] = value + this.meteoEnum[key][1];
        } else {
        //   obs[this.meteoEnum[key][0]] = this.$t(
        //     "atmospheric-variable-not-available"
        //   );
        obs[this.meteoEnum[key][0]] =  "-"
        }
      }
      return Object.entries(obs);
    },
  },
};
