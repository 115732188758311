<template>
  <div class="meteoInfo pane-container-boxes" v-if="translatedData != null">
    <div class="infoSectionTitle addFlex">
      <div class="flexItemTitle">{{ $t("marine-forcast-title") }}</div>
    </div>
    <div class="meteo-maritime-prev-container" v-if="validDate">
      <div class="prevItems" id="short-prev-items">
        <div id="region">{{ translatedData.region }}</div>
        <div id="emis">{{ translatedData.emis }}</div>
        <!-- <button
                    v-show="translatedData.dataPresence.warning"
                    class="button-warning"
                    @click="showHideWarning()"
                >
                    {{ $t("warning") }}
                </button>
                <div v-show="warningDetailsState">
                    {{ translatedData.warningType }} {{ $t("warning-text") }}
                    <a :href="meteoMaritimeUrl" target="_blank">{{
                        $t("marine-forecast-canada")
                    }}</a
                    >.
                </div> -->
        <div
          v-show="
            translatedData.warnings != undefined && translatedData.warnings.length > 0
          "
        >
          <WarningButtons
            class="meteo-maritime-warnings"
            :warnings="translatedData.warnings"
            :url="meteoMaritimeUrl"
          ></WarningButtons>
        </div>

        <div v-show="translatedData.dataPresence.wind">
          <div class="header">
            {{ $t("wind") }}
            <span class="coverage-period"> - {{ translatedData.coveragePeriod }}</span>
          </div>
          <div id="vent">{{ translatedData.vent }}</div>
        </div>

        <div v-show="translatedData.dataPresence.temperature">
          <div class="header">
            {{ $t("temperature") }}
            <span class="coverage-period"> - {{ translatedData.coveragePeriod }}</span>
          </div>
          <div id="temperature">{{ translatedData.temperature }}</div>
        </div>

        <div v-show="translatedData.dataPresence.waves">
          <div class="header">
            {{ $t("waves") }}
            <span class="coverage-period">
              - {{ translatedData.coveragePeriodWaves }}</span
            >
          </div>
          <div id="vagues">{{ translatedData.vagues }}</div>
        </div>
        <div v-show="translatedData.dataPresence.conditions">
          <div class="header">
            {{ $t("conditions-visibility") }}
            <span class="coverage-period"> - {{ translatedData.coveragePeriod }}</span>
          </div>
          <div id="conditions">{{ translatedData.conditions }}</div>
        </div>
      </div>
      <div class="prevItems long-prev-items">
        <div id="long-term-title">{{ $t("Long-term-forecast") }}</div>
        <b-table
          striped
          hover
          :items="translatedData.longTermPredictionsItems"
          thead-class="d-none"
          class="table-widget"
        ></b-table>
        <div id="emisExtent">
          {{ $t("marine-forecast-long-term-date-issued") }}
          {{ translatedData.longTermEmis }}
        </div>
      </div>
    </div>
    <div v-else class="no-marine-weather">{{ $t("no-marine-weather") }}</div>
  </div>
</template>

<script>
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import WarningButtons from "/src/components/buttomPane/layouts/WarningButtons.vue";
export default {
  props: { regionData: Object },
  components: { WarningButtons },
  data() {
    return {
      langConversion: { fr: "f", en: "e" },
      warningDetailsState: false,
      translatedData: null,
      yesterday6PM: new Date(
        new Date(new Date().setDate(new Date().getDate() - 1)).setHours(13, 0, 0, 0)
      ),
      dataDate: null,
    };
  },
  computed: {
    meteoMaritimeUrl: function () {
      return this.$t("marine-forecast-canada-Url");
    },
    validDate: function () {
      if (this.dataDate === null || this.dataDate === undefined) return false;
      if (!this.yesterday6PM) return false;
      return this.dataDate > this.yesterday6PM;
    },
  },
  methods: {
    showHideWarning: function () {
      this.warningDetailsState = !this.warningDetailsState;
    },
    updateTranslatedData: function () {
      if (this.regionData != null) {
        this.translatedData = this.regionData[this.langConversion[this.$i18n.locale]];
      }
    },
  },
  mounted() {
    this.updateTranslatedData();
  },
  watch: {
    regionData: function (newValue) {
      if (newValue === null) {
        this.translatedData = null;
      } else {
        this.translatedData = newValue[this.langConversion[this.$i18n.locale]];
      }
    },
    "$i18n.locale": function () {
      this.updateTranslatedData();
    },
    translatedData: {
      handler: function (val, oldVal) {
        this.dataDate = val.timeStamp;
      },
      deep: true,
    },
  },
};
</script>

<style>
#long-term-title {
  border-collapse: collapse;
  font-size: 1.2em;
  font-family: sans-serif;
  min-width: 225px;
  padding: 5px;

  background-color: #404041;
  color: #ffffff;
}

.long-prev-items {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.header {
  color: #404041;
  font-size: 1em;
  font-weight: bold;
  padding: 12px 10px 0px 10px;
}

.coverage-period {
  font-size: 00.8em;
  font-style: italic;
}

.button-warning {
  background-color: #f44336;
  color: white;
  padding: 10px 10px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.meteo-maritime-prev-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

@media (max-width: 1004px) and (min-width: 500px) {
  .meteoInfo {
    width: 500px;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 1000px) {
  .addFlex {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .flexItemTitle {
    max-width: 500px;
  }
}

.flexMeteoInfo {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.prevItems {
  max-width: 500px;
}

#short-prev-items {
  position: relative;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 25px;
  min-width: 250px;
}

#textWarning {
  font-weight: bold;
  color: red;
}

#region {
  text-align: left;
  padding: 12px 15px 0px 15px;
  font-size: 1.2em;
  font-weight: bold;
}

#periode {
  text-align: right;
  font-size: 0.9em;
  font-style: italic;
  padding: 10px 10px 0px 10px;
  max-width: 300px;
}

#vent,
#vagues,
#temperature,
#conditions {
  padding: 0px 10px 10px 10px;
}

#emis {
  font-size: 0.8em;
  font-style: italic;
  padding: 0px 15px;
}

#emisExtent {
  font-size: 0.8em;
  font-style: italic;
  padding: 0px 15px;
}

.no-marine-forecasts-warning {
  padding: 5px;
  font-style: italic;
  font-size: 12px;
}
.meteo-maritime-warnings {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.no-marine-weather {
  margin: auto;
  padding: 10px 20px;
}
</style>
