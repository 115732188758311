<template>
  <div>
    <div
      class="my-location-control ol-unselectable ol-control"
      :title="$t('yourLocation')"
    >
      <button @click="handleMyLocationClick()">
        <img src="@/assets/my-location-svgrepo-com.svg" />
      </button>
    </div>
    <WmsGetFeatureInfo
      :wmsSources="wmsSources"
      :stle200Status="stle200Status"
    ></WmsGetFeatureInfo>
    <TheItineraryMapPlugin
      v-if="mounted"
      @layersToAdd="addItineraryLayers"
    ></TheItineraryMapPlugin>
    <TheOpacitySlider
      @opacity-value="changeOpacity"
      @widgetOpened="selectWidgetOpened"
      :widgetSelected="widgetSelected"
    ></TheOpacitySlider>
    <TheMapToggle
      @basemapSelected="changeBasemap"
      @widgetOpened="selectWidgetOpened"
      @vectorLayerSelectedChanged="selectVectorLayers"
      :widgetSelected="widgetSelected"
    ></TheMapToggle>
  </div>
</template>

<script>
import WmsGetFeatureInfo from "/src/components/map/mapPlugins/WmsGetFeatureInfo.vue";
import TheItineraryMapPlugin from "/src/components/map/mapPlugins/TheItineraryMapPlugin.vue";
import TheOpacitySlider from "@/components/map/leftButtons/TheOpacitySlider.vue";
import TheMapToggle from "@/components/map/leftButtons/TheMapToggle.vue";
import {
  layerInit,
  getStle200TimeExtent,
} from "@/components/map/mapPlugins/dataLayerInit.js";
import { Layer as LayerCodes } from "@/constants";
import { fromLonLat, toLonLat } from "ol/proj";
import { getHoursDifference } from "@/common/timeRelatedFunctions";
import Map from "ol/Map";
import View from "ol/View";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { unByKey } from "ol/Observable";
import { easeOut } from "ol/easing";
import { getVectorContext } from "ol/render";
import { Circle as CircleStyle, Stroke, Style, Icon, Text, Fill } from "ol/style";
import { roundMinutes } from "@/common/timeRelatedFunctions";
const StationIcon = {
  Atmospheric: require("@/assets/atmospheric_station.svg"),
  AtmosphericSelected: require("@/assets/atmospheric_station_selected.svg"),
  AtmosphericGrey: require("@/assets/atmospheric_station_grey.svg"),
  Marine: require("@/assets/marine_station.svg"),
  MarineSelected: require("@/assets/marine_station_selected.svg"),
  AtmosPrevision: require("@/assets/atmos_prevision_stations_icon.svg"),
  AtmosPrevisionSelected: require("@/assets/atmos_prevision_stations_icon_selected.svg"),
};

import { goToMyLocation } from "@/common/state-aware-functions";
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
export default {
  components: {
    WmsGetFeatureInfo,
    TheItineraryMapPlugin,
    TheOpacitySlider,
    TheMapToggle,
  },
  data() {
    let category = [];
    let layerVisibleState = {};
    for (let cat in stationDataLayer.Category) {
      category.push(stationDataLayer.Category[cat]);
      this.$set(layerVisibleState, stationDataLayer.Category[cat], true);
    }

    return {
      category,
      mounted: false,
      selectedMarkerSource: new VectorSource({
        features: [],
      }),
      selectedStationMarkerSource: new VectorSource({
        features: [],
      }),
      hoverStationMarkerSource: new VectorSource({
        features: [],
      }),
      selected: null,
      wmsSources: {},
      stle200TimeExtent: [],
      isoSelectedDate: null,
      needToRetry: false,
      satelliteSource: null,
      blackWhiteSource: null,
      depthLayer: null,
      widgetSelected: null,
      layerVisibleState,
      basemapLayers: {},
    };
  },
  computed: {
    wmsLayers() {
      let wmsLayers = [];
      this.$store.state.map.getLayers().forEach(function (layerFound) {
        if (layerFound.get("type") === "wms") {
          wmsLayers.push(layerFound);
        }
      });
      return wmsLayers;
    },
    wmsExtraLayers() {
      let wmsExtraLayers = [];
      this.$store.state.map.getLayers().forEach(function (layerFound) {
        if (layerFound.get("type") === "wmsExtraLayers") {
          wmsExtraLayers.push(layerFound);
        }
      });
      return wmsExtraLayers;
    },
    stle200Status() {
      // Compute resolved data based on the promiseData
      let status = false;
      if (this.stle200TimeExtent.length > 0) {
        if (
          this.$store.state.selectedTime > this.stle200TimeExtent[0] &&
          this.$store.state.selectedTime < this.stle200TimeExtent[1]
        ) {
          status = true;
        }
      }
      this.$store.mutations.updateStle200Status(status)
      return status;
    },
  },
  methods: {
    handleMyLocationClick() {
      goToMyLocation();
    },
    moveMarker(coordinates) {
      this.selectedMarkerSource.clear();

      if (coordinates == null) return;

      const selectedMarker = new Feature({
        geometry: new Point(fromLonLat([coordinates.longitude, coordinates.latitude])),
      });
      selectedMarker.setStyle(
        new Style({
          image: new Icon({
            scale: [1.2, 1.2],
            src: require("@/assets/map_pin_select.svg"),
            crossOrigin: "anonymous",
          }),
        })
      );
      this.selectedMarkerSource.addFeatures([selectedMarker]);

      return selectedMarker;
    },
    updateSelectedStationIcon(event, coordinates, nameCategory, nameFeature) {
      let source = null;
      let icon = null;
      let imageSrc = null;
      if (event === "click") {
        source = this.selectedStationMarkerSource;
        icon = nameCategory + "Selected";
        imageSrc = StationIcon[icon];
      } else if (event === "hover") {
        source = this.hoverStationMarkerSource;
        icon = nameCategory;

        // Si on hover au dessus de station atmosphérique mais que ces stations ne sont plus sélectionnable, on grey le hover
        if (!this.category.includes(nameCategory)) {
          imageSrc = StationIcon["AtmosphericGrey"];
        } else {
          imageSrc = StationIcon[icon];
        }
      }

      source.clear();

      if (coordinates == null) return;

      const selectedStationMarker = new Feature({
        geometry: new Point(fromLonLat([coordinates.longitude, coordinates.latitude])),
      });
      selectedStationMarker.set("name", nameFeature);
      selectedStationMarker.setStyle(
        new Style({
          image: new Icon({
            scale: [1.2, 1.2],
            src: imageSrc,
            crossOrigin: "anonymous",
          }),
          text: new Text({
            font: "12px Calibri,sans-serif",
            fill: new Fill({ color: "#000" }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
            offsetY: 25,

            text: selectedStationMarker.get("name"),
          }),
        })
      );
      source.addFeatures([selectedStationMarker]);

      return selectedStationMarker;
    },

    flash(coordinates, duration = 1000) {
      const _this = this;
      const start = Date.now();
      const markerLayer = this.$store.state.map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "selectedMarker");
      const listenerKey = markerLayer.on("postrender", animate);
      // this.selectedMarkerSource.addFeatures([invisibleFeature]);
      const flashGeom = _this.moveMarker(coordinates).getGeometry().clone();

      function animate(event) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= duration) {
          unByKey(listenerKey);
          return;
        }
        const vectorContext = getVectorContext(event);
        const elapsedRatio = elapsed / duration;
        // radius will be 5 at start and 30 at end.
        const zoomParam = (_this.$store.state.map.getView().getZoom() / 7) ** 6;
        const radius = (easeOut(elapsedRatio) * 30 + 5) * zoomParam;
        const opacity = easeOut(1 - elapsedRatio);

        const style = new Style({
          image: new CircleStyle({
            radius: radius,
            fill: new Fill({ color: [239, 66, 0, 0.2 * opacity] }),
            stroke: new Stroke({
              color: "rgba(239,66,0, " + opacity + ")",
              width: 0.25 + opacity,
            }),
          }),
        });

        vectorContext.setStyle(style);
        vectorContext.drawGeometry(flashGeom);
        // tell OpenLayers to continue postrender animation
        _this.$store.state.map.render();
      }
    },

    updateStationsMarkers: function (layerName, stations) {
      let layer = null;
      this.$store.state.map.getLayers().forEach(function (layerFound) {
        if (layerFound.get("name") === layerName) {
          layer = layerFound;
        }
      });

      // Clear layer first
      if (layer.getSource() != null) {
        layer.getSource().clear();
      }

      // Then add features
      const features = [];
      for (const station of stations) {
        const coordinates = fromLonLat([station.longitude, station.latitude]);
        const feature = new Feature({
          geometry: new Point(coordinates),
          size: 10,
        });
        feature.setProperties({
          id: station.id,
          officialName: station.name,
          category: station.category,
        });
        feature.setStyle(
          new Style({
            image: new Icon({
              scale: [1, 1],
              src:
                layerName === stationDataLayer.Category.Marine
                  ? StationIcon.Marine
                  : layerName === stationDataLayer.Category.AtmosPrevision
                  ? StationIcon.AtmosPrevision
                  : this.category.includes("Atmospheric")
                  ? StationIcon.Atmospheric
                  : StationIcon.AtmosphericGrey,
              crossOrigin: "anonymous",
            }),
          })
        );
        features.push(feature);
      }
      if (features.length != 0) {
        layer.setSource(
          new VectorSource({
            features: features,
          })
        );
      }
    },
    checkForQuebecRegionClick(coordinateClicked) {
      let qcLat = [46.555649, 47.199604];
      let qcLon = [-71.884089, -70.58674];
      if (
        coordinateClicked[0] > qcLon[0] &&
        coordinateClicked[0] < qcLon[1] &&
        coordinateClicked[1] > qcLat[0] &&
        coordinateClicked[1] < qcLat[1]
      ) {
        this.$store.mutations.updateQuebecCoordinatesSelected(true);
      } else {
        this.$store.mutations.updateQuebecCoordinatesSelected(false);
      }
    },
    checkForMtlRegionClick(coordinateClicked) {
      let qcLat = [45.324, 46.329];
      let qcLon = [-74.575, -72.491];
      if (
        coordinateClicked[0] > qcLon[0] &&
        coordinateClicked[0] < qcLon[1] &&
        coordinateClicked[1] > qcLat[0] &&
        coordinateClicked[1] < qcLat[1]
      ) {
        this.$store.mutations.updateMtlCoordinatesSelected(true);
      } else {
        this.$store.mutations.updateMtlCoordinatesSelected(false);
      }
    },
    checkForLayer(evt) {
      let _this = this;
      _this.$store.state.map.forEachFeatureAtPixel(evt.pixel, function (f, layer) {
        if (layer === null) {
          return;
        }
        if (!_this.category.includes(layer.get("name"))) return;

        _this.selected = f;
        return true;
      });
    },

    addClickEvent(e) {
      let _this = this;
      _this.selected = null;
      if (_this.$store.state.mapClickEvent === "location") {
        _this.checkForLayer(e);
        if (_this.selected) {
          if (_this.category.includes(_this.selected.getProperties().category)) {
            const stationCoordinates = toLonLat(
              _this.selected.getGeometry().getCoordinates()
            );

            _this.$store.mutations.updateSelectedStation(
              _this.selected.getProperties().id,
              stationCoordinates[1],
              stationCoordinates[0],
              _this.selected.getProperties().category,
              _this.selected.getProperties().officialName
            );
          }
        } else {
          const coordinate = toLonLat(e.coordinate);
          _this.$store.mutations.updateSelectedCoordinates(coordinate[1], coordinate[0]);
          _this.$store.mutations.unselectStation();
          _this.selectedStationMarkerSource.clear();
          _this.checkForQuebecRegionClick(coordinate);
          _this.checkForMtlRegionClick(coordinate);
        }
      }
    },
    addItineraryLayers(layers) {
      for (const layer of layers) {
        this.$store.state.map.addLayer(layer);
      }
    },
    changeOpacity(value) {
      for (const layer in this.wmsLayers) {
        this.wmsLayers[layer].setOpacity(value / 100);
      }
    },
    changeBasemap(value) {
      const basemap = this.$store.state.map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "basemap");
      this.$store.state.map.removeLayer(basemap);
      this.$store.state.map.addLayer(this.basemapLayers[value]);
    },
    selectWidgetOpened(value) {
      this.widgetSelected = value;
    },
    selectVectorLayers(layers) {
      this.layerVisibleState = layers;
      if (Object.keys(this.$store.state.map).length > 0) {
        for (let layerName in layers) {
          let lyr = this.$store.state.map
            .getLayers()
            .getArray()
            .find((layer) => layer.get("name") === layerName);

          lyr.setVisible(layers[layerName]);
        }
      }
    },
    getLayerByName(name) {
      return this.$store.state.map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === name);
    },
    stleLayerDisplayer() {
      let stle200Layer = this.getCurrentsLayer("stle200");
      let stle400Layer = this.getCurrentsLayer("stle_courants_coverage");
      if (this.stle200Status) {
        stle200Layer.setVisible(true);
        stle400Layer.setVisible(false);
      } else {
        stle200Layer.setVisible(false);
        stle400Layer.setVisible(true);
      }
    },
    getCurrentsLayer(name) {
      return this.getLayerByName(LayerCodes.CURRENTS)
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === name);
    },
  },
  mounted() {
    var _this = this;
    const center = [-71, 47];

    let upperLeftCorner = fromLonLat([-95.901, 62.568]);
    let lowerRightCorner = fromLonLat([-47.973, 39.075]);
    var view = new View({
      center: fromLonLat(center),
      zoom: 9,
      extent: [
        upperLeftCorner[0],
        lowerRightCorner[1],
        lowerRightCorner[0],
        upperLeftCorner[1],
      ],
    });

    const offsetX = 3000;
    const offsetY = 9000;
    this.$store.mutations.updateGulfCurrentsOffsets([offsetX, offsetY]);

    getStle200TimeExtent().then((result) => {
      this.stle200TimeExtent = result;
    });
    let returns = layerInit(center, [offsetX, offsetY], this.category, this.$store);

    this.$store.state.map = new Map({
      layers: [returns[0].mapbox],
      target: "map",
      view: view,
    });

    this.basemapLayers = returns[0];

    for (let layer of returns[1]) {
      if (layer.active) {
        this.$store.state.map.addLayer(layer.layer);
      }
    }
    for (let wmsSource of returns[2]) {
      this.$set(this.wmsSources, wmsSource.name, wmsSource.source);
    }

    this.$store.state.map.addLayer(
      new VectorLayer({
        name: "selectedMarker",
        source: this.selectedMarkerSource,
        zIndex: 10000,
        opacity: 1,
      })
    );
    this.$store.state.map.addLayer(
      new VectorLayer({
        name: "hoverStationMarker",
        source: this.hoverStationMarkerSource,
        zIndex: 10000,
        opacity: 1,
      })
    );
    this.$store.state.map.addLayer(
      new VectorLayer({
        name: "selectedStationMarker",
        source: this.selectedStationMarkerSource,
        zIndex: 10000,
        opacity: 1,
      })
    );

    this.$store.state.map.on("singleclick", this.addClickEvent);
    this.$store.state.map.on("pointermove", function (e) {
      _this.selected = null;
      _this.checkForLayer(e);
      _this.hoverStationMarkerSource.clear();
      if (_this.selected) {
        if (_this.category.includes(_this.selected.getProperties().category)) {
          _this.$store.state.map.getTargetElement().style.cursor = "pointer";
        }

        let arrCoordinates = toLonLat(_this.selected.getGeometry().getCoordinates());

        let coordinates = {
          longitude: arrCoordinates[0],
          latitude: arrCoordinates[1],
        };

        _this.updateSelectedStationIcon(
          "hover",
          coordinates,
          _this.selected.getProperties().category,
          _this.selected.getProperties().officialName
        );
      } else {
        this.getTargetElement().style.cursor = "crosshair";
      }
    });

    document.getElementById("map").style.cursor = "crosshair";

    this.mounted = true;
  },

  watch: {
    "$store.state.nearStations": function (newNearStations) {
      this.updateStationsMarkers(
        stationDataLayer.Category.Marine,
        newNearStations.filter((x) => x.category === stationDataLayer.Category.Marine)
      );
      this.updateStationsMarkers(
        stationDataLayer.Category.Atmospheric,
        newNearStations.filter(
          (x) => x.category === stationDataLayer.Category.Atmospheric
        )
      );
      this.updateStationsMarkers(
        stationDataLayer.Category.AtmosPrevision,
        newNearStations.filter(
          (x) => x.category === stationDataLayer.Category.AtmosPrevision
        )
      );
    },
    "$store.state.selectedCoordinates": function (newValue) {
      if (!newValue) return;
      if (this.$store.state.mapClickEvent === "location") {
        this.flash(newValue);

        // center map somewhere below de selected coordinates
        const map = this.$store.state.map;
        const view = map.getView();
        const mapSize = map.getSize();

        const oldCenter = view.getCenter();

        setTimeout(() => {
          view.centerOn(
            fromLonLat([newValue.longitude, newValue.latitude]),
            map.getSize(),
            [mapSize[0] / 2, mapSize[1] * 0.18]
          );
          const newCenter = view.getCenter();
          view.setCenter(oldCenter);
          view.animate({ center: newCenter, duration: 1000 });
        });
      }
    },
    "$store.state.selectedStation": function (newValue) {
      if (!newValue) return;
      if (newValue.coordinates === null) return;
      this.updateSelectedStationIcon(
        "click",
        newValue.coordinates,
        newValue.category,
        newValue.name
      );
    },
    "$store.state.selectedLayer": function (newSelectedLayer) {
      for (const layer of this.wmsLayers) {
        if (layer.get("name") === newSelectedLayer) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    },
    "$store.state.selectedExtraLayers": function (extraLayers) {
      for (const layer of this.wmsExtraLayers) {
        if (extraLayers.includes(layer.get("name"))) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    },

    "$store.state.selectedTime": function (newValue) {
      let _this = this;
      this.isoSelectedDate = roundMinutes(newValue).toISOString();
      this.stleLayerDisplayer();
      for (const layer of this.wmsLayers) {
        if (layer.get("name") === LayerCodes.CURRENTS) {
          layer.getLayers().forEach(function (currentLayer) {
            currentLayer.getSource().updateParams({
              // LAYERS: "shc_courants:" + currentLayer.get("name"),
              // TILED: true,
              time: _this.isoSelectedDate,
            });
          });
        } else {
          layer.getSource().updateParams({
            // LAYERS: "eccc_wind_predictions:eccc_wind_predictions",
            // TILED: true,
            time: _this.isoSelectedDate.split(".")[0] + "Z",
          });
        }
      }
      for (const layer in this.wmsExtraLayers) {
        this.wmsExtraLayers[layer].getSource().updateParams({
          time: _this.isoSelectedDate.split(".")[0] + "Z",
        });
      }

      function loopThroughAtmosFeatures(src) {
        _this.$store.state.map.getLayers().forEach(function (layerFound) {
          if (layerFound.get("name") === "atmosphericStations") {
            let source = layerFound.getSource();
            if (source != null) {
              source.forEachFeature(function (feature) {
                feature.setStyle(
                  new Style({
                    image: new Icon({
                      scale: [1, 1],
                      src: src,
                      crossOrigin: "anonymous",
                    }),
                  })
                );
              });
            }
          }
        });
      }
      // Si le temps cliqué est trop dans le futur, on doit griser les stations observations
      if (getHoursDifference(newValue) >= 3) {
        this.category = ["Marine", "AtmosPrevision"];
        loopThroughAtmosFeatures(StationIcon.AtmosphericGrey);
      } else {
        this.category = ["Atmospheric", "Marine", "AtmosPrevision"];
        loopThroughAtmosFeatures(StationIcon.Atmospheric);
      }
    },
    "$store.state.selectedVectorLayers": {
      handler(newValue) {
        this.selectVectorLayers(newValue);
      },
      deep: true,
    },
    stle200TimeExtent: {
      handler(newVal, oldVal) {
        this.stleLayerDisplayer();
      },
      deep: true,
    },
  },
};
</script>

<style>
.my-location-control {
  z-index: 1;
  top: 155px;
  left: 0.5em;
}

.ol-zoom {
  top: 100px !important;
}

@media (max-width: 550px) {
  .my-location-control {
    z-index: 1;
    left: 0.5em;
  }
}
</style>
