<template>
    <div class="opacity-widget">
        <div
            class="opacity-icon-container"
            :title="$t('opacityTitle')"
        >
            <img
                class="opacity-icon"
                src="@/assets/opacity-icon.svg"
                @click="opacityIconClicked = !opacityIconClicked"
            />
        </div>
        <div class="opacity-widget-envelop" v-if="opacityIconClicked">
            <div class="opacity-slider-container">
                <div class="opacity-title">
                    <div>{{ $t("opacityTitle") }}</div>
                </div>
                <div class="slider-value">
                    <div class="opacity-slider-direct-container">
                        <input
                            class="opacity-slider"
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            v-model="value"
                        />
                    </div>
                    <div class="value">{{ value }}%</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["widgetSelected"],
    data() {
        return {
            value: 100,
            opacityIconClicked: false,
            widgetName: "opacitySlider",
        };
    },
    watch: {
        value: function () {
            this.$emit("opacity-value", this.value);
        },
        opacityIconClicked: function (value) {
            if (value === true) {
                this.$emit("widgetOpened", this.widgetName);
            }
        },
        widgetSelected: function (value) {
            if (value != this.widgetName) {
                this.opacityIconClicked = false;
            }
        },
    },
};
</script>

<style scoped>
.opacity-slider-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(0, 60, 136, 0.6);
    font-weight: bold;
    border-radius: 7px;
    height: 100%;
}
.opacity-widget {
    position: absolute;
    z-index: 1;
    top: 220px;
    left: 0.5em;
    color: white;
}
.opacity-icon-container {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    width: 28px;
    height: 28px;
}
.opacity-icon {
    width: 22px;
    height: 22px;
    padding: 2px;
    background-color: rgba(0, 60, 136, 0.6);
    border-radius: 3px;
    cursor: pointer;
}
.opacity-icon-container:hover {
    background-color: rgba(255, 255, 255, 0.6);
}
.opacity-icon:hover {
    background-color: rgba(0, 60, 136, 0.8);
}
.opacity-widget-envelop {
    position: absolute;
    bottom: 0px;
    left: 2em;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 3px;
    border-radius: 3px;
}
.opacity-slider {
    position: relative;
    -webkit-appearance: none; /* Override default CSS styles */
    top: 58px;
    right: -10px;
    width: 70px;
    height: 5px;
    background: #d3d3d3;
    transform: rotate(270deg);
    transform-origin: top left;
    -moz-transform: rotate(270deg);
}
.opacity-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 7px;
    background: #00adef;
    cursor: pointer;
}

.opacity-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #00adef;
    cursor: pointer;
}
/* input[type="range"][orient="vertical"] {
    writing-mode: bt-lr; /* IE */
/*     -webkit-appearance: slider-vertical; */
/* }  */
.slider-value {
    display: flex;
    flex-wrap: wrap;
    width: 30px;
}
.opacity-slider-direct-container {
    margin: 3px;
    height: 70px;
    width: 20px;
}
.value {
    text-align: center;
    width: 30px;
    font-size: 12px;
}
.opacity-title {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.opacity-title > div {
    color: #fff;
    font-size: 13px;
    transform: rotate(-90deg);
    white-space: nowrap;
}

@media (max-width: 575px) {
    .opacity-slider-container {
        top: 190px;
        left: 5px;
        /* width: 50px; */
        /* height: 90px; */
    }
    .opacity-slider {
        width: 60px;
        top: 50px;
        right: -7px;
    }
    .slider-value {
        width: 25px;
    }
    .value {
        width: 25px;
        font-size: 9px;
    }
    .opacity-title {
        width: 15px;
    }
    .opacity-title > div {
        font-size: 11px;
    }
    .opacity-slider-direct-container {
        height: 60px;
    }
}
</style>