<template>
  <div>
    <div class="infoSectionTitle">
      {{ $t("marinestation-details-waterlevel-section-title") }}
    </div>
    <div class="waterLevelContent">
      <div class="waterLevelTableContainer">
        <div class="waterLevelTableDate">{{ dateHoveredString }}</div>
        <b-table sticky-header="150px" striped hover small :items="itemTable" thead-class="d-none" :fields="fields"
          class="waterLevelTable">
          <template #cell(Icon)="data">
            <img :src="data.value" width="30" height="7" />
          </template>
        </b-table>
      </div>

      <div id="WaterLevelChart" class="svg-container"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { days } from "@/common/time-utils";
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";

export default {
  props: ["stationData"],
  data() {
    //revoir: ecrire en 1.5x moins de ligne (cf les autres composants)
    var _this = this;

    let selectedDate = new Date();
    if (this.$store.state.selectedTime != null) {
      selectedDate = this.$store.state.selectedTime;
    }

    var dateMinDisplayed = new Date(this.roundToClosestFifteen(selectedDate));
    dateMinDisplayed.setHours(dateMinDisplayed.getHours() - 12);

    var dateMaxDisplayed = new Date(this.roundToClosestFifteen(selectedDate));
    dateMaxDisplayed.setHours(dateMaxDisplayed.getHours() + 12);

    const width = 600;
    const height = 200;
    const margin = { top: 10, bottom: 35, left: 60, right: 10 };
    var line = d3
      .line()
      .x(function (d) {
        return _this.x(d.date);
      })
      .y(function (d) {
        return _this.y(d.value);
      });

    return {
      fields: [
        { key: "Code", label: "", class: "tableCode" },
        "Valeur",
        { key: "Icon", label: "", class: "tideIcon" },
      ],
      itemTable: [],
      timeSeriesToChart: ["wlp", "wlo", "wlf"],
      content: null,
      fullData: {},
      data: {},
      baseData: [],
      limitNbOfHours: 12,
      width,
      height,
      margin,
      extentX: null,
      minYValue: null,
      maxYValue: null,
      svg: null,
      x: null,
      y: null,
      xAxis: null,
      opacityLineMouse: 0.4,
      lineFollowX: null,
      lineFollowY: null,
      focus: null,
      rect: null,
      selectedData: null,
      mouseMoveX: null,
      mouseMoveY: null,
      dates: null,
      line,
      dragStart: null,
      dateHovered: this.$store.state.selectedTime,
      dateMinDisplayed,
      dateMaxDisplayed,
    };
  },
  computed: {
    dateHoveredString: function () {
      if (this.dateHovered === null || this.dateHovered === undefined) return "";
      return formatDateToDisplay(
        this.roundToClosestFifteen(this.dateHovered),
        days[this.$i18n.locale]
      );
    },
    dateSelected: function () {
      if (this.$store.state.selectedTime != null) {
        return this.$store.state.selectedTime;
      }
      return new Date();
    },
    timeSeriesEnum: function () {
      return {
        wlp: ["orange", this.$t("prediction"), "orange_line.png"],
        wlo: ["blue", this.$t("observation"), "blue_line.png"],
        wlf: ["green", this.$t("forecast"), "green_line.png"],
      };
    },
  },
  methods: {
    splitDateTime: function (string) {
      let DateTimeSplit = string.eventDate.split("T");
      let DateSplit = DateTimeSplit[0].split("-");
      let TimeSplit = DateTimeSplit[1].slice(0, -1).split(":");
      return DateSplit + TimeSplit;
    },
    setupData: function () {
      let _this = this;
      this.data = {};
      this.content = document.getElementById("WaterLevelChart");
      // Clear the content
      var child = _this.content.lastElementChild;
      while (child) {
        _this.content.removeChild(child);
        child = _this.content.lastElementChild;
      }

      for (const timeSeries in this.fullData) {
        if (this.timeSeriesToChart.includes(timeSeries)) {
          this.data[timeSeries] = this.fullData[timeSeries];
          this.data[timeSeries].sort(function (a, b) {
            var aSplit = _this.splitDateTime(a);
            var bSplit = _this.splitDateTime(b);
            return aSplit > bSplit ? 1 : aSplit < bSplit ? -1 : 0;
          });
          for (let i = 0; i < this.data[timeSeries].length; i++) {
            this.data[timeSeries][i].date = new Date(
              this.data[timeSeries][i].eventDate
            );
          }
        }
      }
    },
    initializeChart: function () {
      for (let i = 0; i < this.timeSeriesToChart.length; i++) {
        if (Object.keys(this.data).length === 0) return;
        if (this.data[this.timeSeriesToChart[i]].length != 0) {
          this.baseData = this.data[this.timeSeriesToChart[i]];
          break;
        }
      }

      this.dates = this.baseData.map((a) => a.date);

      this.maxYValue = null;
      this.minYValue = null;
      for (let [key] of Object.entries(this.data)) {
        if (this.data[key].length != 0) {
          var min = d3.min(this.data[key], (d) => d.value);
          if (this.minYValue === null || min < this.minYValue) {
            this.minYValue = min;
          }
          var max = d3.max(this.data[key], (d) => d.value);
          if (this.maxYValue === null || max > this.maxYValue) {
            this.maxYValue = max;
          }
        }
      }
      // Max extent of the data
      this.extentX = d3.extent(this.baseData, (d) => d.date);

      this.svg = d3
        .select(this.content)
        .append("svg")
        .attr("class", "svg-content-responsive")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 600 250");

      this.x = d3
        .scaleTime()
        .range([this.margin.left, this.width - this.margin.right]);

      // Set domain of the x axis
      this.setXDomain();

      this.y = d3
        .scaleLinear()
        .domain([this.minYValue, this.maxYValue])
        // .domain(d3.extent(this.baseData, (d) => d.value))
        .range([this.height - this.margin.bottom, this.margin.top]);
    },
    fillChart: function () {
      if (this.svg === null) return;
      this.svg.append("g");
      for (const timeSeries in this.data) {
        //.attr('transform', 'translate(50,-50)')

        //Display prediction lines
        this.svg
          .append("path")
          .data([this.data[timeSeries]])
          .attr("class", "line")
          .attr("d", this.line)
          .style("stroke-width", 2)
          .style("stroke", this.timeSeriesEnum[timeSeries][0])
          .style("fill", "none");
      }

      // Display time line
      //var date = new Date(this.dateChosen);
      var chosenLine = [
        {
          date: this.dateSelected,
          value: this.minYValue,
        },
        {
          date: this.dateSelected,
          value: this.maxYValue,
        },
      ];

      // configure line for date chosen
      this.svg
        .append("path")
        .data([chosenLine])
        .attr("class", "line")
        .attr("d", this.line)
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none")
        .style("stroke-dasharray", "3, 3");
    },
    setAxis: function () {
      let _this = this;
      //configure xaxis
      const xAxisPosition = this.height - this.margin.bottom;
      const format = d3.timeFormat("%a %H:%M");
      this.xAxis = d3.axisBottom(this.x).ticks(10).tickFormat(format);
      this.svg
        .append("g")
        .attr("class", "xaxis")
        .attr("transform", "translate(0," + xAxisPosition + ")")
        .call(this.xAxis)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)")
        .style("font-size", 16);

      //Configure yaxis
      this.svg
        .append("g")
        .attr("class", "yaxis")
        .attr("transform", "translate(" + this.margin.left + ",0)")
        .call(d3.axisLeft(this.y).ticks(6))
        .style("font-size", 16);
      this.svg
        .append("text")
        .attr("class", "y label")
        .attr("y", 2)
        .attr("x", 0 - _this.height / 2.3)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .attr("transform", "rotate(-90)")
        .text(_this.$t("chart-y-label"));
    },
    addInteractions: function () {
      //configure line following mouse along xAxis
      this.lineFollowX = this.svg
        .append("path")
        .attr("class", "lineFollow")
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none")
        .style("stroke-dasharray", "3, 3")
        .style("opacity", this.opacityLineMouse);

      //configure line following mouse along YAxis
      this.lineFollowY = this.svg
        .append("path")
        .attr("class", "lineFollow")
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none")
        .style("stroke-dasharray", "3, 3")
        .style("opacity", this.opacityLineMouse);

      // Create the circle that travels along the curve of chart
      this.focus = this.svg
        .append("g")
        .append("circle")
        .style("fill", "#00adef")
        .attr("r", 4.5)
        .style("opacity", 0);

      // Create a rect on top of the svg area: this rectangle recovers mouse position
      this.rect = this.svg
        .append("rect")
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr("class", "chartRect")
        .on("mouseover", this.mouseover)
        .on("mousemove", this.mousemove)
        .on("mouseout", this.mouseout)
        .on("click", this.clicked);

      // Création de l'intéraction drag
      this.rect.call(
        d3
          .drag()
          .on("start", this.dragStarted)
          .on("drag", this.dragged)
          .on("end", this.dragEnded)
      );
    },

    setXDomain: function () {
      var dateVariable;
      if (this.dateMinDisplayed <= this.extentX[0]) {
        dateVariable = new Date(this.extentX[0]);
        this.dateMinDisplayed = new Date(this.extentX[0]);
        this.dateMaxDisplayed = new Date(
          dateVariable.setHours(
            dateVariable.getHours() + this.limitNbOfHours * 2
          )
        );
        this.x.domain([this.dateMinDisplayed, this.dateMaxDisplayed]);
      } else if (
        this.dateMinDisplayed > this.extentX[0] &&
        this.dateMaxDisplayed < this.extentX[1]
      ) {
        this.x.domain([this.dateMinDisplayed, this.dateMaxDisplayed]);
      } else if (this.dateMaxDisplayed >= this.extentX[1]) {
        dateVariable = new Date(this.extentX[1]);
        this.dateMaxDisplayed = new Date(this.extentX[1]);
        this.dateMinDisplayed = new Date(
          dateVariable.setHours(
            dateVariable.getHours() - this.limitNbOfHours * 2
          )
        );
        this.x.domain([this.dateMinDisplayed, this.dateMaxDisplayed]);
      }
    },
    mouseover: function () {
      this.focus.style("opacity", 1);
    },
    mousemove: function (event) {
      this.findDateRelated(event);
      if (this.selectedData === undefined) return;
      this.focus
        .attr("cx", this.x(this.selectedData.date))
        .attr("cy", this.y(this.selectedData.value));

      this.mouseMoveX = [
        {
          date: this.selectedData.date,
          value: this.minYValue,
        },
        {
          date: this.selectedData.date,
          value: this.selectedData.value,
        },
      ];
      this.mouseMoveY = [
        {
          date: this.selectedData.date,
          value: this.selectedData.value,
        },
        {
          date: this.extentX[0],
          value: this.selectedData.value,
        },
      ];

      this.lineFollowX.data([this.mouseMoveX]).attr("d", this.line);

      this.lineFollowY.data([this.mouseMoveY]).attr("d", this.line);
    },
    mouseout: function () {
      this.focus.style("opacity", 0);
    },
    clicked: function () {
      if (this.$store.state.itineraryCoordinates.length < 1) {
        this.$store.state.selectedTime = new Date(this.findDateRelated(event));
      }
    },
    dragStarted: function () {
      if (event.type === "mousemove") {
        this.findDateRelated(event);
      }
      if (event.type === "touchstart") {
        this.findDateRelated(event.touches[0]);
      }
      this.focus.style("opacity", 0);
      this.svg.selectAll(".lineFollow").style("opacity", 0);
      this.dragStart = this.selectedData;
    },
    dragged: function () {
      // Selon le type d'appareil (ordinateur ou mobile), l'event est différent
      if (event.type === "mousemove") {
        this.findDateRelated(event);
      }
      if (event.type === "touchmove") {
        this.findDateRelated(event.touches[0]);
      }

      var diff =
        this.dragStart.date.getTime() - this.selectedData.date.getTime();

      this.dateMinDisplayed.setTime(this.dateMinDisplayed.getTime() + diff);
      this.dateMaxDisplayed.setTime(this.dateMaxDisplayed.getTime() + diff);

      this.setXDomain();

      this.svg
        .select(".xaxis")
        .call(this.xAxis)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)")
        .style("font-size", 16);

      this.svg.selectAll(".line").remove();

      this.fillChart();
    },
    dragEnded: function () {
      if (event.type === "touchend") {
        this.mousemove(event.changedTouches[0]);
      }
      this.focus.style("opacity", 1);
      this.svg.selectAll(".lineFollow").style("opacity", this.opacityLineMouse);
    },

    findDateRelated: function (event) {
      var pointer = d3.pointer(event, event.target);
      var x0 = this.x.invert(pointer[0]);
      var i = d3.bisect(this.dates, x0);
      this.dateHovered = this.dates[i];
      this.fillTable();
      this.selectedData = this.baseData[i];
      return this.dates[i];
    },
    fillTable: function () {
      this.itemTable = [];
      if (this.dateHovered === undefined) {
        for (const timeSeries in this.data) {
          this.emptyTableRow(timeSeries)
        }
        return
      }
      for (const timeSeries in this.data) {
        let dataFound = false;
        for (let j = 0; j < this.data[timeSeries].length; j++) {
          if (
            this.data[timeSeries][j].date.getTime() ===
            this.dateHovered.getTime()
          ) {
            this.itemTable.push({
              Code: this.timeSeriesEnum[timeSeries][1],
              Valeur: Math.round(this.data[timeSeries][j].value * 100) / 100,
              Icon: require("@/assets/" + this.timeSeriesEnum[timeSeries][2]),
            });
            dataFound = true;
            break;
          }
        }
        if (!dataFound) {
          this.emptyTableRow(timeSeries)
        }
      }
    },
    emptyTableRow: function (timeSeries) {
      this.itemTable.push({
        Code: this.timeSeriesEnum[timeSeries][1],
        Valeur: null,
        Icon: require("@/assets/" + this.timeSeriesEnum[timeSeries][2]),
      });
    },
    initializeTable: function () {
      this.fillTable();
    },
    roundToClosestFifteen: function (date) {
      var coeff = 1000 * 60 * 15;
      return new Date(Math.round(date.getTime() / coeff) * coeff);
    },
    displayGraph: function () {
      this.setupData();
      this.initializeTable();
      this.initializeChart();
      if (this.svg === null) return;
      this.fillChart();
      this.setAxis();
      this.addInteractions();
    },
  },
  mounted() {
    this.fullData = this.stationData;
    this.displayGraph();
  },
  watch: {
    stationData: function (newValue) {
      this.fullData = newValue;
      this.displayGraph();
    },
    "$store.state.selectedTime": function (newValue) {
      if (newValue != null) {
        this.dateHovered = newValue;
        this.displayGraph();
      }
    },
    "$i18n.locale": function () {
      this.displayGraph();
    },
  },
};
</script>

<style>
.waterLevelContent {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.waterLevelTableContainer {
  border-radius: 10px;
  border: 2px solid #bec0c2;
  margin: 4px;
}

.waterLevelTableDate {
  font-size: 14px;
  font-style: italic;
  padding: 5px;
}

.waterLevelTable {
  min-width: 300px;
  max-width: 600px;
  margin: 5px;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  /* aspect ratio */
  height: 100%;
  vertical-align: top;
  max-width: 500px;
  border-radius: 10px;
  border: 2px solid #bec0c2;
  margin: 4px;
}

.svg-content-responsive {
  height: 50%;
}

.chartRect {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: grab;
}

.chartRect:active {
  cursor: grabbing;
}

.tableCode {
  max-width: 80px;
}
</style>