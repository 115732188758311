<template>
    <div>
        <div class="meteo-warning-button" :class="item.type + '-color'" v-for="item in warnings" :key="item.description"
            @click="openWarningURL()">
            <img class="warning-icon" :src="require('@/assets/warning_exclamation.svg')" />
            <div class="warning-button-text">{{ item.description.charAt(0).toUpperCase() + item.description.toLowerCase().slice(1) }}</div>

            <img class="exiting-link-icon" :src="require('@/assets/exiting-link-icon.svg')" />
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            itemClicked: null,
            locales: {
                fr: "f",
                en: "e",
            },
        };
    },
    props: {
        warnings: Array,
        url: String
    },

    // created(){
    //     console.log(this.stationData
    //     )
    // },
    methods: {
        clicked(element) {
            this.itemClicked = element

            document.getElementById('detailed-day-weather-container').scrollIntoView({ behavior: 'smooth' })

        },
        openWarningURL() {
            window.open(this.url, 'mywindow');
        }
    },
};
</script>
  
<style scoped>
.warning-button-text{
    color:black;
    margin:0px 3px;
}
.meteo-warnings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.meteo-warning-button {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 5px;
    text-align: center;
    border-radius: 10px;
}

.warning-color {
    background-color: #d75050;
}

.watch-color {
    background-color: #ffcb1f;
}

.statement-color {
    background-color: #bec0c2;
}

.warning-color:hover,
.watch-color:hover,
.statement-color:hover {
    filter: brightness(85%);
}

.warning-icon {
    height: 20px;
    margin: 0px 4px;
}

.exiting-link-icon {
    height: 10px;
    margin-left: 5px;
}
</style>