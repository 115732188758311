<template>
  <div>
    <div v-if="!noStationData">
      <WaterLevelChartLayout class="pane-container-boxes" v-bind:stationData="stationData"></WaterLevelChartLayout>
      <TidesExtendedLayout class="pane-container-boxes" v-bind:stationData="stationData"></TidesExtendedLayout>
    </div>
    <div class="no-iwls-data" v-if="noStationData">{{ $t("no-iwls-data") }}</div>
  </div>
</template>

<script>
import TidesExtendedLayout from "@/components/buttomPane/layouts/TidesExtendedLayout.vue";
import WaterLevelChartLayout from "@/components/buttomPane/layouts/WaterLevelChartLayout.vue";
export default {
  components: {
    TidesExtendedLayout,
    WaterLevelChartLayout,
  },
  props: ["stationData"],
  data() {
    return {
      noStationData: false
    }
  },
  methods: {
    checkForData: function(dataObject){
      let noStationData = true
      for (let timeserie in dataObject) {
        if (dataObject[timeserie].length != 0) {
          noStationData = false
        }
      }
      this.noStationData = noStationData
    }
  },
  mounted(){
    this.checkForData(this.stationData)
  },
  watch: {
    "stationData": function (stationData) {
      this.checkForData(stationData)
    }
  }
};
</script>

<style>

.no-iwls-data{
  text-align: center;
}
</style>