var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-pane"},[_c('div',{staticClass:"tabs-menu"},_vm._l((_vm.tabs),function(tab){return _c('MenuTabButton',{key:tab,attrs:{"menuTabName":tab,"activeTab":_vm.activeTab},on:{"activeTabFromChild":_vm.handleTabClick}})}),1),_vm._v(" "),(_vm.selectedLayer == _vm.Layer.CURRENTS)?_c('layerInfoModal'):_vm._e(),_vm._v(" "),_c('LayerLegends',{staticClass:"layer-legend-container"}),_vm._v(" "),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab),expression:"activeTab"}],staticClass:"tab-content"},[_c('img',{staticClass:"close-icon",attrs:{"src":require("@/assets/icone_x.svg")},on:{"click":function($event){_vm.activeTab = null}}}),_vm._v(" "),(_vm.activeTab === _vm.tabEnum.INFORMATION)?_c('AreaInfo'):_vm._e(),_vm._v(" "),(_vm.activeTab === _vm.tabEnum.MARINE_STATIONS)?_c('StationsListAndDetails',{attrs:{"allStations":_vm.allMarineStations,"categories":_vm.Category,"selectedStation":_vm.selectedMarineStation,"stationCategories":[_vm.Category.Marine]}},[_c('MarineStationLayout',{attrs:{"stationData":_vm.selectedMarineStationData}})],1):_vm._e(),_vm._v(" "),(_vm.activeTab === _vm.tabEnum.ATMOSPHERIC_STATIONS)?_c('StationsListAndDetails',{attrs:{"allStations":_vm.allAtmosStations,"categories":_vm.Category,"selectedStation":_vm.selectedAtmosStation,"stationCategories":[
          _vm.Category.Atmospheric,
          _vm.Category.AtmosPrevision,
          _vm.Category.MeteoMaritime,
        ]}},[(
            _vm.selectedStation != null && _vm.selectedStation.category === _vm.Category.Atmospheric
          )?_c('MeteoStationExtendedLayout',{key:_vm.meteoKey,attrs:{"station":_vm.selectedAtmosphericStationData,"showAllVariables":true}}):_vm._e(),_vm._v(" "),(
            _vm.selectedStation != null &&
            _vm.selectedStation.category === _vm.Category.AtmosPrevision
          )?_c('AtmosPrevLayout',{attrs:{"stationData":_vm.selectedAtmosPrevisionData}}):_vm._e(),_vm._v(" "),(
            _vm.selectedStation != null &&
            _vm.selectedStation.category === _vm.Category.MeteoMaritime
          )?_c('MeteoMaritimeLayout',{attrs:{"regionData":_vm.selectedMeteoMaritimeData}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ItineraryTab',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === _vm.tabEnum.ITINERARY),expression:"activeTab === tabEnum.ITINERARY"}]})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }