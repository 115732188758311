<template>
  <div></div>
</template>

<script>
import { fromLonLat } from "ol/proj";
export default {
  props: {
    wmsSources: Object,
    stle200Status: Boolean,
  },
  methods: {
    async fetchWmsData(coordinates, view, source) {
      let data = {};
      const viewResolution = /** @type {number} */ (view.getResolution());
      const url = source.getFeatureInfoUrl(coordinates, viewResolution, "EPSG:3857", {
        INFO_FORMAT: "application/json",
      });
      if (url) {
        await fetch(url)
          .then((response) => response.json())
          .then((json) => {
            if (json.features.length > 0) {
              data = json.features[0].properties;
            } else {
              data = null;
            }
          });
      }
      return data;
    },
    getWMSInfo(coordinates, view) {
      if (coordinates === null) {
        return;
      }

      let _this = this;

      this.getWMSCurrentsInfo(coordinates, view);

      //Fetch wind and gusts data
      this.fetchWmsData(
        coordinates,
        view,
        this.wmsSources["winds"],
        ["eccc_winds_tif_0", "eccc_winds_tif_1", "eccc_winds_tif_2"],
        _this.$store.mutations.updateSelectedWinds,
        false
      ).then((data) => {
        if (data != null) {
          _this.$store.mutations.updateSelectedWinds(
            data.eccc_winds_tif_0,
            data.eccc_winds_tif_1,
            data.eccc_winds_tif_2,
            _this.isoSelectedDate
          );
        } else {
          _this.$store.mutations.updateSelectedWinds(null, null, null);
        }
      });
    },
    getWMSCurrentsInfo: async function (coordinates, view) {
      let sources = [];
      if (this.stle200Status) {
        sources.push({ name: "stle200", coordinates: coordinates });
      } else {
        sources.push({ name: "stle", coordinates: coordinates });
      }
      // sources.push({ name: "gulf", coordinates: this.applyOffset(coordinates) });
      sources.push({ name: "gulf", coordinates: coordinates });
      sources.push({ name: "shop", coordinates: coordinates });

      for (const source of sources) {
        if (source.coordinates != null) {
          let data = await this.fetchWmsData(
            source.coordinates,
            view,
            this.wmsSources[source.name]
          );

          if (data !== null && data.u !== null && data.v !== null && (data.u !== 0 || data.v !== 0)) {
            this.$store.mutations.updateSelectedCurrents(
              data.u,
              data.v,
              this.isoSelectedDate,
              source.name
            );
            return;
          }
        }
      }

      this.$store.mutations.updateSelectedCurrents(null, null, null);
    },

    applyOffset: function (coordinates) {
      if (this.$store.state.gulfCurrentsOffsets === null) {
        return null;
      }
      return [
        coordinates[0] + this.$store.state.gulfCurrentsOffsets[0],
        coordinates[1] + this.$store.state.gulfCurrentsOffsets[1],
      ];
    },
  },

  watch: {
    "$store.state.selectedCoordinates": function (newValue) {
      if (!newValue) return;
      this.getWMSInfo(
        fromLonLat([newValue.longitude, newValue.latitude]),
        this.$store.state.map.getView()
      );
    },
    "$store.state.selectedTime": function () {
      // On doit updater les valeurs de courants
      if (this.$store.state.selectedCoordinates) {
        this.getWMSInfo(
          fromLonLat([
            this.$store.state.selectedCoordinates.longitude,
            this.$store.state.selectedCoordinates.latitude,
          ]),
          this.$store.state.map.getView()
        );
      }
    },
  },
};
</script>

<style></style>
