<template>
  <div id="app">
    <TheMap id="map" ref="map" />
    <TheHeader />
    <TheSidePane />
    <TheBottomPane />
  </div>
</template>
<script>
import TheHeader from "@/components/header/TheHeader.vue";
import TheMap from "@/components/map/TheMap.vue";
import TheBottomPane from "@/components/buttomPane/TheBottomPane.vue";
import TheSidePane from "@/components/map/rightSidePane/TheSidePane.vue";

import "ol/ol.css";

import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import {getNearMeteoMaritimeStations} from "@/stationDataLayer/dd-meteo-maritime"
import { Layer } from "@/constants";
import { goToMyLocation } from "@/common/state-aware-functions";

export default {
  components: { TheHeader, TheMap, TheBottomPane, TheSidePane },
  data() {
    return {
      // vikingBuoyMeteoData: [],
      // vikingBuoyMarineData: [],
      stationDataGetter: {},
    };
  },
  mounted() {
    goToMyLocation();
    this.$store.mutations.updateSelectedTime(new Date());
    this.$store.mutations.updateSelectedLayer(Layer.CURRENTS);
  },

  methods: {
    // loadVikingData() {
    //   const minTime = new Date();
    //   minTime.setHours(minTime.getHours() - 1);
    //   fetch(
    //     `${
    //       process.env.VUE_APP_VIKING_ERDDAP_URL
    //     }?&time>=${minTime.toISOString()}`
    //   )
    //     .then((response) => {
    //       if (response.status == 404) return;
    //       response.json().then((result) => {
    //         const mostRecentData = [];
    //         const columnNames = result.table.columnNames;
    //         result.table.rows.forEach((current) => {
    //           const currentStationData = {
    //             id: current[columnNames.indexOf("station_id")],
    //             officialName: current[columnNames.indexOf("station_id")],
    //             time: new Date(current[columnNames.indexOf("time")]),
    //             observation_date_utc: current[columnNames.indexOf("time")],
    //             latitude: current[columnNames.indexOf("latitude")],
    //             longitude: current[columnNames.indexOf("longitude")],
    //             type: "viking",
    //             data: {
    //               wind_speed: current[columnNames.indexOf("WSPD_01")],
    //               wind_direction: current[columnNames.indexOf("WDIR_01")],
    //               air_temperature: current[columnNames.indexOf("DRYT_01")],
    //             },
    //           };
    //           const mostRecentStationData = mostRecentData.find(
    //             (stationData) => {
    //               return currentStationData.id === stationData.id;
    //             }
    //           );
    //           if (mostRecentStationData == null) {
    //             mostRecentData.push(currentStationData);
    //           } else if (mostRecentStationData.time < currentStationData.time) {
    //             mostRecentData[mostRecentData.indexOf(mostRecentStationData)] =
    //               currentStationData;
    //           }
    //         });
    //         const meteoStationData = mostRecentData.filter((station) => {
    //           if (
    //             station.data.wind_speed ||
    //             station.data.wind_direction ||
    //             station.data.air_temperature
    //           ) {
    //             return true;
    //           }
    //         });
    //         this.vikingBuoyMeteoData = meteoStationData;
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },

  watch: {
    "$store.state.stationsLayerData.stationsList": function (value) {
      const coordinates = this.$store.state.selectedCoordinates;
      if (!coordinates) return;
      const nearStations = stationDataLayer.getNearStationsSorted(coordinates);
      this.$store.mutations.updateNearStations(nearStations);
    },
    "$store.state.selectedCoordinates": function (newCoordinates) {
      if (!newCoordinates) return;
      const nearStations =
        stationDataLayer.getNearStationsSorted(newCoordinates);
      this.$store.mutations.updateNearStations(nearStations);
      
      
      let regionLayer = this.$store.state.map.getLayers().getArray().find(layer => layer.get('name') == 'meteoMaritimeRegions');
      const nearMeteoMaritimeRegions = getNearMeteoMaritimeStations(newCoordinates, regionLayer)
      this.$store.mutations.updateNearMeteoMaritimeStations(nearMeteoMaritimeRegions)

    },
  },
};

const documentHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', documentHeight)
documentHeight()

</script>
<style>
:root {
 --doc-height: 100%;
}

html,
body {
 padding: 0;
 margin: 0;
 height: 100vh; /* fallback for Js load */
 height: var(--doc-height);
}

#map {
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100vh);
  height: var(--doc-height);
  width: 100%;
  background-color:#9cd5ff
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
}

.infoSectionTitle {
  color: white;
  background-color: #0076a3;
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  padding: 0px 3px 0px 3px;
}

.allTideTables {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tideIcon {
  max-width: 30px;
}
.tideTableLong {
  font-size: 12px;
  width: 150px !important;
  padding: 25px 25px 25px 25px;
}
.display-day-date-time {
  font-size: 14px;
  font-style: italic;
  padding: 4px 0px 4px 8px;
}
.table-atmos {
  border-radius: 10px;
  border: 2px solid #bec0c2;
  margin: auto;
  max-width: 500px;
}
.pane-container-boxes {
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  margin: 15px;
}
.warning-message {
  background-color: yellow;
  font-weight: bold;
  width: 100%;
  font-size: small;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}
/* @media (max-width: 1285px) {
  .warning-message {
    height: 5%;
  }
  #app {
    height: 95%;
  }
} */

.ol-touch .ol-control button {
  font-size: inherit;
}

.btn-menu {
  background-color: #00adef; /* Green */
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: block;
  width: 300px;
}
.btn-menu:hover {
  background-color: #1e4659;
  color: white;
}

@media (max-width: 300px) {
  .btn-menu {
    width: 100%;
  }
}
@media (max-width: 555px) {
  .warning-message {
    font-size: x-small;
  }
}
.plus-minus-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #00adef;
  border-radius: 50%;
  margin: 3px;
}

.vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
  display: block !important;
}

.fadeHeightLegend-enter-active,
.fadeHeightLegend-leave-active {
    transition: all 0.5s;
    max-height: 200px;
}
.fadeHeightLegend-enter,
.fadeHeightLegend-leave-to {
    opacity: 0;
    max-height: 0px;
}
</style>