<template>
  <div class="currents-modal-main-container">
    <div class="currents-modal-clickable-text-container">
      <div class="currents-modal-clickable-text" v-b-modal.modal-currents-info>
        <span>{{ modelList }}</span>
        <img class="point-inter-currents" src="@/assets/icon_pt_inter_grey.svg" />
      </div>
    </div>
    <b-modal id="modal-currents-info" ref="modal-currents-info" size="xl">
      <img class="close-modal-icon" src="@/assets/icone_x_blue_green.svg" @click="$bvModal.hide('modal-currents-info')" />
      <div class="modal-current-content">
        <div class="modal-currents-info-title">
          {{ $t("currents-modal-title") }}
        </div>
        <div>
          {{ $t("currents-modal-description") }}
        </div>
        <table class="info-currents-table">
          <thead>
            <tr>
              <th>{{ $t("model") }}</th>
              <th>{{ $t("distributor") }}</th>
              <th>{{ $t("spatial-coverage") }}</th>
              <th>{{ $t("temporal-coverage") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SHOP</td>
              <td>CMC - ECCC</td>
              <td>Montréal - Trois-Rivières</td>
              <td>{{ $t("Predictions") }} 3 {{ $t("days") }}</td>
            </tr>
            <tr>
              <td>{{ $t("STLE200") }}</td>
              <td>{{ $t("DFO") }}</td>
              <td>Trois-Rivières - Les Escoumins</td>
              <td>{{ $t("Predictions") }} 2 {{ $t("days") }}</td>
            </tr>
            <tr>
              <td>STLE400</td>
              <td>{{ $t("DFO") }}</td>
              <td>Trois-Rivières - Les Escoumins</td>
              <td>{{ $t("Predictions") }} 5 {{ $t("days") }}</td>
            </tr>
            <tr>
              <td>{{ $t("CIOPS-East") }}</td>
              <td>ECCC</td>
              <td>Les Escoumins - {{ $t("Newfoundland") }}</td>
              <td>{{ $t("Predictions") }} 2 {{ $t("days") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { toLonLat } from "ol/proj";
export default {
  components: {},
  computed: {
    zoomLevel: function () {
      return this.$store.state.map.getView().getZoom();
    },
    mapCenter: function () {
      return toLonLat(this.$store.state.map.getView().getCenter());
    },
    stleModelUsed: function () {
      return this.$store.state.stle200Status ? this.$t("STLE200") : "STLE400";
    },
    modelList: function () {
      let intro = this.$t("model-intro");
      if (this.zoomLevel < 8) {
        return this.$t("more-than-one-model");
      } else {
        if (this.mapCenter[0] > -69.18) {
          return `${intro} ${this.$t("ciops-east")}`;
        } else if (this.mapCenter[0] < -72.5) {
          return `${intro} SHOP`;
        } else {
          return `${intro} ${this.stleModelUsed}`;
        }
      }
    },
  },
  methods: {
  },
};
//revoir: utiliser style scoped pour le css
</script>
<style>
.close-modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin: 5px 5px;
}
.currents-modal-clickable-text-container {
  position: absolute;
  right: 50px;
  top: 22px;
  width: 235px;
  text-align: right;
}
.currents-modal-clickable-text {
  padding: 0 3px;
  font-size: 14px;
  display: inline-block;
  color: #656565;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
}
.point-inter-currents {
  height: 15px;
  pointer-events: auto;
  margin-right: 2px;
  margin-left: 2px;
  margin-top: -3px;
}
.modal-currents-info-title {
  font-weight: 700;
}
.currents-modal-main-container {
  pointer-events: auto;
}
.modal-current-content {
  padding: 25px;
}
.info-currents-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.info-currents-table thead tr {
  background-color: #5494a0;
  color: #ffffff;
  text-align: left;
}
.info-currents-table th,
.info-currents-table td {
  padding: 12px 15px;
}
.info-currents-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.info-currents-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.info-currents-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
@media (max-width: 850px) {
  .info-currents-table th,
  .info-currents-table td {
    padding: 5px 5px;
  }
  .info-currents-table {
    border-collapse: collapse;
    margin: 10px;
    font-size: 0.7em;
    min-width: 100px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 720px) {
  .currents-modal-clickable-text-container {
    display: none;
  }
}
</style>
