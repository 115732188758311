<template>
    <div class="pane-container-boxes atmos-pane">
        <div class="infoSectionTitle">{{ $t("meteostation-tab-title") }}</div>
        <div v-if="warnings != null">
            <WarningButtons class="meteo-warnings" :warnings="warnings" :url="this.data.warnings.url"></WarningButtons>
        </div>
        <div class="prev-items" v-if="data != null">
            <div class="meteo-prev-button" v-for="item in data.forecasts" :key="item[0].period"
                @click="clicked(item[0].period)">
                <div class="meteo-button-title">{{ capitalizeFirstLetter(item[0].period) }}</div>
                <!-- <div v-for="forecast in item" :key="forecast.period" :class="forecast.period.includes('soir') || forecast.period.includes('night')
                    ? 'meteo-button-night'
                    : 'meteo-button-day'
                "> -->
                <transition name="fadeWidth" mode="out-in">
                    <div v-show="detailedWeather != null && item[0].period === detailedWeather[0].period"
                        class="meteo-button-sep"></div>
                </transition>
                <div v-for="forecast in item" :key="forecast.period" class="meteo-prev-button-items">
                    <img class="meteo-button-icon"
                        :src="require('@/assets/meteo_icon/icon_' + forecast['icon-code'] + '.svg')" :class="forecast.period.includes('soir') || forecast.period.includes('night')
                            ? 'meteo-icon-night'
                            : 'meteo-icon-day'
                            " />
                    <div class="meteo-prev-button-temp">{{ forecast['temperature'] }}°</div>
                </div>


            </div>
        </div>
        <div id="detailed-day-weather-container">
            <div v-for="forecast in detailedWeather" :key="forecast.period">
                <div class="detailed-day-weather-items">
                    <img class="meteo-icon-big" :src="require('@/assets/meteo_icon/icon_' + forecast['icon-code'] + '.svg')"
                        :class="forecast.period.includes('soir') || forecast.period.includes('night')
                            ? 'meteo-icon-night'
                            : 'meteo-icon-day'
                            " />
                    <div class="detailed-day-weather-item">
                        <div class="meteo-prev-item-title">{{ capitalizeFirstLetter(forecast.period) }}</div>
                        <div class="meteo-item-value">
                            {{ forecast["text-summary"] }}</div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import WarningButtons from "/src/components/buttomPane/layouts/WarningButtons.vue";
export default {
    components:{WarningButtons},
    data() {
        return {
            itemClicked: null,
            locales: {
                fr: "f",
                en: "e",
            },
        };
    },
    props: {
        stationData: Object,
        showAllVariables: { type: Boolean, default: true },
    },
    computed: {
        data: function () {
            if (this.stationData == null) {
                return null;
            }
            return this.stationData[this.locales[this.$i18n.locale]]
        },
        warnings: function(){
            if (this.data == null || this.data.warnings == null){
                return null
            }
            return this.data.warnings.events
        },
        detailedWeather: function () {
            if (this.itemClicked == null) {
                return null;
            }
            let text = this.stationData[this.locales[this.$i18n.locale]].forecasts.filter(arr => { return arr[0]["period"].indexOf(this.itemClicked) != -1 });
            return text[0]
        }
    },
    // created(){
    //     console.log(this.stationData
    //     )
    // },
    methods: {
        clicked(element) {
            this.itemClicked = element

            document.getElementById('detailed-day-weather-container').scrollIntoView({ behavior: 'smooth' })

        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        openWarningURL() {
            window.open(this.data.warnings.url, 'mywindow');
        }
    },
    watch: {
        stationData: function (newValue) {
            if (newValue === null) return
            this.itemClicked = this.stationData[this.locales[this.$i18n.locale]].forecasts[0][0].period
        }
    }
};
</script>
  
<style scoped>
.date-time-atmos {
    text-align: center;
}

.atmos-pane {
    /* max-width: 850px; */
    margin: 0 auto !important;
}

.meteo-prev-button {
    position: relative;
    width: 120px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
    text-align: center;
    border-radius: 10px;
}

.meteo-prev-button:hover {
    background-color: #d3d3d3;
    ;
}

.prev-items {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
    flex-wrap: wrap;
}

.meteo-button-icon {
    width: 33px;
    height: 33px;
    border-radius: 16px;
    padding: 2px;
    margin: 3px
}

@media (max-width: 550px) {
    .meteo-button-icon {
        width: 27px;
        height: 27px;
        border-radius: 13px;
    }
}

/* .meteo-prev-button-temp {
    display: inline;
} */

.meteo-icon-day {
    background-color: #c5e0eb;
}

.meteo-icon-night {
    background-color: #1e4659;
}

.meteo-prev-button-items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.meteo-button-sep {
    position: absolute;
    top: 20px;
    height: 4px;
    width: 50px;
    border: 0;
    background: #00adef;
    left: 50%;
    transform: translate(-50%, 0);
}

.meteo-button-title {
    margin-bottom: 4px;
    color: #0076a3;
    font-weight: bold;
}

.fadeWidth-enter-active,
.fadeWidth-leave-active {
    transition: all 0.7s;
    max-width: 50px;
}

.fadeWidth-enter,
.fadeWidth-leave-to {
    opacity: 1;
    max-width: 0px;
}

#detailed-day-weather-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.detailed-day-weather-items {
    display: flex;
}

.meteo-icon-big {
    width: 50px;
    height: 50px;
    margin: 5px;
    border-radius: 25px;
}

.meteo-prev-item-title {
    color: #0076a3;
    font-weight: 800;
}

.detailed-day-weather-item {
    flex-grow: 1;
}

.meteo-warnings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.meteo-warning-button {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 5px;
    text-align: center;
    border-radius: 10px;
}

.warning-color {
    background-color: #d75050;
}

.watch-color {
    background-color: #ffcb1f;
}

.statement-color {
    background-color: #bec0c2;
}

.warning-color:hover,
.watch-color:hover,
.statement-color:hover {
    filter: brightness(85%);
}

.warning-icon {
    height: 20px;
    margin: 0px 4px;
}

.exiting-link-icon {
    height: 10px;
    margin-left: 5px;
}
</style>