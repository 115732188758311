<template>
    <div>
        <div class="infoSectionTitle">{{ $t("meteostation-tab-title") }}</div>
        <div class="info-panel-weather-containers">
            <div class="info-panel-weather-container" v-if="weatherItems.length > 1">
                <div class="info-panel-section-title">{{ $t("weather_this") }} {{ weatherItems[0] }}</div>
                <span class="stationNameClick" @click="goToStation(weatherStation)">{{ weatherStationName }}</span>
                <span class="meteo-warning-button" v-for="(item, index) in weatherWarningIcons" :key="index"
                    @click="goToStation(weatherStation)">
                    <img class="meteo-button-warning-icon"
                        :src="require('@/assets/warning_exclamation_' + item + '.svg')" />
                </span>
                <div class="summary-meteo-icon-and-text">
                    <img class="meteo-button-icon" :src="require('@/assets/meteo_icon/icon_' + weatherItems[2] + '.svg')" />
                    <div class="info-panel-weather-text">{{ weatherItems[1] }}</div>
                </div>

            </div>
            <div class="info-panel-weather-observation-container">
                <div class="info-panel-section-title info-panel-second-section-title">Observations</div>
                <div class="info-panel-section-items">
                    <div class="titleNameWeather">
                        <span class="stationNameClick" :class="{
                            'disable-click': !timeInRange,
                        }" @click="goToStation(station)">{{ stationName }}</span>
                        <div class="distanceFromClick">{{
                            stationDisplayedString
                        }}</div>
                    </div>
                    <div v-show="timeInRange" class="obs-table-and-title">
                        <div class="display-day-date-time no-padding">{{ dateString }}</div>
                        <b-table v-show="stationData != null" sticky-header="300px" striped hover small
                            :items="observationItems" thead-class="d-none" class="table-widget"></b-table>
                    </div>
                </div>

                <div v-show="!timeInRange">
                    {{ $t("no_atmos_station") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHoursDifference } from "@/common/timeRelatedFunctions";
import meteoMixin from "@/components/buttomPane/layouts/mixins/displayMeteoInfoMixin";
import stationDataLayer from "@/stationDataLayer/stationDataLayer";
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
    mixins: [meteoMixin],
    props: {
        weatherStation: Object,
    },
    data() {
        return { stationData: null, weatherStationData: null };
    },
    computed: {
        codeLanguage() {
            return this.$i18n.locale.charAt(0);
        },
        observationItems() {
            if (!this.stationData) {
                return null;
            }

            // const entries = Object.entries(this.station.data);
            const entries = Object.entries(this.stationData.data);

            const data = entries.filter(([key]) =>
                ["air_temperature", "wind_speed", "wind_direction"].includes(
                    key
                )
            );
            this.changeWindToKnots(data);
            return this.buildTable(data);
        },
        weatherStationName() {
            return this.weatherStationData[this.codeLanguage].location
        },
        weatherItems() {
            if (!this.weatherStationData) {
                return [];
            }
            let data = [
                this.weatherStationData[this.codeLanguage]["forecasts"][this.prevMeteoArrayItem][0]
                    .period,
                this.weatherStationData[this.codeLanguage]["forecasts"][this.prevMeteoArrayItem][0][
                "text-summary"
                ],
                this.weatherStationData[this.codeLanguage]["forecasts"][this.prevMeteoArrayItem][0][
                "icon-code"
                ]
            ];
            return data;
        },
        weatherWarningIcons() {
            let warningIconDict = { warning: "red", watch: "yellow", statement: "grey" }
            let iconList = []
            if (this.weatherStationData[this.codeLanguage].warnings != null) {
                for (const event of this.weatherStationData[this.codeLanguage].warnings.events) {
                    event.type in warningIconDict ? iconList.push(warningIconDict[event.type]) : null;
                }
            }

            return iconList
        },
        stationDisplayedString() {
            if (!this.station) {
                return this.$t("no-meteo-data");
            }
            return (
                "  à " +
                Math.round(this.station.distanceFromClick) +
                " km de distance"
            );
        },
        stationName() {
            if (!this.station) {
                return "";
            }
            return this.station.name;
        },
        dateString: function () {
            if (!this.stationData) {
                return "";
            }
            var date = new Date(this.stationData.observationDateUtc);
            return formatDateToDisplay(date, days.fr);
        },
        timeInRange: function () {
            return getHoursDifference(this.$store.state.selectedTime) < 3;
        },
        prevMeteoArrayItem: function () {
            let date2 = new Date(this.$store.state.selectedTime)
            const diffTime = Math.abs(date2.setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0));
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        }
    },
    methods: {
        goToStation: function (station) {
            this.$store.mutations.updateSelectedStation(
                station.id,
                station.latitude,
                station.longitude,
                station.category,
                station.name,
                true
            );
        },
        updateObsStationData() {
            if (!this.station) {
                this.stationData = null;
                return;
            }

            stationDataLayer
                .getAtmosphericStationData(
                    this.station.id,
                    this.$store.state.selectedTime
                )
                .then((data) => {
                    this.stationData = data;
                });
        },
        updateWeatherStationData() {
            if (!this.weatherStation) {
                this.weatherStationData = null;
                return;
            }

            stationDataLayer
                .getAtmosPrevisionStationDataWithCache(
                    this.weatherStation.id,
                    this.weatherStation.province
                )
                .then((data) => {

                    this.weatherStationData = data;
                });
        },
    },
    mounted() {
        this.updateObsStationData();
        this.updateWeatherStationData();
    },
    watch: {
        station: function () {
            this.updateObsStationData();
        },
        weatherStation: function () {
            this.updateWeatherStationData();
        },
    },
};
</script>

<style scoped>
.info-panel-weather-text {
    min-width: 120px;
}

.info-panel-weather-container {
    margin: 0px 4px;
}

.info-panel-weather-observation-container {
    margin: 0px 4px;
}

.disable-click {
    pointer-events: none;
    color: grey;
}

.info-panel-weather-containers {
    /* display: flex; */
    justify-content: space-around;
    font-size: 14px;
}

.info-panel-section-title {
    font-size: 16px;
    font-weight: bold;

}

.info-panel-second-section-title {
    margin-top: 5px;
}

.no-padding {
    padding: 0px !important;
}

.info-panel-section-items {
    display: flex;

}

.titleNameWeather {
    min-width: 120px;
}

.obs-table-and-title {
    margin-left: 10px;
}

.summary-meteo-icon-and-text {
    display: flex;
}

.meteo-button-icon {
    width: 45px;
    height: 45px;
    margin: 5px
}

.meteo-button-warning-icon {
    width: 25px;
    cursor: pointer;
    margin:0px 2px;
}
</style>