<template>
  <div class="itinerary-table text-center">
    <b-table responsive striped hover :items="items" :fields="fields">
      <template #head(speed)="data">
        <span class="itinerary-speed-label">{{ data.label }}</span>
        <b-form-select
          class="itinerary-units-select"
          v-model="unitsChosen"
          :options="unitChoices"
          required
        ></b-form-select>
      </template>
      <template v-slot:cell(speed)="row">
        <b-form-input
          class="boat-speed-input"
          min="1"
          v-model="row.item.speed"
          type="number"
        />
      </template>
      <template v-slot:cell(departureDate)="row">
        <v-date-picker
          v-model="row.item.departureDate"
          mode="dateTime"
          :popover="popover"
          :masks="{ L: 'YYYY-MM-DD' }"
          :is24hr="true"
        >
          <template v-slot="{ inputValue, inputEvents, updateValue }">
            <img class="itinerary-clock-icon" src="@/assets/clock.svg" />
            <input
              class="
                bg-white
                border
                px-2
                py-1
                rounded
                input-itinerary-departure-date
              "
              :value="inputValue"
              v-on="inputEvents"
            />
            <div class="plus-minus-container">
              <img
                class="plus-minus-icon"
                src="@/assets/ios-arrow-back.svg"
                @click="updateValue(changeHour(-1))"
              />
              <img
                class="plus-minus-icon"
                src="@/assets/ios-arrow-forward.svg"
                @click="updateValue(changeHour(1))"
              />
            </div>
          </template>
        </v-date-picker>
      </template>
    </b-table>
  </div>
</template>

<script>
import itineraryTableMixin from "@/components/buttomPane/itinerary/mixins/itineraryTableMixin";
import { formatDateToDisplay } from "@/common/timeRelatedFunctions";
import { days } from "@/common/time-utils";
export default {
  mixins: [itineraryTableMixin],
  data() {
    let lengthInMeters = 0;
    let selectedSpeed;
    return {
      boatSpeedInKnots: 5,
      lengthInMeters,
      selectedSpeed,
      totalDurationInMinutes: 0,
      items: [
        {
          departureDate: new Date(),
          speed: 5,
          distance: 0,
          arrivalDateFormatted: null,
          time: null,
        },
      ],
      unitsChosen: "knots",
    };
  },
  computed: {
    boatSpeedDisplayed() {
      return this.items[0].speed;
    },
    departureDate() {
      return this.items[0].departureDate;
    },
    arrivalDate() {
      if (this.totalDurationInMinutes === 0) {
        return null;
      }
      return new Date(
        this.departureDate.getTime() + this.totalDurationInMinutes * 60000
      );
    },
    formattedArrivalDate() {
      if (this.arrivalDate === null) {
        return null;
      }
      return formatDateToDisplay(this.arrivalDate, days[this.$i18n.locale]);
    },

    formattedLength() {
      return this.formatLength(this.$store.state.itineraryTotalLength);
    },
  },
  methods: {
    changeHour(value) {
      return this.items[0].departureDate.setHours(
        this.items[0].departureDate.getHours() + value
      );
    },
    formatLength: function (lengthInMeters) {
      return Math.round((lengthInMeters / 1851.99932587) * 100) / 100;
    },
    updateSpeedandDistanceDisplayed: function (value) {
      if (value === "knots") {
        this.items[0].speed = this.kmToNm(this.boatSpeedDisplayed);
        this.items[0].distance = this.kmToNm(this.items[0].distance);
      } else {
        this.items[0].speed = this.nmToKm(this.boatSpeedDisplayed);
        this.items[0].distance = this.nmToKm(this.items[0].distance);
      }
    },
  },
  watch: {
    departureDate: function (newValue) {
      if (newValue != null) {
        this.$emit("departureDateChanged", newValue);
      }
    },
    arrivalDate: function (value) {
      this.$emit("arrivalDateChanged", value);
    },
    formattedLength: function (value) {
      if (this.universalUnitsChosen === "knots") {
        this.items[0].distance = value;
      } else {
        this.items[0].distance = this.nmToKm(value);
      }
      this.totalDurationInMinutes = Math.floor(
        this.calculateDuration(value, this.boatSpeedDisplayed)
      );
    },
    boatSpeedInKnots: function (value) {
      this.totalDurationInMinutes = Math.floor(
        this.calculateDuration(this.formattedLength, value)
      );
    },
    totalDurationInMinutes: function (value) {
      if (value != 0) {
        this.items[0].time = this.formatDuration(value);
      } else {
        this.items[0].time = null;
      }
      this.$emit("durationChanged", value);
    },
    formattedArrivalDate: function (value) {
      this.items[0].arrivalDateFormatted = value;
    },
    universalDepartureDate: function (value, oldValue) {
      if (value.getTime() != oldValue.getTime()) {
        this.items[0].departureDate = value;
      }
    },
    universalUnitsChosen: function (value, oldValue) {
      if (value != oldValue) {
        this.unitsChosen = value;
        this.updateSpeedandDistanceDisplayed(value);
      }
    },
    unitsChosen: function (value) {
      this.$emit("unitsChanged", value);
    },
    boatSpeedDisplayed: function () {
      if (this.universalUnitsChosen === "knots") {
        this.boatSpeedInKnots = this.boatSpeedDisplayed;
      } else {
        this.boatSpeedInKnots = this.kmToNm(this.boatSpeedDisplayed);
      }
    },
  },
};
</script>

<style>
</style>